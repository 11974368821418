import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Grid from "@mui/material/Grid";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import { Link, useParams } from "react-router-dom";
import "../../index.css";
import TextField from "@mui/material/TextField";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck from "../../Assets/Icons/Icons/GreenCheck.png";
import { useHistory } from "react-router-dom";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { apiService, apiService2 } from "../../Services/apiService";
import { CREATE_NOTIFICATION , SERVER_URL} from '../../Services/apiEndpints'

export default function SetReminder() {
  const history = useHistory();
  const [message, setMessage] = React.useState()
  let { campId } = useParams();
  
  const sendNotification = async () => {
    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
        icon: 'no-border'

        //    confirmButton: 'your-confirm-button-class',
      },
    }).then((result) => {
      if (result.isConfirmed) {
        (async () => {
          let dataSent = {
            userId: localStorage.getItem('userId'),
            campId: campId,
            notificationType: "camp",
            notificationRemarks: message,

          }
          let apiInfo = {
            // url: "https://cncdevapi.natsoft.io/api/camps/createnotification",
            url: `${SERVER_URL}${CREATE_NOTIFICATION}`,
            method: "POST",
            postData: dataSent,
            headers: {
              "Content-Type": "application/json",
              tokenheaderkey: localStorage.getItem("token"),
            },
          }
          let output = await apiService2(apiInfo)
          console.log(output)
          if (output?.data?.responseCode === 0) {
            console.log(output.data.responseData[0][0].Desc)
            Swal.fire({
              iconHtml: `<img src="${GreenCheck}" width="100" height="100" >`,
              text: output.data.responseData[0][0].Desc,
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
            }).then((result) => {
              if (result.value) {
                history.push(`/ReminderLog/${campId}`);
              }
            });
          }
        })()
      }
    })
 
  }
  const handleMessageSubmit = (event) => {
    console.log(event.target.value)
    setMessage(event.target.value)
  }
  return (
    <div>
      <Box>
        <Grid container direction="row">
          <Grid container display="flex" justifyContent="space-between">
            <Grid>
              <Link to="/CampList">
                <Box
                  mt="0.65rem"
                  alignSelf="center"
                  component="img"
                  sx={{
                    height: "0.75rem",
                    width: "0.65rem",
                    cursor: "pointer",
                  }}
                  height="20"
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>
            </Grid>
            <Grid item>
              <Box
                className="gilroy"
                alignSelf="center"
                sx={{ textAlign: "center" }}
              >
                Set Reminder
              </Box>
            </Grid>

            <Grid item>
              <Box
                className="gilroy"
                alignSelf="center"
                sx={{ fontSize: "1.2rem" }}
              >

              </Box>
            </Grid>
          </Grid>
          {/* </Grid> */}
        </Grid>
        {/* <Grid>
          <Box className="gilroy" mt="0.75rem">
            Send Date & Time
          </Box>
        </Grid> */}
        <Grid>
          {/* <DatePicker label="Basic date picker" /> */}
        </Grid>
        <Grid>
          <Box className="gilroy" mt="0.75rem">
            Contents:
          </Box>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                mt: "0.75rem",
                width: "70%",
                mb: "1rem",
                // borderRadius:"1rem"
              },
            }}
            onChange={(e) => handleMessageSubmit(e)}
            noValidate
            autoComplete="off"
          >
            <TextField id="outlined-multiline-static" multiline rows={5} />
          </Box>
        </Grid>

        <Grid container justifyContent="flex-end">
          <Grid item>
          <Link to={`/ReminderLog/${campId}`}>
            <Button
              variant="contained"
              sx={{
                px: "2rem",
                mx: "1rem",
                bgcolor: "#909090",
                textTransform: "none",
                "&:hover": { background: "#909090" },
              }}
              className="gilroy"
            >
              Cancel
            </Button>
            </Link>
          </Grid>
          <Grid item>
           
            <Button
              variant="contained"
              className="gilroy"
              sx={{ px: "2rem", mx: "0.5rem", bgcolor: "#379592", "&:hover": { background: "#379592" }, textTransform: "none", }}
              // onClick={shoot}
              onClick={sendNotification}

            >
              Submit
            </Button>
           
           
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
