import React from "react";
import {  CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png"
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import Input from '@mui/material/Input';
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Dropdown from "../Assets/Icons/Icons/Dropdown.png"
import Button from "@mui/material/Button";
import InputAdornment from '@mui/material/InputAdornment';
import CalenderDark from "../Assets/Icons/Icons/CalenderDark.png"
import { Link ,useHistory} from "react-router-dom";
import Divider from "@mui/material/Divider";
import SampleImg from "../Assets/Icons/Icons/sample.png"
import {apiService,apiService2,apiServiceImage,} from "../Services/apiService";
import Swal from "sweetalert2";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import { useParams } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Image from "../Assets/Icons/Icons/Background-image.png";
import {
  SERVER_URL,
  REMOVE_CAMP,
  VIEW_CAMP
} from "../Services/apiEndpints";
import question from "../Assets/Icons/Icons/questionMark.png";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#909090",
  "&:hover": {
    backgroundColor: "#909090",
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: "#379592",
  "&:hover": {
    backgroundColor: "#379592",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

export default function RemoveCamp() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rows,setRows] = React.useState([])
  const history = useHistory();
  let { campId } = useParams();
  const [viewData, setViewData] = React.useState({});
  const [flist,setFlist] =  React.useState([])
  const [loading, setLoading] =React.useState(false);
 

//   campId: 5017,
//   campName: "Adventure Camp",
//   campDescription: "Among these specialty camps are those geared towards kids with special needs, enabling them to experience the joys and excitement of camp like every other child.",
//   campLocation: "Perth Square",
//   campStartDateTime: "26/02/2023",
//   campEndDateTime: "26/02/2023",
//   campRegStartDateTime: "22/01/2023",
//   campRegEndDateTime: "25/02/2023",
//   ageGroup: "14-16 Years",
//   campMinAge: 14,
//   campMaxAge: 16,
//   campType: "BOYS CAMP",
//   campSpots: 25,
//   campFee: 30,
//   campStatus: "ACTIVE"

const handleRemove = ()=>{
    console.log('remove clicked')
    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
          container: "my-swal",
          icon: 'no-border'

          //    confirmButton: 'your-confirm-button-class',
      },
  }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            (async () => {
                let inputData = {
                  userId: localStorage.getItem("userId"),
                  campId : campId
                };
                let apiInfo = {
                  // url: "https://cncdevapi.natsoft.io/api/camps/removecamp",
                  url:`${SERVER_URL}${REMOVE_CAMP}`,
                  method: "POST",
                  postData : inputData,
                  headers: {
                    "Content-Type": "application/json",
                    tokenheaderkey: localStorage.getItem("token"),
                  },
                }
                let output = await apiService2(apiInfo)
                let removeOp = output.data.responseData[0][0]
                console.log(removeOp)
                if(output.data.responseData[0][0].Code === '0'){
                  Swal.fire({
                    iconHtml: `<img src="${GreenCheck}" >`, 
                    text: output?.data?.responseData[0][0]?.Desc,
                    confirmButtonColor: "#379592",
                  })
                  .then((result)=>{
                    if(result.isConfirmed){
                        history.push("/CampList1")
                    }
                    }
                    );
                }
               else if (output.data.responseData[0][0].Code !== '0'){
                Swal.fire({
                  icon: 'error',
                  // iconHtml: `<img src="${GreenCheck}" >`, 
                  text: output?.data?.responseData[0][0]?.Desc,
                  confirmButtonColor: "#379592",
                  customClass: {
                    container: "my-swal",
                    icon: 'no-border'
          
                    //    confirmButton: 'your-confirm-button-class',
                },
                })
                .then((result)=>{
                  if(result.isConfirmed){
                      history.push("/CampList1")
                  }
                  }
                  
                  );
               }
              })()
          
        } 
      })
}
React.useEffect(()=>{
  (async () => {
    setLoading(true)
    let inputData = {
      userId: localStorage.getItem("userId"),
      // campId : '5133'
      campId : campId
    };
    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/viewcamp",
      url:`${SERVER_URL}${VIEW_CAMP}`,
      method: "POST",
      postData : inputData,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    }
    let output = await apiService2(apiInfo)
    let viewOp = output.data.responseData[0][0]
    setLoading(false)
    console.log(viewOp)
    
    setViewData(viewOp)
    setRows(viewOp.groupInfo)
    setFlist(viewOp.fileList && viewOp.fileList)
  })()

}
  ,[])

  
  return (
    
    <ThemeProvider theme={theme}>
      
        <CssBaseline />
        <div>
          <Box>
          <Stack
                direction="row"
                spacing={1}
                xs={12}
                sm={12}
                md={6}
               my='0.5rem'
                alignSelf="center"
              >
                
                <Link to="/CampList1">
                <Box
                  mt="0.5rem"
                  alignSelf="center"
                  component="img"
                  sx={{ height: "0.75rem", width: "0.65rem" ,cursor:'pointer'}}
                  height="20"
                  alt="Logo"
                  src={ArrowBack}
                ></Box>
              </Link>
                <Box className="gilroy" alignSelf="center">
                  Remove Camp
                </Box>
                
              </Stack>
          </Box>
          {loading === true ? (<Grid container justifyContent='center'><CircularProgress/></Grid>) 
          : (
            <Box >
            <Grid mx="1rem" className="txt-grey">
              <Box>
                <TextField
                  id="standard-basic"
                  label="Camp Name"
                  variant="standard"
                  value={viewData.campName}
                  InputProps={{
                    readOnly: true,
                    
                  }}
                 InputLabelProps = {{
                  shrink: true
                 }}
                  sx={{
                    width: 220,
                  }}
                ></TextField>
              </Box>
              <Grid container spacing={4} py='0.5rem'>  
                <Grid item xs={12} sm={12} md={4} >
                  <InputLabel 
                   htmlFor="standard-adornment-basic"
                  
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                   Start Date
                    </InputLabel>
                  <Input
              id="standard-adornment-basic"
              value={viewData.campStartDateTime}
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name="Start Date"
                    
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={CalenderDark} width='1rem'></Box>
                </InputAdornment>
              }
            />             
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                <InputLabel 
                   htmlFor="standard-adornment-basic"
                   
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                   End Date
                    </InputLabel>
                  <Input
                   
              id="standard-adornment-basic"
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name="End Date"
                    value={viewData.campEndDateTime}
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={CalenderDark} width='1rem'></Box>
                </InputAdornment>
              }
            />             
                </Grid>
                
                <Grid item xs={12} sm={12} md={4} pt='0.5rem'>
                  <TextField
                    id="standard-basic"
                    sx={{ width: 200 }}
                    label="Location"
                    variant="standard"
                    className="txt-grey "
                    name="location" 
                    InputProps={{
                     
                      readOnly:true
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={viewData.campLocation}
                      
                  />
                </Grid>
              </Grid>
              <Grid container spacing={4}>
               
                <Grid item xs={12} sm={12} md={4} >
                <InputLabel 
                   htmlFor="standard-adornment-basic"
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                   Registartion Open Date
                    </InputLabel>
                  <Input
              id="standard-adornment-basic"
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name="Registartion Open Date"
                    value={viewData.campRegStartDateTime}
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={CalenderDark} width='1rem'></Box>
                </InputAdornment>
              }
            />  
                  
                </Grid>
                <Grid item xs={12} sm={12} md={4} >
                <InputLabel 
                   htmlFor="standard-adornment-basic"
                    
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                   Registartion End Date
                    </InputLabel>
                  <Input
              id="standard-adornment-basic"
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name="Registartion End Date"
                    value={viewData.campRegEndDateTime}
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={CalenderDark} width='1rem'></Box>
                </InputAdornment>
              }
            />  
                </Grid>
                
                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    id="standard-basic"
                    label="Max Spots:   "
                    variant="standard"
                    sx={{ width: 200 }}
                    // sx={{ my: "1rem" }}
                    className="txt-grey"
                    name="maxSpots"
                    value={`${viewData.campSpots} Spots`}
                    inputProps={{
                      readOnly:true
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  ></TextField>
                  
                </Grid>
              </Grid>
              <Grid container spacing={4}>
                <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                   <InputLabel 
                   htmlFor="standard-adornment-basic"
                    
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                    Minimum Age
                    </InputLabel>
                  <Input
              id="standard-adornment-basic"
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name="Minimum Age"
                    
                    value={`${viewData.campMinAge} Yrs`}
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={Dropdown}></Box>
                </InputAdornment>
              }
            />
                </Grid>
                <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                   
                <InputLabel 
                   htmlFor="standard-adornment-basic"
                    
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                    Maximum Age
                    </InputLabel>
                  <Input
              id="standard-adornment-basic"
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name="Maximum Age"
                    value={`${viewData.campMaxAge} Yrs`}
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={Dropdown}></Box>
                </InputAdornment>
              }
            />
                  
                </Grid>
                <Grid item xs={12} sm={12} md={4} mt="0.35rem">
                <InputLabel 
                   htmlFor="standard-adornment-basic"
                    
                    sx={{
                      "&.MuiInputLabel-root": {
                        fontSize: 12,
                      },
                    }}
                   >
                    Camp Type
                    </InputLabel>
                  <Input
              id="standard-adornment-basic"
              inputProps={{
                readOnly:true
              }}
              variant="standard"
                    sx={{ width: 220 }}  
                    name=" Camp Type"
                    // value={viewData && viewData.campType}
                    defaultValue=''
                    value={viewData.campType && viewData.campType.charAt(0).toUpperCase() + viewData.campType.slice(1).toLowerCase()}
                   
              endAdornment={
                <InputAdornment position="end">
                  <Box component='img' src={Dropdown}></Box>
                </InputAdornment>
              }
            />
                </Grid>
              </Grid>
            </Grid>
            <Box mt="2rem">
              <TableContainer component={Paper}>
                <Table
                  sx={{ minWidth: 500 }}
                  aria-label="customized table"
                  my="1rem"
                >
                  <TableHead>
                    <TableRow>
                      <StyledTableCell align="center">Group</StyledTableCell>
                      <StyledTableCell align="center">
                        Group Leader
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        Support Staff
                      </StyledTableCell>
                      <StyledTableCell align="center">Therapist</StyledTableCell>
                      <StyledTableCell align="center">Medication Manager</StyledTableCell>
                      
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {console.log(rows)}
                    {rows && rows.map((row,index) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                       {row.groupName}
                        </TableCell>
  
                        <TableCell align="center">
                          
                            <Grid
                              container
                              display="flex"
                              justifyContent="center"
                            >
                              <Grid item>
                                
                                {(row.staffList).map((item,index)=>
                                <Box key={index}>
                                  {item.rolesname === 'GROUP LEADER' && item.staffName}
                                </Box>
                                  )}
                              </Grid>
                          </Grid>
                         
                        </TableCell>
                        <TableCell align="center">
                         
                            <Grid
                              container
                              display="flex"
                              justifyContent="center"
                            >
                              <Grid item>
                              {(row.staffList).map((item,index)=>
                                <Box key={index}>
                                  {item.rolesname ==='SUPPORT STAFF' && item.staffName}
                                </Box>
                                  )}
                              </Grid>
                            </Grid>
                        </TableCell>
                        <TableCell align="center">
                         
                            <Grid
                              container
                              display="flex"
                              justifyContent="center"
                            >
                              <Grid item>
                              {(row.staffList).map((item,index)=>
                                <Box key={index}>
                                  {item.rolesname ==='THERAPIST' && item.staffName}
                                </Box>
                                  )}
                              </Grid>
                              </Grid>
                            
                        </TableCell>
                        <TableCell align="center">
                           
                            <Grid
                              container
                              display="flex"
                              justifyContent="center"
                            >
                              <Grid item>
                              {(row.staffList).map((item,index)=>
                                <Box key={index}>
                                  {item.rolesname ==='MEDICATION MANAGER' && item.staffName}
                                </Box>
                                  )}
                              </Grid>
                              
                            </Grid>
                          
                        </TableCell>
                        
                      </TableRow>
                    ))}
                  </TableBody>
                  
                </Table>
              </TableContainer>
             
            </Box>
  
            <Box
              component="form"
              sx={{
                "& .MuiTextField-root": {
                  mt: "3rem",
                  width: "40%",
                  mb: "1rem",
                },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                name="description"
                InputProps={{
                  readOnly: true,
                  
                }}
                InputLabelProps ={{
                  shrink:true
                }}
                value={viewData.campDescription}
              />
              
            </Box>
            <Box>
              {/* {test==0 ? (<>Working</>):(<>Not working</>)} */}
              <TextField
                id="standard-basic"
                label="Fee"
                variant="standard"
                className="txt-grey"
                name="campFee"
                InputLabelProps ={{
                  shrink:true
                }}
                InputProps={{
                  readOnly: true,
                
                }}
                value={`$ ${viewData.campFee}`}
              ></TextField>
              
            </Box>
            
              <Box >
              <Box py='1.5rem'><Divider/></Box>
              <Box>
              <Box>Thumbnail (Only One Picture)</Box>
              {flist !== null && flist.length > 0 && flist.map((list,index)=>{
            return(  
              list.documentContent === 'Thumbnail' && 
              <Box component='img' key={index}
              src={ list.documentPath}
              sx={{height:'120px',width:'200px',borderRadius:'10px'}} 
              ></Box>
            )}
            )}
              
              </Box>
              <Box py='1.5rem'><Divider/></Box>
              <Box>Attachments</Box>
              {/* <Box 
              component='img' 
              src={SampleImg}
              width='12%' height='12%'
              sx={{my:'0.5rem',borderRadius:'0.5rem'}}
              /> */}
              {flist !== null && flist.length > 0 && flist.map((list,index)=>{
            return(  
              list.documentContent !== 'Thumbnail' && 
              // <Grid container>
                <Box component='img' key={index}
              src={ list.documentPath}
              sx={{height:'80px',width:'150px',borderRadius:'10px',mx:'0.5rem'}} 

              ></Box>
              
              // </Grid>
              
            )}
            )}
              </Box>
   
            <Grid container justifyContent="flex-end">
              <Grid item>
              <Link className='link2' to='/CampList1'>
                <Button
                  variant="contained"
                  sx={{
                    px: "2rem",
                    mx: "1rem",
                    bgcolor: "#909090",
                    textTransform: "none",
                    "&:hover": { background: "#909090" },
                  }}
                  className="gilroy"
                >
                  Cancel
                </Button>
                </Link>
              </Grid>
              <Grid item>
    
                <PublishButton
                  variant="contained"
                  className="gilroy"
                  sx={{ px: "2rem", mx: "1rem" }}
                  onClick={handleRemove}
                >
                  Remove
                </PublishButton>
               
              </Grid>
            </Grid>
            </Box>
          )}
         
         
        </div>
      </ThemeProvider>
  );
}


