import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { Grid, Stack } from "@mui/material";
import "../App.css";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { apiService } from "../Services/apiService";
import { useHistory } from "react-router-dom";

import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Swal from "sweetalert2";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import Typography from "@mui/material/Typography";
import dayjs from "dayjs";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import {
  SERVER_URL,
  UPDATE_STAFF_PROFILE,
  GET_PROFILE
} from "../Services/apiEndpints";


const formFields = {
  firstName: "",
  lastName: "",
  dateofBirth: "",
  gender: "",
  address: "",
  email: "",
  mobileNumber: "",
  password: "",
  userRole: "",
};
let rolesName = localStorage.getItem('rolesName')


// let rolesName =localStorage.getItem("rolesName").charAt(0).toUpperCase() + localStorage.getItem("rolesName").slice(1).toLowerCase()


let rolesArr = []

if (rolesName === 'CAMP MANAGER'){
   rolesArr = [
  {
        // rolesId: 9,
        roles_group: "STAFF",
        rolesName: "Camp Staff"
    }
  
  ]
}
else if(rolesName === 'ADMIN' || rolesName === 'CAMP ADMIN' ){
    rolesArr = [
      {
            // rolesId: 9,
            roles_group: "STAFF",
            rolesName: "Camp Staff"
        },
        {
          // rolesId: 9,
          roles_group: "STAFF",
          rolesName: "Camp Manager"
      } 
    ]
}
// const rolesArr = [
//   {
//     rolesId: 3,
//     roles_group: "ADMIN",
//     rolesName: "Camp Manager",
//   },

//   {
//     rolesId: 5,
//     roles_group: "STAFF",
//     rolesName: "Therapist",
//   },
//   {
//     rolesId: 6,
//     roles_group: "STAFF",
//     rolesName: "Medication Manager",
//   },
// ];
export default function Register() {
  const history = useHistory();
  const current = new Date();
  const [showPassword, setShowPassword] = useState(false);
  const [getUserData, setGetUserData] = useState({});
  const [today , setToday] = React.useState(null); 


  // const date = `${
  //   current.getMonth() + 1
  // }/${current.getDate()}/${current.getFullYear()}`;
 
  
  // const dateCheck = new Date();

  // const date2 = `${dateCheck.getMonth() + 1}/${
  //   dateCheck.getDate()  
  // }/${dateCheck.getFullYear()}`;
  React.useEffect(()=>{
    var date = new Date(); 
    var day = date.getTime() - (1 * 24 * 60 * 60 * 1000); 
    date.setTime(day); 
    const newDateVal =  formatDate(new Date(date).toISOString().substr(0, 10))
    setToday(newDateVal)
  },[])
 
 
  

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const getProfileDetails = async () => {
    let apiInfo = {
      // url: `${process.env.REACT_APP_API_URL}auth/getprofile`,
      url:`${SERVER_URL}${ GET_PROFILE}`,

      method: "POST",
      postData: {
        email: localStorage.getItem("email"),
        userId: localStorage.getItem("userId"),
      },
    };
    let response = await apiService(apiInfo);
    console.log("response((((((((((", response.data.responseData[0][0]);
    if (response?.data?.responseCode === 0) {
      return {
        firstName: response.data.responseData[0][0]["firstName"],
        lastName: response.data.responseData[0][0]["Lastname"],
        email: response.data.responseData[0][0]["email"],
        rolesName: response.data.responseData[0][0]["rolesName"],
        // contactNo: response.data.responseData[0][0]['contactNo'],
        // Gender: response.data.responseData[0][0]['Gender']
      };
    }
  };
  // const phoneRegExp = /^((\\+91-?)|0)?[0-9]{10}$/;
  const phoneRegExp = /^[1-9][0-9]{9}$/;
  // const phoneRegExp = /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[6789]\d{9}$/

  // const phoneRegExp = /^(\d{1,9})$/

  const passwordRegExp =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

    const doSomething = async (value) => {
      var date = new Date(value); 
      var day = date.getTime() - (10 * 24 * 60 * 60 * 1000); 
       date.setTime(day); 
     const newDateVal =  formatDate(new Date(date).toISOString().substr(0, 1))
     setToday(newDateVal)
    //    setToday(new Date(date).toISOString().substr(0, 10)); 
    // do something with my select value onChange
  //  const val = await trigger(['campName']) // Trigger validation on select
  
  };
  function formatDate(date) { 
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First name is required"),
    lastName: Yup.string().required("Last name is required"),
    // dateOfBirth: Yup.date().required("Date of birth is required").nullable().transform(v => (v instanceof Date && !isNaN(v) ? v : null))
    //   .max(date2, "Future date is not expected"),
    dateOfBirth: Yup.date()
      .nullable()
      .typeError("DOB is required"),
      // .max(date2, "Future and current date are not accepted"),

    gender: Yup.string().required("Gender is required"),
    address: Yup.string().required("Address is required"),
    mobileNumber: Yup.string()
      .required("Mobile Number is required")
      .matches(phoneRegExp, "Mobile number is not valid"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    rolesName: Yup.string().required("User role is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(
        passwordRegExp,
        "Must be 8 characters 1 uppercase, 1 lowercase, 1 Number and 1 special character."
      )
      .min(8, "Password must be at least 8 characters")
      .max(12, "Password must not exceed 12 characters"),

    //   staffEmail: Yup.string().required("Email is required").matches(EMAIL_REGEXP, "Email is invalid"),

    ConfirmPassword: Yup.string()

      .required("Confirm Password should match with password")
      .oneOf(
        [Yup.ref("password")],
        "Confirm Password should match with password"
      ),
    // .min(6, "Password must be at least 6 characters")
    // .max(12, "Password must not exceed 12 characters"),
    // string.min(limit: number | Ref, message?: string | function): Schema
    // date.max(limit: Date | string | Ref, message?: string | function): Schema
    // username: Yup.string()
    //   .required('Username is required')
    //   .min(6, 'Username must be at least 6 characters')
    //   .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    // password: Yup.string()
    //   .required('Password is required')
    //   .min(6, 'Password must be at least 6 characters')
    //   .max(40, 'Password must not exceed 40 characters'),
    // confirmPassword: Yup.string()
    //   .required('Confirm Password is required')
    //   .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
    // userRole: Yup.string().required('User is required'),
    // gender: Yup.string().required('Gender is required'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    defaultValues: async () => await getProfileDetails(),
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    delete data.ConfirmPassword;
    delete data.rolesName;
    data["DOB"] = data.dateOfBirth;
    data["mobile"] = data.mobileNumber;
    data["userId"] = localStorage.getItem("userId");

    delete data.dateOfBirth;
    delete data.mobileNumber;

    console.log(JSON.stringify(data, null, 2));
    if (data) {
      let apiInfo = {
        // url: `${process.env.REACT_APP_API_URL}auth/updatestaffprofile`,
        url:`${SERVER_URL}${UPDATE_STAFF_PROFILE}`,
        method: "POST",
        postData: JSON.stringify(data, null, 2),
        headers : {
          "Content-Type": "application/json",
          tokenheaderkey:localStorage.getItem("token"),
        },
      };
      let register = await apiService(apiInfo);
      if (register?.data?.responseCode === "0") {
        Swal.fire({
          // icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          text: register?.data?.responseDesc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        }).then(() => {
          history.push("/");
        });
        //
      } else {
        Swal.fire({
          icon: "error",
          text: register?.data?.responseDesc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
        });
      }
    }
  };
  // const [formData, setFormData] = useState(formFields);
  // const [showPassword, setShowPassword] = useState(false);
  // const [userRoles, setUserRoles] = useState([]);
  // const [errors, setErrors] = useState([]);
  // const noRegEx = ["userRole", "gender", "dateofBirth"];

  // const history = useHistory();

  // const handleClickShowPassword = () => setShowPassword((show) => !show);

  // const handleMouseDownPassword = (event) => {
  //   event.preventDefault();
  // };

  // const handleChange = (event) => {
  //   console.log(event)
  //   const { name, value } = event.target;
  //   console.log("name and value", name, value);

  //   setFormData((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));

  // };

  // console.log("errors", errors);

  // const validateFeild = (name) => {
  //   switch (name) {
  //     case "firstName":
  //       return /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;
  //     case "lastName":
  //       return /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;
  //     case "mobileNumber":
  //       // return /[0-9]+$/;
  //       return /^((\\+91-?)|0)?[0-9]{10}$/;

  //     case "email":
  //       return /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;

  //     // case "gender":
  //     // return /^$/;
  //     case "password":
  //       // return /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/;
  //       return /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,16}$/;

  //     case "address":
  //       return /^[a-zA-Z0-9\s,'-]*$/;
  //   }
  // };

  // const validateFeild = (name) => {
  //   switch (name) {
  //     case "firstName":
  //       return /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;

  //     case "lastName":
  //       return /^[a-zA-Z]+(\s[a-zA-Z]+)?$/;

  //     case "mobileNumber":
  //       return /[0-9]+$/;
  //   }
  // };

  // const getUserRoles = async () => {
  //   let apiInfo = {
  //     url: https://cncdevapi.natsoft.io/api/auth/userroles,
  //     method: "POST",
  //     postData: {},
  //   };
  //   console.log("process", process.env);

  //   let roles = await apiService(apiInfo);
  //   if (roles?.data?.responseData) {
  //     setUserRoles(roles?.data?.responseData);
  //   }
  //   console.log("roles", roles);
  // };
  // useEffect(() => {
  //   getUserRoles();
  // }, []);

  // const onSubmit2 = async () => {
  //   console.log(formData)

  //   let apiInfo = {
  //     url: `${process.env.REACT_APP_API_URL}auth/signup`,

  //     method: "POST",
  //     postData: formData,
  //   };
  //   let register = await apiService(apiInfo);
  //   if (register?.data?.responseCode === "0") {

  //     Swal.fire({
  //       icon: 'success',
  //       title: "Account created successfully",
  //       confirmButtonText: 'OK',
  //       confirmButtonColor: '#379592',

  //     })
  //     history.push("/");
  //   } else {

  //     Swal.fire({
  //       icon: 'error',

  //       text: register?.data?.responseDesc,
  //       confirmButtonText: 'OK',
  //       confirmButtonColor: '#379592',

  //     })
  //   }
  // };

  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    // overrides: {
    //   MuiRadioGroup: {
    //     root: {
    //       color: 'green',
    //     },
    //     colorSecondary: {
    //       '&$checked': {
    //         color: 'green',
    //       },
    //     },
    //   },
    // },  // MuiFormLabel-root-MuiInputLabel-root
   
    components: {
      MuiInputLabel:{
        styleOverrides:{
            asterisk:{
               color:"red",
               fontSize: 24
            }
        }
     },
      MuiCssBaseline: {
        styleOverrides: {
          "@font-face": {
            fontFamily: "gilroyMedium",
            src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
          },
        },
      },
    },
  });
  
  return (
   
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <Box> */}
      <Grid
        container
        direction="flex"
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "100vh",
          backgroundImage: `url(${Image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          // overflowY:'hidden'
        }}
      >
        <Grid item >
          {/* <div className='outer'> */}
          <Card
            // sx={{
            //   borderRadius: "20px",
            //   minWidth: 700,
            //   maxHeight: "550px",
            //   overflowY: "scroll",
            // }}
            sx={{ minWidth: 700,borderRadius: "20px",}}
          >
            {/* <Box sx={{ overflowY: "scroll",overflowX: "hidden"}}> */}
            <CardContent sx={{ mx: "0.5rem", maxHeight: "480px", overflow: "auto", overflowX: "hidden"}}>
              {/* <Box sx={{ overflowY: "scroll",overflowX: "hidden"}}> */}
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <CardMedia
                  component="img"
                  height="20%"
                  image={image}
                  alt="Logo"
                  sx={{ width: "25%" }}
                />
              </Box>
              <Box
                className="normal headingColor"
                my="0.75rem"
                sx={{ fontSize: "1.2rem", textAlign: "center" }}
              >
                Complete the Registration
              </Box>
              <Box component="form" noValidate autoComplete="off" >
                <Grid container columnSpacing={3} sx={{ textAlign: "center" }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField 
                      id="standard-basic"
                      label="First Name"
                      variant="standard"
                      name="firstName"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      {...register("firstName")}
                      error={errors.firstName ? true : false}
                      sx={{ width: "80%",
                      // "& .MuiButtonBase-root.MuiRadio-root.Mui-checked "
                      "& .MuiFormLabel-root.MuiInputLabel-root": {
                        // fontSize: 29,
                       
                      },
                    }}
                    
                    />
                    <Typography
                      color="error.main"
                      ml="2rem"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.firstName?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField 
                      id="standard-basic"
                      label="Last Name"
                      variant="standard"
                      InputProps={{
                        readOnly: true,
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      name="lastName"
                      {...register("lastName")}
                      error={errors.lastName ? true : false}
                      sx={{ width: "80%" }}
                    />
                    {/* <Box color='error.main' ml='2rem' sx={{ typography: 'subtitle2', textAlign: 'left' }}>
                        {errors.lastName?.message}
                      </Box> */}
                    <Typography 
                      color="error.main"
                      ml="2rem"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.lastName?.message}
                    </Typography>

                    {/* <Stack direction="row" ml='1.85rem'>
                    
                    <TextField
          id="standard-read-only-input"
          label=" "
          
          defaultValue="+ 61"
          InputProps={{
            readOnly: true,
          }}
          variant="standard"
          sx={{ width: '30px',
          ".MuiInputBase-input.MuiInput-input":{
            color:"#757575"
          }
        }}
        />
                    <TextField
                      id="standard-basic"
                      label="Mobile"
                      variant="standard"
                      name="mobileNumber"
                      {...register("mobileNumber")}
                      error={errors.mobileNumber ? true : false}
                      sx={{ width: "78%" }}

                    />
                    
                    
                    </Stack>
                    <Typography color='error.main' ml='2rem' sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {errors.mobileNumber?.message}
                    </Typography> */}
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} sx={{ textAlign: "center" }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField 
                      id="standard-basic"
                      label="Email"
                      // helperText="Use as primary contact"
                      variant="standard"
                      name="email"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register("email")}
                      error={errors.email ? true : false}
                      sx={{ width: "80%" }}
                    />
                    {/* <Box color='error.main' ml='2rem' sx={{ typography: 'subtitle2', textAlign: 'left' }}>
                        {errors.email?.message}
                      </Box> */}
                    <Typography
                      color="error.main"
                      ml="2rem"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.email?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    {/* <FormControl variant="standard" sx={{ width: 258 }}>
                                            <InputLabel
                                                id="demo-simple-select-standard-label"
                                                error={errors.userRole ? true : false}
                                            >
                                                Roles
                                            </InputLabel>
                                            <TextField
                                                labelId="demo-simple-select-standard-label"
                                                id="demo-simple-select-standard"
                                                label="Roles"
                                                name="rolesName"
                                                {...register('rolesName')}
                                                error={errors.rolesName ? true : false}
                                            >
                                                <MenuItem value="">
                                                    <em>None</em>
                                                </MenuItem>
                                                {rolesArr.map((role) => (
                                                    <MenuItem value={role.rolesId}>
                                                        {role.rolesName}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                            <Typography
                                                color="error.main"
                                                sx={{ fontSize: '12px', textAlign: 'left' }}
                                            >
                                                {errors.userRole?.message}
                                            </Typography>
                                        </FormControl> */}

                    <TextField 
                      id="standard-basic"
                      label="Roles"
                      variant="standard"
                      name="rolesName"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      InputProps={{
                        readOnly: true,
                      }}
                      {...register("rolesName")}
                      error={errors.rolesName ? true : false}
                      sx={{ width: "80%", textTransform:"lowercase" }}
                    />
                    <Typography
                      color="error.main"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.userRole?.message}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} sx={{ textAlign: "center" }}>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField 
                      type="date"
                      // defaultValue="2017-05-24"
                      id="standard-basic"
                      sx={{ width: "80%" }}
                      // inputFormat="MM/DD/YYYY"
                      // minDate={dayjs('02/14/2022')}
                      label="DOB"
                      variant="standard"
                      name="dateOfBirth"
                      {...register("dateOfBirth")}
                      error={errors.dateOfBirth ? true : false}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{ 
                        max: today,
                      }}
                      // onChange={(e) => doSomething(e.target.value)}
                      // inputProps={{ 
                      //   max: today,
                       
                      // }}
                    />
                    <Typography 
                      color="error.main"
                      ml="2rem"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.dateOfBirth?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField 
                      id="standard-basic"
                      label="Address"
                      variant="standard"
                      name="address"
                      {...register("address")}
                      error={errors.address ? true : false}
                      sx={{ width: "80%" }}
                    />
                    <Typography
                      color="error.main"
                      ml="2rem"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.address?.message}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  columnSpacing={3}
                  // sx={{ textAlign: "center" }}
                  // display="flex"
                  // justifyContent="space-between"
                  ml="0.5rem"
                >
                  <Grid item mt="1rem" xs={12} sm={6} md={6}>
                    <FormControl>
                      <FormLabel 
                        id="demo-row-radio-buttons-group-label"
                        className="medium"
                        error={errors.gender ? true : false}
                      >
                        Gender
                      </FormLabel>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        className="small colorSecondary"
                        // {...controlProps('e')}
                        error={errors.gender ? true : false}
                        sx={{
                          "& .MuiSvgIcon-root": {
                            fontSize: 10,
                          },
                          "& .MuiFormControlLabel-label": {
                            fontSize: 12,
                          },

                          "& .MuiButtonBase-root.MuiRadio-root.Mui-checked ": {
                            color: "#379592",
                          },
                        }}
                        name="gender"
                      >
                        <FormControlLabel
                          className="small"
                          {...register("gender")}
                          value="female"
                          control={<Radio />}
                          label="Female"
                        />
                        <FormControlLabel
                          className="small"
                          {...register("gender")}
                          value="male"
                          control={<Radio />}
                          label="Male"
                        />
                        <FormControlLabel
                          {...register("gender")}
                          value="Prefer not to say"
                          control={<Radio />}
                          label="Prefer not to say"
                        />
                      </RadioGroup>
                      <Typography
                        color="error.main"
                        sx={{ fontSize: "12px", textAlign: "left" }}
                      >
                        {errors.gender?.message}
                      </Typography>
                    </FormControl>
                  </Grid>
                  <Grid item mt="1rem" xs={12} sm={6} md={6}>
                    {/* <FormControl variant="standard" sx={{ width: 258 }} >
                      <InputLabel id="demo-simple-select-standard-label" error={errors.userRole ? true : false}>
                        Roles
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-standard-label"
                        id="demo-simple-select-standard"
                        label="Roles"
                        name="userRole"
                        {...register("userRole")}
                        error={errors.userRole ? true : false}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {rolesArr.map((role) => (
                          <MenuItem value={role.rolesId}>
                            {role.rolesName}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography color='error.main' sx={{ fontSize: '12px', textAlign: 'left' }}>
                        {errors.userRole?.message}
                      </Typography>
                    </FormControl> */}
                    <TextField 
                      id="standard-basic"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      // helperText="Must be at least 6 characters"
                      variant="standard"
                      name="password"
                      {...register("password")}
                      error={errors.password ? true : false}
                      sx={{ width: 250 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      color="error.main"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.password?.message}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container columnSpacing={3} ml="0.5rem">
                  <Grid item xs={12} sm={6} md={6}>
                    {/* <TextField
                      id="standard-basic"
                      label="Password"
                      // helperText="Must be at least 6 characters"
                      variant="standard"
                      name="password"
                      {...register("password")}
                      error={errors.password ? true : false}
                      sx={{ width: 250 }}
                    />
                    <Typography color='error.main' sx={{ fontSize: '12px', textAlign: 'left' }}>
                      {errors.password?.message}
                    </Typography> */}
                    <Stack direction="row">
                      <TextField 
                        id="standard-read-only-input"
                        label=" "
                        defaultValue="+ 61"
                        InputProps={{
                          readOnly: true,
                        }}
                        variant="standard"
                        sx={{
                          width: "30px",
                          ".MuiInputBase-input.MuiInput-input": {
                            color: "#757575",
                          },
                        }}
                      />
                      <TextField 
                        id="standard-basic"
                        label="Mobile"
                        variant="standard"
                        name="mobileNumber"
                        {...register("mobileNumber")}
                        error={errors.mobileNumber ? true : false}
                        sx={{ width: "78%" }}
                      />
                    </Stack>
                    <Typography
                      color="error.main"
                      ml="2rem"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.mobileNumber?.message}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                    <TextField 
                      id="standard-basic"
                      label="Confirm Password"
                      type={showPassword ? "text" : "password"}
                      // helperText="Must be at least 6 characters"
                      variant="standard"
                      name="ConfirmPassword"
                      {...register("ConfirmPassword")}
                      error={errors.ConfirmPassword ? true : false}
                      sx={{ width: 250 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                            >
                              {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    <Typography
                      color="error.main"
                      sx={{ fontSize: "12px", textAlign: "left" }}
                    >
                      {errors.ConfirmPassword?.message}
                    </Typography>
                  </Grid>
                </Grid>
                
              </Box>
              {/* </Box> */}
              
            </CardContent>
            {/* </Box> */}
           
            <Box
              sx={{ width: "100%", textAlign: "center" }}
              mb="0.75rem"
              mt="1rem"
            >
              <Button
                variant="contained"
                sx={{
                  px: "5rem",
                  // py: "0.5rem",
                  bgcolor: "#379592",
                  textTransform: "none",
                  "&:hover": { background: "#379592" },
                }}
                // onClick={() => onSubmit()}
                onClick={handleSubmit(onSubmit)}
              >
                Submit
              </Button>
            </Box>
          </Card>
          {/* </div> */}
          
        </Grid>
      </Grid>
      {/* </Box> */}
    </ThemeProvider>
  );
}


