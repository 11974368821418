import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png"
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import Input from '@mui/material/Input';
import TextField from "@mui/material/TextField";
import InputLabel from '@mui/material/InputLabel';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Calender from "../Assets/Icons/Icons/calendar.png"
import Dropdown from "../Assets/Icons/Icons/Dropdown.png"
import Button from "@mui/material/Button";
import InputAdornment from '@mui/material/InputAdornment';
import CalenderDark from "../Assets/Icons/Icons/CalenderDark.png"
import { Link, useHistory } from "react-router-dom";
import Divider from "@mui/material/Divider";
import SampleImg from "../Assets/Icons/Icons/sample.png"
import CircularProgress from '@mui/material/CircularProgress';
import Image from "../Assets/Icons/Icons/Background-image.png";
import Swal from "sweetalert2";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";
import question from "../Assets/Icons/Icons/questionMark.png";
import PdfIcon from "../Assets/Icons/Icons/PdfIcon.png";
import {
  apiService,
  apiService2,
  apiServiceImage,
} from "../Services/apiService";
import {
  SERVER_URL,
  REMOVE_CAMP,
  VIEW_CAMP
} from "../Services/apiEndpints";

import { useParams } from "react-router-dom";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});

export default function ViewCamp() {
  const [rows, setRows] = React.useState([])
  const [flist, setFlist] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [hoursTable, setHoursTable] = React.useState([])
  const history = useHistory();
  let { campId } = useParams();
  // {
  //   campId: 5017,
  //   campName: "Adventure Camp",
  //   campDescription: "Among these specialty camps are those geared towards kids with special needs, enabling them to experience the joys and excitement of camp like every other child.",
  //   campLocation: "Perth Square",
  //   campStartDateTime: "26/02/2023",
  //   campEndDateTime: null,
  //   campRegStartDateTime: "22/01/2023",
  //   campRegEndDateTime: "25/02/2023",
  //   ageGroup: "14-16 Years",
  //   campMinAge: 14,
  //   campMaxAge: 16,
  //   campType: "BOYS CAMP",
  //   campSpots: 25,
  //   campFee: 30,
  //   campStatus: "ACTIVE",}
  const [viewData, setViewData] = React.useState([]);
  // console.log(campId)
  React.useEffect(
    async () => {
      setLoading(true)
      let inputData = {
        userId: localStorage.getItem("userId"),
        // campId : '5133'
        campId: campId
      };
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/viewcamp",
        url: `${SERVER_URL}${VIEW_CAMP}`,
        method: "POST",
        postData: inputData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      }
      let output = await apiService2(apiInfo)
      console.log(output)
      let viewOp = output.data.responseData[0][0]
      if (viewOp.videoInfo) {
        viewOp.videoInfo = JSON.parse(viewOp.videoInfo)

      }
      setLoading(false)
      // console.log(viewOp)


      setViewData(viewOp)
      setRows(viewOp.groupInfo)
      setFlist(viewOp.fileList && viewOp.fileList)

      setHoursTable(JSON.parse(viewOp.campHoursDatewise))


    }


    , [])
  console.log(viewData)
  // {console.log(flist,flist.length)}
  // {flist.length>0 && console.log(flist[0].documentPath)}
  const handleRemove = () => {
    console.log('remove clicked')
    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
        icon: 'no-border'
      },
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        (async () => {
          let inputData = {
            userId: localStorage.getItem("userId"),
            campId: campId
          };
          let apiInfo = {
            // url: "https://cncdevapi.natsoft.io/api/camps/removecamp",
            url: `${SERVER_URL}${REMOVE_CAMP}`,
            method: "POST",
            postData: inputData,
            headers: {
              "Content-Type": "application/json",
              tokenheaderkey: localStorage.getItem("token"),
            },
          }
          let output = await apiService2(apiInfo)
          let removeOp = output.data.responseData[0][0]
          console.log(removeOp)
          if (output.data.responseData[0][0].Code === '0') {
            Swal.fire({
              iconHtml: `<img src="${GreenCheck}" >`,
              text: output?.data?.responseData[0][0]?.Desc,
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
                icon: 'no-border'
              },
            })
              .then((result) => {
                if (result.isConfirmed) {
                  history.push("/CampList1")
                }
              }
              );
          }
          else if (output.data.responseData[0][0].Code !== '0') {
            Swal.fire({
              icon: 'error',
              // iconHtml: `<img src="${GreenCheck}" >`, 
              text: output?.data?.responseData[0][0]?.Desc,
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",

              },
            })
              .then((result) => {
                if (result.isConfirmed) {
                  history.push("/CampList1")
                }
              }

              );
          }
        })()

      }
    })
  }
  return (

    <ThemeProvider theme={theme}>
      <CssBaseline />

      <div>
        <Box>
          <Grid
            display='flex'
            // spacing={1}
            xs={12}
            sm={12}
            md={6}
            // my='0.5rem'
            // alignSelf="center"
            justifyContent='space-between'
          >
            <Grid item>
              <Stack direction='row' spacing={1}>
                <Link to="/CampList1">
                  <Box
                    // mt="0.75rem"
                    component="img"
                    sx={{ height: "0.75rem", width: "0.65rem", cursor: 'pointer' }}
                    height="20"
                    alt="Logo"
                    src={ArrowBack}
                  ></Box>
                </Link>
                <Box className="gilroy" alignSelf="center">
                  View Camp
                </Box>
              </Stack>
            </Grid>

            <Grid item>
              <Stack direction='row' >
                <Link to={`/EditCamp/${campId}`} className="link2">
                  <Button
                    variant="contained"
                    sx={{
                      px: "1.5rem",
                      width: '150px',
                      mx: "1rem",
                      bgcolor: "#379592",
                      textTransform: "none",
                      "&:hover": { background: "#379592" },
                    }}
                    className="gilroy"
                  >
                    Edit Camp
                  </Button>
                </Link>
                {/* <Link to={`/RemoveCamp/${campId}`} className="link2"> */}
                <Button
                  variant="contained"
                  sx={{
                    px: "1.5rem",
                    mx: "1rem",
                    bgcolor: "#F21010",
                    textTransform: "none",
                    "&:hover": { background: "#F21010" },
                    width: '150px',
                  }}
                  className="gilroy"
                  onClick={handleRemove}
                >
                  Remove Camp
                </Button>
                {/* </Link> */}

              </Stack>
            </Grid>


          </Grid>
        </Box>
        {loading === true ? (<Grid container justifyContent='center'><CircularProgress /></Grid>) : (<Box>
          <Grid mx="1rem" className="txt-grey">
            <Box>
              <TextField
                id="standard-basic"
                label="Camp Name"
                variant="standard"
                value={viewData.campName}
                InputProps={{
                  readOnly: true,

                }}
                InputLabelProps={{
                  shrink: true
                }}
                // sx={{
                //   width: 220,
                // }}
              ></TextField>
            </Box>
            <Grid container spacing={4} py='0.5rem'>
              <Grid item xs={12} sm={12} md={4} >
                <InputLabel
                  htmlFor="standard-adornment-basic"

                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  Start Date
                </InputLabel>

                <Input
                  id="standard-adornment-basic"
                  value={viewData.campStartDateTime}
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  // sx={{ width: 220 }}
                  name="Start Date"

                  endAdornment={
                    <InputAdornment position="end">
                      <Box component='img' src={CalenderDark} width='1rem'></Box>
                    </InputAdornment>
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} >
                <InputLabel
                  htmlFor="standard-adornment-basic"

                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  End Date
                </InputLabel>
                <Input

                  id="standard-adornment-basic"
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  // sx={{ width: 220 }}
                  name="End Date"
                  value={viewData.campEndDateTime}
                  endAdornment={
                    <InputAdornment position="end">
                      <Box component='img' src={CalenderDark} width='1rem'></Box>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4} pt='0.5rem'>
                <TextField
                  id="standard-basic"
                  // sx={{ width: 200 }}
                  label="Location"
                  variant="standard"
                  className="txt-grey "
                  name="location"
                  InputProps={{

                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={viewData.campLocation}

                />
              </Grid>
            </Grid>
            <Grid container spacing={4}>

              <Grid item xs={12} sm={12} md={4} >
                <InputLabel
                  htmlFor="standard-adornment-basic"
                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  Registartion Open Date
                </InputLabel>
                <Input
                  id="standard-adornment-basic"
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  // sx={{ width: 220 }}
                  name="Registartion Open Date"
                  value={viewData.campRegStartDateTime}
                  endAdornment={
                    <InputAdornment position="end">
                      <Box component='img' src={CalenderDark} width='1rem'></Box>
                    </InputAdornment>
                  }
                />

              </Grid>
              <Grid item xs={12} sm={12} md={4} >
                <InputLabel
                  htmlFor="standard-adornment-basic"

                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  Registartion End Date
                </InputLabel>
                <Input
                  id="standard-adornment-basic"
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  // sx={{ width: 220 }}
                  name="Registartion End Date"
                  value={viewData.campRegEndDateTime}
                  endAdornment={
                    <InputAdornment position="end">
                      <Box component='img' src={CalenderDark} width='1rem'></Box>
                    </InputAdornment>
                  }
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  id="standard-basic"
                  label="Max Spots:   "
                  variant="standard"
                  // sx={{ width: 200 }}
                  // sx={{ my: "1rem" }}
                  className="txt-grey"
                  name="maxSpots"
                  value={`${viewData.campSpots} Spots`}
                  inputProps={{
                    readOnly: true
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                ></TextField>

              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                <InputLabel
                  htmlFor="standard-adornment-basic"

                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  Minimum Age
                </InputLabel>
                <Input
                  id="standard-adornment-basic"
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  sx={{ width: 200 }}
                  name="Minimum Age"

                  value={`${viewData.campMinAge} Yrs`}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <Box component='img' src={Dropdown}></Box>
                //   </InputAdornment>
                // }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.25rem">

                <InputLabel
                  htmlFor="standard-adornment-basic"

                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  Maximum Age
                </InputLabel>
                <Input
                  id="standard-adornment-basic"
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  sx={{ width: 200 }}
                  name="Maximum Age"
                  value={`${viewData.campMaxAge} Yrs`}
                // endAdornment={
                //   <InputAdornment position="end">
                //     <Box component='img' src={Dropdown}></Box>
                //   </InputAdornment>
                // }
                />

              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.35rem">
                <InputLabel
                  htmlFor="standard-adornment-basic"

                  sx={{
                    "&.MuiInputLabel-root": {
                      fontSize: 12,
                    },
                  }}
                >
                  Camp Type
                </InputLabel>
                <Input
                  id="standard-adornment-basic"
                  inputProps={{
                    readOnly: true
                  }}
                  variant="standard"
                  // sx={{ width: 220 }}
                  name=" Camp Type"
                  // value={viewData && viewData.campType}
                  defaultValue=''
                  value={viewData.campType && viewData.campType.charAt(0).toUpperCase() + viewData.campType.slice(1).toLowerCase()}

                // endAdornment={
                //   <InputAdornment position="end">
                //     <Box component='img' src={Dropdown}></Box>
                //   </InputAdornment>
                // }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid mt="2rem">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="customized table"
                my="1rem"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Group</StyledTableCell>
                    <StyledTableCell align="center">
                      Group Leader
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      Support Staff
                    </StyledTableCell>
                    <StyledTableCell align="center">Therapist</StyledTableCell>
                    <StyledTableCell align="center">Medication Manager</StyledTableCell>

                  </TableRow>
                </TableHead>
                <TableBody>

                  {rows &&
                    rows.map((row, index) => (
                      <TableRow key={row.id}>
                        <TableCell align="center">
                          {row.groupName}
                        </TableCell>

                        <TableCell align="center">

                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>

                              {(row.staffList).map((item, index) =>
                                <Box key={index}>
                                  {item.rolesId === '9' && item.staffName}
                                </Box>
                              )}
                            </Grid>
                          </Grid>

                        </TableCell>
                        <TableCell align="center">

                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>
                              {(row.staffList).map((item, index) =>
                                <Box key={index}>
                                  {item.rolesId === '4' && item.staffName}
                                </Box>
                              )}
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell align="center">

                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>
                              {(row.staffList).map((item, index) =>
                                <Box key={index}>
                                  {item.rolesId === '5' && item.staffName}
                                </Box>
                              )}
                            </Grid>
                          </Grid>

                        </TableCell>
                        <TableCell align="center">

                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>
                              {(row.staffList).map((item, index) =>
                                <Box key={index}>
                                  {item.rolesId === '6' && item.staffName}
                                </Box>
                              )}
                            </Grid>

                          </Grid>

                        </TableCell>

                      </TableRow>
                    ))}
                </TableBody>

              </Table>
            </TableContainer>

          </Grid>
          <Grid container direction='flex' alignItems='center' justifyContent='space-between'>
            <Grid item md={8} sm={12}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    mt: "3rem",
                    width: "100%",
                    mb: "1rem",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  name="description"
                  InputProps={{
                    readOnly: true,

                  }}
                  InputLabelProps={{
                    shrink: true
                  }}
                  value={viewData.campDescription}
                />

              </Box>
            </Grid>
            {/* <Grid item  md={5} sm={12}> 
            <Box>
            
            <TextField
              id="standard-basic"
              label="Fee for Support Staff"
              variant="standard"
              className="txt-grey"
              name="campFeeSS"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: true,

              }}
              value={`$ ${viewData.campFeeSS}`}
            ></TextField>

          </Box>
            </Grid> */}

          </Grid>
          <Box mt={3} mb={3}>
            {" "}
            <Divider />
          </Box>
          <Grid container direction='flex' alignItems='center' justifyContent='space-between'>
            <Grid item md={6} sm={12}>
              <Box mt="2rem">
                <TableContainer component={Paper}>
                  <Table
                    // sx={{ minWidth: 500 }}
                    aria-label="customized table"
                    my="1rem"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>
                        <StyledTableCell align="center">
                          Hours
                        </StyledTableCell>

                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {hoursTable?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {item.campDate}

                          </TableCell>
                          <TableCell align="center">

                            {item.campHours}
                          </TableCell>
                        </TableRow>
                      ))}


                    </TableBody>
                  </Table>
                </TableContainer>

              </Box>
            </Grid>
            <Grid container display='flex' mt='2rem' >
              <Grid item md={3} sm={12}>
                <Box>
                  {/* {test==0 ? (<>Working</>):(<>Not working</>)} */}
                  <TextField
                    id="outlined-basic"
                    label="Fee for Participant"
                    variant="outlined"
                    className="txt-grey"
                    name="campFee"
                    // size='small'
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: true,

                    }}
                    value={`$ ${viewData.campFee}/hr`}
                  ></TextField>


                </Box>
              </Grid>
              <Grid item md={3} sm={12} ml='2rem'>
                <Box>

                  <TextField
                    id="outlined-basic"
                    label="Fee for Support Staff"
                    variant="outlined"
                    className="txt-grey"
                    name="campFeeSS"
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      readOnly: true,

                    }}
                    value={`$ ${viewData.campFeeSS}`}
                  ></TextField>

                </Box>
              </Grid>
            </Grid>

          </Grid>
          {/* <Grid item  md={5} sm={12}> 
            <Box>
            
            <TextField
              id="standard-basic"
              label="Fee for Support Staff"
              variant="standard"
              className="txt-grey"
              name="campFeeSS"
              InputLabelProps={{
                shrink: true
              }}
              InputProps={{
                readOnly: true,

              }}
              value={`$ ${viewData.campFeeSS}`}
            ></TextField>

          </Box>
            </Grid> */}
          <div>
            <Box >
              <Box py='1.5rem'><Divider /></Box>
              <Box>
                <Box>Thumbnail (Only One Picture)</Box>
                {flist !== null && flist.length > 0 && flist.map((list, index) => {
                  return (
                    list.documentContent === 'Thumbnail' &&
                    <Box component='img' key={index}
                      src={list.documentPath}
                      sx={{ height: '120px', width: '200px', borderRadius: '10px' }}
                    ></Box>
                  )
                }
                )}
                {/* <Box component='img'
               src={viewData.campThumbnail ? (viewData.campThumbnail) : (Image)}
               sx={{height:'120px',width:'200px',borderRadius:'10px'}} 
               ></Box> */}

              </Box>
              <Box py='1.5rem'><Divider /></Box>
              <Box >Videos</Box>
              {viewData.videoInfo && viewData.videoInfo.map((item, index) => {
                return (
                  <span className="thumbnail-span" key={index}>
                    {/* <span  > */}
                    {item.actualUrl != null ? (
                      <Box my='1rem'>
                        <Grid container display='row' alignItems='center' >
                          <Grid item md={4} sx={{ textAlign: 'center' }}>

                            <Box sx={{ cursor: 'pointer', textAlign: 'center' }}>
                              <a href={item.actualUrl} target='_blank'>
                                <img
                                  src={item.thumbnailurl}

                                  style={{ height: "80%",width:'80%', borderRadius: '10px' }}
                                />
                              </a>
                            </Box>
                          </Grid>

                          <Grid item md={8} >
                            <Box sx={{ width: '100%' }} >
                              <TextField
                                id="outlined-multiline-static"
                                label="Description"
                                multiline
                                rows={5}
                                name="description"

                                value={item.description}
                                // onChange={(event) => handleComboDescription(event, index)}
                                sx={{ width: '100%', borderRadius: '20px' }}
                              // defaultValue="Default Value"
                              />
                            </Box>

                          </Grid>
                        </Grid>
                      </Box>


                    ) : (
                      ""
                    )}


                  </span>

                )
              })}
              <Box py='1.5rem'><Divider /></Box>
              <Box>Attachments</Box>
              {flist !== null && flist.length > 0 && flist.map((list, index) => {
                let x = (list.documentPath).search('.pdf')

                if (x !== -1)

                  return (
                    // <Box sx={{ color: '#0075CE' }}>

                    //   <a href={list.documentPath} target="_blank">
                    //     {list.documentTitle}
                    //   </a>
                    // </Box>
                    <Grid item my='0.25rem'>
                          <Box
                            component="img"
                            sx={{ height: "65%", mx: "0.5rem" }}
                            alt="Logo"
                            src={PdfIcon}
                          ></Box>
                          <a href={list.documentPath} target="_blank">
                            {list.documentTitle}
                          </a>
                        </Grid>

                  )
                else if (x === -1)
                  return (

                    list.documentContent !== 'Thumbnail' &&

                    <Box component='img' key={index} my='0.25rem'
                      src={list.documentPath}
                      sx={{ height: '80px', width: '150px', borderRadius: '10px', mx: '0.5rem' }}

                    ></Box>


                  )
              }
              )}



            </Box>


          </div>
        </Box>)}


      </div>


    </ThemeProvider>
  );
}


