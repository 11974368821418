import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import Iconawesome from "./Assets/Icon-awesome-check-circle.png";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'
import "./VerifyOTP.css";
import Mail from "../Assets/Icons/Icons/Mail.png"


const theme = createTheme({
    typography: {
        fontFamily: "gilroyMedium",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "gilroyMedium",
                    src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                },
            },
        },
    },
})

export default function ForgotPasswordPopup() {
    const history = useHistory();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Grid container
                    direction="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100vh', backgroundImage: `url(${Image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                >
                    <Grid item>
                        <Card sx={{ borderRadius: '20px' }}>
                            <CardContent sx={{ mx: '1.5rem', maxWidth: '300px' }}>
                                {/* <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CardMedia
                                        component="img"
                                        height="30%"
                                        image={image}
                                        alt="Logo"
                                        sx={{ width: "65%" }}
                                    />
                                </Box> */}
                                <Box mt='2rem' mb='1rem' sx={{ display: "flex", justifyContent: "center" }}>
                                    <CardMedia
                                        component="img"
                                        height="30%"
                                        image={Mail}
                                        alt="Logo"
                                        sx={{ width: "20%" }}
                                    />
                                </Box>
                                <Box className="normal " my='0.75rem' sx={{ fontSize: '1.2rem', textAlign: 'center' }}>Check your Email</Box>
                                <Box className='small' sx={{textAlign:'center'}}>You will receive an email with instructions to reset your password</Box>
                                <Box className="normal" sx={{ width: "100%" }} my='4rem'>
                                    <Button
                                        
                                        variant="contained"
                                        sx={{
                                            bgcolor: "#379592",
                                            width:'100%',
                                            // paddingLeft: "20%",
                                            // paddingRight: "20%",
                                            '&:hover': { background: "#379592" },textTransform:'none'
                                        }}
                                        onClick={() => history.push("/verifyotp")}
                                    >
                                        OK
                                    </Button>
                                </Box>
                                {/* <Box
                                    className=" medium"
                                    sx={{ fontSize: "small", }}
                                >
                                    Did not receive the email? Please check your spam folder, or {""}
                                    <Link className="link" href="#" underline="hover">
                                        try another email address.
                                    </Link>
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Box>
        </ThemeProvider>
    );
}
