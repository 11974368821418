import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import Iconawesome from "./Assets/Icon-awesome-check-circle.png";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'
import "./VerifyOTP.css";
import Mail from "../Assets/Icons/Icons/Mail.png"
import Greencheck from "../Assets/Icons/Icons/GreenCheck.png"

const theme = createTheme({
    typography: {
        fontFamily: "gilroyMedium",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "gilroyMedium",
                    src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                },
            },
        },
    },
})

 

export default function UserAddedPopup() {
    const history = useHistory();
    function handleClick(){
        return(history.push('/ActivateStaff'))
    }
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Grid container display='flex' justifyContent='center' alignItems='center' height='100vh'  >
                
                <Box textAlign='center' >
                <Box component='img' src={Greencheck}  width='45%' ></Box>
                <Box className='test' sx={{color:'#3B3B3B'}} my='3rem' >User Added Succesfully!</Box>
                
                <Button
                variant="contained"
                sx={{
                  px: "5rem",
                  
                  bgcolor: "#379592",
                  textTransform: 'none',
                  "&:hover": { background: "#379592" },
                }}
                onClick={()=>handleClick()}
              >
                OK
              </Button>
                </Box>
                
            </Grid>
        </ThemeProvider>
    );
}
