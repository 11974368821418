import React, { useState } from "react";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import "../index.css";
import Grid from "@mui/material/Grid";

import Swal from "sweetalert2";
import { CssBaseline } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";

import InputBase from "@mui/material/InputBase";

import { Link } from "react-router-dom";
import {apiService2 } from "../Services/apiService";
import Pagination from "@mui/material/Pagination";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";

import UpDown from "../Assets/Icons/Icons/Updown.png"

import CircularProgress from '@mui/material/CircularProgress';

import {
  SERVER_URL,
  ACTIVE_INACTIVE_STAFF,
  GETLIST_STAFF,
} from "../Services/apiEndpints";

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  palette: {
    secondary: {
      main: "#379592",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
  Muidisabled: {
    styleOverrides: {
      backgroundColor: "purple",
    },
  },
});





const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  // color: theme.palette.getContrastText(purple[500]),
  color: "white",
  backgroundColor: "#379592",
  "&:hover": {
    backgroundColor: "#379592",
  },
}));

export default function ActivateCampStaff() {
  const [searchFirstName, setSearchFirstName] = React.useState("");
  const [searchLastName, setSearchLastName] = React.useState("");
  const [searchTitle, setTitle] = React.useState("");
  
  const [sortType,setSortType] = React.useState('default');
  const[loading,setLoading] = React.useState(false)

  const [firstName,setFirstName] = React.useState('')
  const [lastName,setLastName] = React.useState('')
  const [role,setRole] = React.useState('')
  const [stat,setStat] = React.useState('')

  // const [firstName,setFirstName] = React.useState('defaultFirstName')
  // const [firstName,setFirstName] = React.useState('defaultFirstName')
  
  const handleSearchChange = (event) => {
    setSearchFirstName(event.target.value);
  };
  const handleLastNameSearchChange = (event) => {
    setSearchLastName(event.target.value);
  };

  const handleTitleSearchChange = (event) => {
    setTitle(event.target.value);
  };

  const [staffOutput, setStaffOutput] = React.useState([
    {
      userId: "",
      userName: "",
      email: "",
      profileName: "",
      userRole: "",
      rolesName: "",
      userStatus: "",
      firstName: "",
      lastName: "",
    },
  ]);

  

  // const testFuncton = (fname) => {
  //   filteredList.sort((a, b) => (a.fname > b.fname) ? 1: -1);
  //   // setTest(false)
  //   console.log('sfop',filteredList);
  //   console.log(fname);
    
  // }

  const filteredList = staffOutput.filter((item) => {
    // console.log(filteredList)
    return (
      item.firstName.toLowerCase().includes(searchFirstName.toLowerCase()) &&
      item.lastName.toLowerCase().includes(searchLastName.toLowerCase()) &&
      item.rolesName.toLowerCase().includes(searchTitle.toLowerCase())
    );
  });

  const [currentPage, setCurrentPage] = React.useState(1);
  
  const [selectedItem, setSelectedItem] = useState(false);

  const [isConditionMet, setIsConditionMet] = useState(true);

  
  const getStaffStatusActive = async (item) => {
    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/staff/activeinactivestaff",
      url :`${SERVER_URL}${ACTIVE_INACTIVE_STAFF}`,
      method: "POST",
      postData: {
        userId: localStorage.getItem("userId"),
        // userId: '7',
        staffUserID: item.userId,
        staffStatus: "ACTIVE",
      },
      headers: {
        "Content-Type": "application/json",
        // tokenheaderkey:
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMjAwMkB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NzA0NjIyMCwiZXhwIjoxNjg0ODIyMjIwfQ._bK2cl7VnnoxdnldS32kiOFPAWZdFz9Q-L9yk_0bvl0",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let activeOutput = await apiService2(apiInfo);
    
    setSelectedItem(item);
    setIsConditionMet(false);
    Swal.fire({
      // icon: 'success',
      // iconHtml:`<img src="${GreenCheck}" style="width:50px;height:50px ">`,
      iconHtml: `<img src="${GreenCheck}" >`,
      text: activeOutput?.data?.responseData[0][0]?.Desc,
      confirmButtonText: "OK",
      confirmButtonColor: "#379592",
    });
  };

  

  const getStaffStatusInactive= async (item) => {
    // console.log( localStorage.getItem("userId"))
    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/staff/activeinactivestaff",
      url :`${SERVER_URL}${ACTIVE_INACTIVE_STAFF}`,
      method: "POST",
      postData: {
        userId: localStorage.getItem("userId"),
        // userId: "7",
        staffUserID: item.userId,
        staffStatus: "INACTIVE",
      },
      headers: {
        "Content-Type": "application/json",
        // tokenheaderkey:
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMjAwMkB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NzA0NjIyMCwiZXhwIjoxNjg0ODIyMjIwfQ._bK2cl7VnnoxdnldS32kiOFPAWZdFz9Q-L9yk_0bvl0",
        tokenheaderkey: localStorage.getItem("token"),
      },
    };
    let deactiveOutput = await apiService2(apiInfo);

    console.log(deactiveOutput.data.responseData[0]);
    const response = deactiveOutput.data.responseData[0];
    console.log(response);
    // setStatus(status)
    setSelectedItem(item);
    console.log(selectedItem);
    // console.log('inside loop',status)
    setIsConditionMet(true);
    Swal.fire({
      // icon: 'success',
      iconHtml: `<img src="${GreenCheck}" >`,
      text: deactiveOutput?.data?.responseData[0][0]?.Desc,
      confirmButtonText: "OK",
      confirmButtonColor: "#379592",
    });
  };


  React.useEffect(() => {
    (async () => {
      setLoading(true)
      let id = {
        userId: localStorage.getItem("userId"),
        rolesId: 4
      };

      let apiInfo = {

       
        // url : "https://cncdevapi.natsoft.io/api/staff/getlistofstaff",
        url :`${SERVER_URL}${GETLIST_STAFF}`,
        method: "POST",
        postData: id,
        headers: {
          "Content-Type": "application/json",
          // tokenheaderkey:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMjAwMkB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NzAwMTQyMSwiZXhwIjoxNjg0Nzc3NDIxfQ.ODkv4UzvGooMD-A6vaD40pGvxnAXb_F1rsVjha_d16k",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      let login = await apiService2(apiInfo);
      // login.data.responseData[0]?():()
      setLoading(false)
      const listofStaff = login.data.responseData[0];

      // console.log(listofStaff);
      setStaffOutput(listofStaff);
      // console.log("list", staffOutput);
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        // setLoading(false);
      });
  }, [isConditionMet]);
  // console.log('list',staffOutput)

  // console.log("list", staffOutput);


  const ActivateComponent = (props) => {
    return (
    
      <Button  variant="contained" 
      onClick={()=>{getStaffStatusInactive(props.item)
        setIsConditionMet("true")}}
      sx={{
        px: "3rem",
        bgcolor: "#F21010",
        textTransform: 'none',
        "&:hover": { background: "#F21010" },
        width: "100px"
      }}>Deactivate</Button>
    )
  }
  const DeactivateComponent = (props) => {
    return (
    
      <Button  variant="contained"
     
        onClick={()=>{getStaffStatusActive(props.item)
        setIsConditionMet("false")
      }}
      sx={{
        px: "3rem",
        bgcolor: "#4C9F70",
        textTransform: 'none',
        "&:hover": { background: "#4C9F70" },
        width: "100px"
      }}>
        Activate
        {/* {props.item.userId} */}
        </Button>
    )
  }
  const InviteComponent = () => {
    return (
    
      <Button  variant="contained"
      
      sx={{
        px: "3rem",
        bgcolor: "#909090",
        textTransform: 'none',
        "&:hover": { background: "#909090" },
        width: "100px"
      }}>Invited</Button>
    )
  }



  function StatusComponent(stat,item) {
    let component
    switch (stat) {
      case 'INVITED':
        component = <InviteComponent/> 
        break
      case 'ACTIVE':
          component = <DeactivateComponent item={item}/> 
          break
      case 'INACTIVE':
        component = <ActivateComponent item={item}/>
        // component = <MultipleActivateComponent/>
        break

      default:
        component = <Box sx={{ color: 'black' }}></Box>
    }
    return component
  }
  
  

  const handleFirstNameSort = () => {
  setFirstName('defaultFirstName')
    setSortType('upFirstName')
    if(firstName === 'defaultFirstName' && sortType ===  'upFirstName' ){
      setSortType('downFirstName')
    }
    else if(firstName === 'defaultFirstName' && sortType ===  'downFirstName' ){
      setSortType('upFirstName')
    }
    // else if(firstName === 'defaultFirstName' && sortType ===  'defaultFirstName'){
    //   setSortType('upFirstName')
    // }
    
  }


  const handleLastNameSort =() =>{

    setLastName('defaultLastName')
    setSortType('upLastName')
    if(lastName === 'defaultLastName' && sortType ===  'upLastName' ){
      setSortType('downLastName')
    }
    else if(lastName === 'defaultLastName' && sortType ===  'downLastName' ){
      setSortType('upLastName')
    }    
    // else if(lastName === 'defaultLastName' && sortType ===  'defaultLastName' ){
    //   setSortType('upLastName')
    // } 
  }

  const handleRoleSort =() =>{

    setRole('defaultRole')
    setSortType('upRole')
    if(role === 'defaultRole' && sortType ===  'upRole' ){
      setSortType('downRole')
    }
    else if(role === 'defaultRole' && sortType ===  'downRole' ){
      setSortType('upRole')
    }   
   
  }


  const handleStatusSort =() =>{

    setStat('defaultStat')
    setSortType('upStatus')
    if(stat === 'defaultStat' && sortType ===  'upStatus' ){
      setSortType('downStatus')
    }
    else if(stat === 'defaultStat' && sortType ===  'downStatus' ){
      setSortType('upStatus')
    }   
  }

  
  const sortTypes = {
    
    upFirstName: {
      class: 'sort-up',
      fn: (a, b) => (a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? 1: -1
    },
    downFirstName: {
      class: 'sort-down',
      fn: (a, b) =>  (a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? -1 : 1,
    },
    defaultFirstName: {
      class: 'sort',
      fn: (a, b) => a
    },
    upLastName: {
      class: 'sort-up',
      fn: (a, b) => (a.lastName.toUpperCase() > b.lastName.toUpperCase()) ? 1: -1
    },
    downLastName: {
      class: 'sort-down',
      fn: (a, b) =>  (a.lastName.toUpperCase() > b.lastName.toUpperCase()) ? -1 : 1,
    },
    defaultLastName: {
      class: 'sort',
      fn: (a, b) => a
    },
    upRole: {
      class: 'sort-up',
      fn: (a, b) => (a.rolesName.toUpperCase() > b.rolesName.toUpperCase()) ? 1: -1
    },
    downRole: {
      class: 'sort-down',
      fn: (a, b) =>  (a.rolesName.toUpperCase() > b.rolesName.toUpperCase()) ? -1 : 1,
    },
    defaultRole: {
      class: 'sort',
      fn: (a, b) => a
    },
    upStatus: {
      class: 'sort-up',
      fn: (a, b) => (a.userStatus.toUpperCase() > b.userStatus.toUpperCase()) ? 1: -1
    },
    downStatus: {
      class: 'sort-down',
      fn: (a, b) =>  (a.userStatus.toUpperCase() > b.userStatus.toUpperCase()) ? -1 : 1,
    },
    defaultStatus: {
      class: 'sort',
      fn: (a, b) => a
    },
    default: {
      class: 'sort',
      fn: (a, b) => a
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Grid container display="flex">
          <Paper
            xs={12}
            sm={12}
            md={6}
            component="form"
            sx={{
              display: "flex",
              alignItems: "center",
              mx: "0.5rem",
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by First Name"
              value={searchFirstName}
              onChange={handleSearchChange}
            />
          </Paper>
          <Paper
            xs={12}
            sm={12}
            md={6}
            component="form"
            sx={{ display: "flex", alignItems: "center", mx: "0.5rem" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by Last Name"
              value={searchLastName}
              onChange={handleLastNameSearchChange}
            />
          </Paper>
          <Paper
            xs={12}
            sm={12}
            md={6}
            component="form"
            sx={{ display: "flex", alignItems: "center", mx: "0.5rem" }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search by Role"
              value={searchTitle}
              onChange={handleTitleSearchChange}
            />
          </Paper>
        </Grid>
        <Grid container display="flex" justifyContent="space-between">
          <Box className="test" sx={{ color: "#3B3B3B" }} mt="1rem">
            Users
          </Box>
          <SaveButton sx={{ px: "1.5rem", py: "0.5rem" }}>
            <Link to="/AddUser" className="link2">
              Add User
            </Link>
          </SaveButton>
        </Grid>
        <Box className="test" sx={{ color: "#379592" }} mb="0.75rem">
          Support Staff
        </Box>
        {/* </Grid> */}
        {loading === true ? (<Grid container justifyContent='center' alignItems='center' >
          <CircularProgress />
          </Grid >) : (<div>
            <TableContainer component={Paper}>
          <Table sx={{ minWidth: 450 }} aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center">Sl. No</StyledTableCell>
               
                <StyledTableCell sx={{textAlign:'center'}}>
                  {/* <Button  onClick={()=>testFuncton('firstName')}>First Name</Button>  */}
                  <Grid container display='flex' justifyContent='center' sx={{align:'center'}}>
                  <Box align='center' px='0.5rem'>First Name</Box>
                  <Box 
                  // onClick={()=>handleSortStyle('upFirstName')}
                  onClick={()=>handleFirstNameSort()}
                  component='img' 
                  src={UpDown} 
                  sx={{width:'0.75rem', height:'0.75rem',alignSelf:'center',cursor:'pointer'}}
                  />
                  
                  </Grid>
                  
                  </StyledTableCell>
                <StyledTableCell align="center">
                <Grid container display='flex' justifyContent='center' sx={{align:'center'}}>
                  <Box align='center' px='0.5rem'>Last Name</Box>
                  <Box 
                  // onClick={()=>handleSortStyle('upLastName')}
                  onClick={()=>handleLastNameSort()}
                  component='img' 
                  src={UpDown}
                  sx={{width:'0.75rem', height:'0.75rem',alignSelf:'center',cursor:'pointer'}}
                  />
                  
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                <Grid container display='flex' justifyContent='center' sx={{align:'center'}}>
                  <Box align='center' px='0.5rem'>Role</Box>
                  <Box 
                  // onClick={()=>handleSortStyle('upRole')}
                  onClick={()=>handleRoleSort()}
                  component='img' 
                  src={UpDown}
                  sx={{width:'0.75rem', height:'0.75rem',alignSelf:'center',cursor:'pointer'}}
                  />
                  
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                <Grid container display='flex' justifyContent='center' sx={{align:'center'}}>
                  <Box align='center' px='0.5rem'>Status</Box>
                  <Box
                  onClick={()=> handleStatusSort()}
                  component='img' 
                  src={UpDown}
                  sx={{width:'0.75rem', height:'0.75rem',alignSelf:'center',cursor:'pointer'}}
                  />
                  
                  </Grid>
                </StyledTableCell>
                <StyledTableCell align="center">
                  Action
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* {localStorage.getItem("userId") */}

              {filteredList.sort(sortTypes[sortType].fn)
                .slice(currentPage * 10 - 10, currentPage * 10)
                .map((row, index) => (
                  <StyledTableRow
                    key={row.userId}
                    className="normal"
                    sx={{
                      "& .MuiTableCell-root": { py: "0.35rem" },
                    }}
                  >
                    <StyledTableCell component="th" scope="row" align="center"
                    sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}>
                      {index + (currentPage - 1) * 10 + 1}
                      
                    </StyledTableCell>
                    <StyledTableCell align="center"
                     sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}>
                      {/* {row.userName.slice(0, (row.userName.indexOf(" ")))} */}
                      {row.firstName}
                    
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center"
                     sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}>
                      {/* {row.userName.slice((row.userName.indexOf(" ")))} */}
                      {row.lastName}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center"
                     sx={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }}>
                      {row.rolesName.charAt(0).toUpperCase() +
                        row.rolesName.slice(1).toLowerCase()}
                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center" style={{ color: row.userStatus !== 'INVITED' ? '#black' : '#909090' }} >

                      {row.userStatus.charAt(0).toUpperCase() +
                        row.userStatus.slice(1).toLowerCase()}
                      {/* <ActivateButton
                        onClick={() => {
                          getStaffStatusActive(row);
                          setIsConditionMet("false");
                        }}
                        disabled={row.userStatus != "ACTIVE" ? false : true}
                        style={{
                          backgroundColor:
                            row.userStatus != "ACTIVE" ? "green" : "grey",
                          color: "white",
                          width: "100px",
                        }}
                      >
                        {row.userStatus}
                      </ActivateButton> */}
                    </StyledTableCell>
                    <StyledTableCell align="center">
                      {/* <Button 
                   
                        onClick={() => {
                          getStaffStatusDeactive(row);
                          setIsConditionMet("true");
                        }}
                        disabled={row.userStatus === "ACTIVE" ? false : true}
                        variant="contained"
                        sx={{
                          px: "3rem",
                          bgcolor: "#909090",
                          textTransform: 'none',
                          "&:hover": { background: "#909090" },
                          width: "100px"
                        }}
                      > */}
                        {StatusComponent(row.Action,row)}
                        {/* {row.Action.charAt(0).toUpperCase() +
                        row.Action.slice(1).toLowerCase()} */}
                        
                      {/* </Button> */}
                    </StyledTableCell>
                  </StyledTableRow>
                  // </>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box mt="1rem" display="flex" justifyContent="flex-end">
          {/* {console.log(staffOutput)} */}
          {/* <Pagination count={(Math.floor(staffOutput.length / 10) === 0 ? (Math.floor(staffOutput.length / 10) ) : (Math.floor(staffOutput.length / 10)+1))}
          
            onChange={(e, value) => setCurrentPage(value)} color='secondary'
            size="small" /> */}

          <Pagination
            // count={
            //   Math.floor(filteredList.length / 10) === 0
            //     ? Math.floor(filteredList.length / 10)
            //     : Math.floor(filteredList.length / 10) + 1
            // }
            // onChange={(e, value) => setCurrentPage(value)}
            // color="secondary"
            // size="small"
           
            count={
              filteredList.length%10===0 
              ? filteredList.length/10 
              : Math.floor(filteredList.length/10 +1)} 
               onChange={(event,val)=> setCurrentPage(val)}
               color="secondary"
            size="small"
          />
        </Box>
          </div>)}
        
       
      </div>
    </ThemeProvider>
  );
}
