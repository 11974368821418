import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import { Link } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
// import "./VerifyOTP.css";
import { apiService } from "../Services/apiService";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'
import {
    SERVER_URL,
    VERIFY_OTP
  } from "../Services/apiEndpints";

const formFields = {
    email: "",
    token: "",
    otp: "",
    otptype: "verify",
};

const theme = createTheme({
    typography: {
        fontFamily: "gilroyMedium",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "gilroyMedium",
                    src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                },
            },
        },
    },
})

export default function VerifyOTP() {
    const [formData, setFormData] = useState(formFields);
    const history = useHistory();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    console.log("formData", formData);

    const onSubmit = async () => {
        formData.email = localStorage.getItem("email");
        formData.token = localStorage.getItem("token");
        let apiInfo = {
            // url: `${process.env.REACT_APP_API_URL}auth/verifyotp`,
            // url: "https://cncdevapi.natsoft.io/api/auth/verifyotp",
            url:`${SERVER_URL}${VERIFY_OTP}`,
            method: "POST",
            postData: formData,
        };
        let verifyotpRes = await apiService(apiInfo);
        if (verifyotpRes?.data?.responseCode === "0") {
            // alert("successfull");
            history.push("/createNewpassword");
        } else {
            // alert(verifyotpRes?.data?.responseDesc);
            Swal.fire({
                icon: 'error',
                // title: 'Oops...',
                text:verifyotpRes?.data?.responseDesc,
                confirmButtonText: 'OK',
                confirmButtonColor: '#379592',
              })
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Grid container
                    direction="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100vh', backgroundImage: `url(${Image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}
                >
                    <Grid item>
                        <Card sx={{ borderRadius: '20px' }}>
                            <CardContent sx={{ mx: '1.5rem', maxWidth: '300px' }}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CardMedia
                                        component="img"
                                        height="30%"
                                        image={image}
                                        alt="Logo"
                                        sx={{ width: "65%" }}
                                    />
                                </Box>
                                <Box className="normal headingColor" mb='0.75rem' mt='1.2rem' sx={{ fontSize: '1.2rem' }}>Verify OTP</Box>
                                {/* <Box className='small'>Enter the email address associated with your account and we'll send an email with instructions to reset your password</Box> */}
                                <Box my='4rem'>
                                    <TextField

                                        sx={{ width: '95%' }}
                                        id="standard-basic"
                                        label="Enter OTP"
                                        variant="standard"
                                        // className="Email-in-frgpwd"
                                        name="otp"
                                        value={formData.otp}
                                        onChange={(event) => handleChange(event)}
                                    />
                                </Box>
                                <Box mt='2rem' mb='2rem' >
                                    <Button

                                        variant="contained"
                                        sx={{ bgcolor: "#379592", '&:hover': { background: "#379592" }, width: '95%',textTransform:'none' }}

                                        onClick={() => onSubmit()}
                                    >
                                        Submit
                                    </Button>
                                </Box>
                                {/* <Box
                                    className=" medium"
                                    sx={{
                                        
                                        fontSize: "small",

                                    }}
                                >
                                    Did not receive the email? Please check your spam folder, or {""}
                                    <Link className='link' href="#" >
                                        try another email address.
                                    </Link>
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

            </Box>
        </ThemeProvider>

    );
}
