import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import "./VerifyOTP.css";
import FormHelperText from "@mui/material/FormHelperText";
import { apiService } from "../Services/apiService";
import { Link, useHistory } from "react-router-dom";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Typography from '@mui/material/Typography';
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png"
import {
    SERVER_URL,
    RESET_PASSWORD
} from "../Services/apiEndpints";

const formFields = {
    email: "",
    password: "",
    confirmPassword: "",
};
// const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&_])[A-Za-z\d@$!%*?&_]{8,}$/;
const passwordRegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]{8,}$/;

const validationSchema = Yup.object().shape({
    // firstName: Yup.string().required("First name is required"),
    // lastName: Yup.string().required("Last name is required"),
    // dateOfBirth: Yup.string().required("Date of birth is required"),
    // gender: Yup.string().required(),
    // address: Yup.string().required(),
    // mobileNumber: Yup.string()
    //   .required("Mobile Number is required")
    //   .matches(phoneRegExp, "Mobile number is not valid"),
    // email: Yup.string().required("Email is required").email("Email is invalid"),
    // userRole: Yup.string().required("User role is required"),
    password: Yup.string()
        .required("Password is required")
        .matches(passwordRegExp, "Must be 8 characters with at least 1 uppercase, 1 lowercase, 1 number, and 1 special character."),
    // .min(6, "Password must be at least 6 characters")
    // .max(12, "Password must not exceed 40 characters"),
    // username: Yup.string()
    //   .required('Username is required')
    //   .min(6, 'Username must be at least 6 characters')
    //   .max(20, 'Username must not exceed 20 characters'),
    // email: Yup.string().required('Email is required').email('Email is invalid'),
    // password: Yup.string()
    //   .required('Password is required')
    //   .min(6, 'Password must be at least 6 characters')
    //   .max(40, 'Password must not exceed 40 characters'),
    confirmPassword: Yup.string()
        .required('Confirm Password is required')
        .oneOf([Yup.ref('password'), null], 'Confirm Password does not match'),
    // acceptTerms: Yup.bool().oneOf([true], 'Accept Terms is required'),
    // userRole: Yup.string().required('User is required'),
    // gender: Yup.string().required('Gender is required'),
});

export default function CreateNewPassword() {
    const {
        register,
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = useForm({
        resolver: yupResolver(validationSchema),
    });
   

    const [formData, setFormData] = useState(formFields);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [iserror, setIsError] = useState(false);
    const history = useHistory();

    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    console.log("formData", formData);

    const onSubmit = async (data) => {
        console.log(JSON.stringify(data, null, 2));
        // const password = data['password']
        const password = data.password
        const body = {
            "email": localStorage.getItem("email"),
            "password": password
        }
        if (data) {
            let apiInfo = {
                //   url: `${process.env.REACT_APP_API_URL}auth/resetpassword`,
                url: `${SERVER_URL}${RESET_PASSWORD}`,
                method: "POST",
                postData: body
            };
            let changePassword = await apiService(apiInfo);
            if (changePassword?.data?.responseCode === 0) {

                Swal.fire({
                    // icon: 'success',
                    iconHtml: `<img src="${GreenCheck}" >`,
                    text: "Password changed successfully",
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#379592',
                }).then((results) => {
                    history.push("/");
                });
                // 
            } else {


                Swal.fire({
                    icon: 'error',
                    text: changePassword?.data?.responseDesc,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#379592',

                })
            }
        }
        // if(data){
        //     if (formData.password !== formData.confirmPassword) {
        //     setIsError(true);
        //     return false;
        // }
        // delete formData.confirmPassword;
        // formData.email = localStorage.getItem("email");
        // let apiInfo = {
        //     url: `${process.env.REACT_APP_API_URL}auth/resetpassword`,

        //     method: "POST",
        //     postData: formData,
        // };
        // let changePassword = await apiService(apiInfo);
        // if (+changePassword?.data?.responseCode === 0) {

        //     history.push("/passwordchanged");
        // } else {

        //     Swal.fire({
        //         icon: 'error',

        //         text: changePassword?.data?.responseDesc,
        //         confirmButtonText: 'OK',
        //         confirmButtonColor: '#379592',
        //     })
        // }
        // }



        // if (formData.password !== formData.confirmPassword) {
        //     setIsError(true);
        //     return false;
        // }
        // delete formData.confirmPassword;
        // formData.email = localStorage.getItem("email");
        // let apiInfo = {
        //     url: `${process.env.REACT_APP_API_URL}auth/resetpassword`,
        //     // url: "https://cncdevapi.natsoft.io/api/auth/resetpassword",
        //     method: "POST",
        //     postData: formData,
        // };
        // let changePassword = await apiService(apiInfo);
        // if (+changePassword?.data?.responseCode === 0) {
        //     // alert("successful");
        //     history.push("/passwordchanged");
        // } else {
        //     // alert(changePassword?.data?.responseDesc);
        //     Swal.fire({
        //         icon: 'error',
        //         // title: 'Oops...',
        //         text: changePassword?.data?.responseDesc,
        //         confirmButtonText: 'OK',
        //         confirmButtonColor: '#379592',
        //     })
        // }
    };
    const theme = createTheme({
        typography: {
            fontFamily: "gilroyMedium",
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    "@font-face": {
                        fontFamily: "gilroyMedium",
                        src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                    },
                },
            },
        },
    })
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Grid container
                    direction="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100vh', backgroundImage: `url(${Image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}>
                    <Grid item>
                        <Card sx={{ borderRadius: '20px' }}>
                            <CardContent sx={{ mx: '1.5rem', maxWidth: '300px' }}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CardMedia
                                        component="img"
                                        height="40%"
                                        image={image}
                                        alt="Logo"
                                        sx={{ width: "70%" }}
                                    />
                                </Box>
                                <Box className="normal headingColor" mt='1.2rem' mb='0.75rem' sx={{ fontSize: '1.1rem' }}>Create new Password</Box>
                                <Box component="form" noValidate autoComplete="off">
                                    <Box >
                                        <TextField
                                            // error={iserror}
                                            type={showPassword ? "text" : "password"}
                                            id="standard-basic"
                                            label="Password"
                                            variant="standard"
                                            // className="Email-in-verifyotp"
                                            name="password"
                                            // sx={{ width: '100%' }}
                                            // value={formData.password}
                                            // onChange={(event) => handleChange(event)}
                                            {...register("password")}
                                            error={errors.password ? true : false}
                                            sx={{ width: 250 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {/* {showPassword ? <VisibilityOff /> : <Visibility />} */}
                                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}

                                        />
                                        <Typography color='error.main' sx={{ fontSize: '12px', textAlign: 'left' }}>
                                            {errors.password?.message}
                                        </Typography>
                                    </Box>
                                    <Box>
                                        <TextField
                                            type={showConfirmPassword ? "text" : "password"}
                                            id="standard-basic"
                                            label="Confirm Password"
                                            variant="standard"
                                            // className="Email-in-verifyotp"
                                            name="confirmPassword"
                                            // sx={{ width: '100%' }}
                                            // value={formData.password}
                                            // onChange={(event) => handleChange(event)}
                                            {...register("confirmPassword")}
                                            error={errors.confirmPassword ? true : false}
                                            sx={{ width: 250 }}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            aria-label="toggle password visibility"
                                                            onClick={handleClickShowConfirmPassword}
                                                            onMouseDown={handleMouseDownPassword}
                                                        >
                                                            {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                                                        </IconButton>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />
                                        {/* <Box variant="inherit" color="textSecondary">
                                            {errors.password?.message}
                                        </Box> */}
                                        <Typography color='error.main' sx={{ fontSize: '12px', textAlign: 'left' }}>
                                            {errors.confirmPassword?.message}
                                        </Typography>

                                    </Box>
                                </Box>
                                <Box sx={{ textAlign: 'center' }} mt='2rem' mb='4rem'>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            bgcolor: "#379592",
                                            // px: "3rem",
                                            width: '100%',
                                            // paddingRight: "20%",
                                            "&:hover": { background: "#379592" },
                                            textTransform: 'none'
                                        }}
                                        // onClick={() => onSubmit()}
                                        onClick={handleSubmit(onSubmit)}
                                    >
                                        Reset Password
                                    </Button>
                                </Box>
                                {/* <Box
                                    className=" medium"
                                    sx={{
                                        // marginLeft: "15%",
                                        // marginRight: "15%",
                                        // marginBottom: "5%",
                                        fontSize: "small",
                                    }}
                                >
                                    Did not receive the email? Please check your spam folder, or {""}
                                    <Link className="link" href="#" underline="hover">
                                        try another email address.
                                    </Link>
                                </Box> */}
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Box>

        </ThemeProvider>
    );
}


