import React, { useState } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import { useHistory } from "react-router-dom";
import "./ForgotPassword.css";
import { apiService } from "../Services/apiService";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png"
import {
    SERVER_URL,
    OTP
  } from "../Services/apiEndpints";
const theme = createTheme({
    typography: {
        fontFamily: "gilroyMedium",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "gilroyMedium",
                    src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                },
            },
        },
    },
})

const formFields = {
    email: "",
    otptype: "verify",
};

export default function ForgotPassword() {
    const [formData, setFormData] = useState(formFields);
    const history = useHistory();

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    console.log("formData", formData);

    const onSubmit = async () => {
        let apiInfo = {
            // url: `${process.env.REACT_APP_API_URL}auth/otp`,
            url:`${SERVER_URL}${OTP}`,
            method: "POST",
            postData: formData,
        };
        let forgotResponse = await apiService(apiInfo);
        if (forgotResponse?.data?.responseCode === "0") {
            localStorage.setItem("email", formData.email);
            localStorage.setItem("token", forgotResponse?.data?.token);
            // alert("Register successfull");
            history.push("/forgotPasswordPopup");
        } else {
            // alert(forgotResponse?.data?.responseDesc);
            Swal.fire({
                icon: 'error',
                // title: 'Oops...',
                text: forgotResponse?.data?.responseDesc,
                confirmButtonText: 'OK',
                confirmButtonColor:"#379592"
              })
        }
    };
    return (

        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
                <Grid container
                    direction="flex"
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        height: '100vh', backgroundImage: `url(${Image})`,
                        backgroundRepeat: "no-repeat",
                        backgroundSize: "cover"
                    }}>
                    <Grid item>
                        <Card sx={{borderRadius:'20px'}}>
                            <CardContent sx={{ mx: '1.5rem', maxWidth: '300px' }}>
                                <Box sx={{ display: "flex", justifyContent: "center" }}>
                                    <CardMedia
                                        component="img"
                                        height="30%"
                                        image={image}
                                        alt="Logo"
                                        sx={{ width: "60%" }}
                                    />
                                </Box>
                                <Box className="normal headingColor" my='0.75rem' sx={{ fontSize: '1.2rem' }}>Forgot Password</Box>
                                <Box className='small'>Enter the email address associated with your account and we'll send an email with instructions to reset your password.</Box>
                                <Box my='4rem'>
                                    <TextField
                                    
                                        sx={{ width: '95%' }}
                                        id="standard-basic"
                                        label="Email"
                                        variant="standard"
                                        // className="Email-in-frgpwd"
                                        name="email"
                                        value={formData.email}
                                        onChange={(event) => handleChange(event)}
                                    />
                                </Box>
                                <Box mt='2rem' mb='2rem' >
                                    <Button
                                       
                                        variant="contained"
                                        sx={{ bgcolor: "#379592", '&:hover': { background: "#379592" },width:'95%', textTransform:'none' }}
                                        onClick={() => onSubmit()}
                                       
                                    >
                                        Send instructions
                                    </Button>
                                </Box>
                            </CardContent>

                        </Card>
                    </Grid>
                </Grid>

            </Box>
        </ThemeProvider>

    );
}