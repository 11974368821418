import React from "react";
import Grid from "@mui/material/Grid";
import { Box } from "@material-ui/core";
import "../../App.css";
import "../../index.css";
import { Link,useHistory } from "react-router-dom";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import TextField from "@mui/material/TextField";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled, alpha } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
// import {  useHistory } from "react-router-dom";



export default function ServiceAgreement() {
  const history = useHistory();

  const shoot = () => {
    Swal.fire({
      icon: "success",
      iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
      // text: register?.data?.responseData,
      text: "",
      confirmButtonText: "OK",
      confirmButtonColor: "#379592",
    }).then(history.push("/CampList1"));
  }


  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
      borderColor: "#707070",
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
      borderColor: "#707070",
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: "#FFFFFF",
      borderColor: "#707070",
      color: "#707070",
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));


  
  return (
    <>
      <Grid
        xs={12}
        sx={{
          textAlign: "center",
        }}
      >
        <Box className="gilroy">Calm and Connected NDIS Service Agreement</Box>
      </Grid>
      <Grid>
        <Link to="/ApplicantsReview" className="link2">
          <Box
            alignSelf="center"
            component="img"
            sx={{
              height: "0.75rem",
              width: "0.65rem",
              cursor: "pointer",
              mt: "3.75rem",
            }}
            alt="Logo"
            src={ArrowBack}
          ></Box>
        </Link>
      </Grid>
      <Grid container>
        <Grid item md={9}>
        <h4 className="gilroy">Parties </h4>
        <Box>Parties This Service Agreement is for Trial Test , a participant in the National Disability Insurance Scheme (Participant), and is made between:</Box>
        <Box >
          This Service Agreement is for Trial Test , a participant in the
          National Disability Insurance Scheme <br />
          (Participant), and is made between:
        </Box>

        <Grid container>
          <Grid>
            <p className="gilroy">Participant’s Representative </p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "1rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid
          sx={{
            mt: "0rem",
          }}
        >
          <p className="gilroy">
            and <br />
            Provider
          </p>
        </Grid>
        <Grid container>
          <Grid className="gilroy">ABN: 22613026430 </Grid>
          <Grid
            className="gilroy"
            sx={{
              ml: "8rem",
            }}
          >
            Calm and Connected Pty Ltd
          </Grid>
        </Grid>
        <Grid container>
          <Grid>
            <p className="gilroy">NDIS Participant Number:</p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "2.5rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid>
            <p className="gilroy">Participant date of birth:</p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "2.7rem",
              }}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid>
            <p className="gilroy">This Service Agreement will commence on </p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "0.5rem",
                width: "60%",
              }}
            />
          </Grid>
          <Grid>
            <p className="gilroy">for the period </p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "0.5rem",
                width: "60%",
              }}
            />
          </Grid>
          <Grid>
            <p className="gilroy">to </p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "0.5rem",
                width: "60%",
              }}
            />
          </Grid>
        </Grid>
        <p className="gilroy">The NDIS and this Service Agreement</p>
        <Box>
          <div>
            This Service Agreement is made for the purpose of providing supports
            under the Participant’s National
            <br /> Disability Insurance Scheme (NDIS) plan.
          </div>
          <div>
            A copy of the Participant’s NDIS plan is to be given to the
            provider.
          </div>
          <div>
            The Parties agree that this Service Agreement is made in the context
            of the NDIS, which is a scheme that aims to:
          </div>
          <div>
            Support the independence and social and economic participation of
            people with disability, and <br />
            Enable people with a disability to exercise choice and control in
            the pursuit of their goals and the planning
            <br />
            and delivery of their supports.
          </div>
        </Box>
        </Grid>
        
      </Grid>
     
      <Grid>
        <Box
          sx={{
            mt: "2rem",
          }}
        >
          <div className="gilroy">
            Schedule of supports <br /> The Provider agrees to provide the
            Participant:
          </div>
        </Box>
        <Box
          sx={{
            mt: "2rem",
          }}
        >
          <div className="gilroy">
            Funding Category: Improved Daily Living (CB Daily Activities)/Core
            Supports <br /> Item Code:
          </div>
        </Box>
        <Grid container>
          <Grid>
            <p className="gilroy">Management Type: </p>
          </Grid>
          <Grid>
            <TextField
              id="standard-basic"
              variant="standard"
              sx={{
                ml: "0.5rem",
                width: "50%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 450 }} aria-label="customized table">
          <TableHead>
            <StyledTableRow>
              <TableCell align="center">Service</TableCell>
              <TableCell align="center">Costing</TableCell>
            </StyledTableRow>
          </TableHead>

          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">Therapy Intensive Camp</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">Cost: $50 per hour group rate</Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center" className="gilroy">
                    Goal Setting phone call
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">
                    <Grid container>
                      <Grid>
                        <p className="gilroy">Total Hours </p>
                      </Grid>
                      <Grid>
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          sx={{
                            // ml: "0.5rem",
                            width: "60%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">
                    <Grid container>
                      <Grid>
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          sx={{
                            // ml: "0.5rem",
                            width: "60%",
                          }}
                        />
                      </Grid>
                      <Grid>
                        <p className="gilroy">Attendence</p>
                      </Grid>
                      <Grid>
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          sx={{
                            // ml: "0.5rem",
                            width: "60%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center">
                    <Grid container>
                      <Grid>
                        <p className="gilroy">Total Amount </p>
                      </Grid>
                      <Grid>
                        <TextField
                          id="standard-basic"
                          variant="standard"
                          sx={{
                            // ml: "0.5rem",
                            width: "60%",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center" className="gilroy">
                    Camp Summary Report
                  </Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="left">
                <Stack
                  direction="row"
                  justifyContent="center"
                  sx={{ fontSize: "0.5rem" }}
                  spacing={1}
                >
                  <Box alignSelf="left">
                    <Grid container>
                      <Grid>
                        <p className="gilroy">
                          Boys Camp out - $1500
                          <br />
                          Young Ladies Getaway - $1400
                          <br />
                          Mountain Bike Camp - $1650
                          <br />
                          Girls Sleepover - $1400
                          <br />
                          Camp Confidence (night) - $2300 <br />
                          Camp Confidence (day) - $1100{" "}
                        </p>
                      </Grid>
                    </Grid>
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow>
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center" className="gilroy">
                    Total Improved Daily Living Funding Amount:
                  </Box>
                </Stack>
              </StyledTableCell>
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Grid>
                    <TextField
                      id="standard-basic"
                      variant="standard"
                      sx={{
                        // ml: "0.5rem",
                        width: "60%",
                      }}
                    />
                  </Grid>
                </Stack>
              </StyledTableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <Grid>
        <p className="gilroy">
          All prices are GST inclusive (if applicable) and include the cost of
          providing the supports. <br />
          Additional expenses (i.e. things that are not included as part of a
          Participant’s NDIS supports) are the ,<br />
          responsibility of the Participant and their family and are not
          included in the cost of the supports.
          <br />
          Examples include items that are written on the packing list of groups
          and camp programs.
          <br />
        </p>

        <p className="gilroy" sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          Responsibilities of Provider
        </p>

        <div className="gilroy" sx={{ mt: "2.5rem" }}>
          The Provider agrees to:
        </div>
        <div className="gilroy">
          {" "}
          Review the provision of supports at 6 months with Participant
        </div>
        <div className="gilroy">
          {" "}
          Provide pre screen sessions at a mutually agreed time with
          participants{" "}
        </div>
        <div className="gilroy">
          Treat the Participant and their caregivers with courtesy and respect
        </div>
        <div className="gilroy">
          {" "}
          Consult the Participant on decisions about how supports are provided{" "}
        </div>
        <div className="gilroy">
          {" "}
          Give the Participant a minimum of 48 hours’ notice where practical if
          the Provider has to change a scheduled appointment to provide supports{" "}
        </div>
        <div className="gilroy">
          Give the Participant the required notice if the Provider needs to end
          the Service Agreement (see ‘Ending this Service Agreement’ below for
          more information)
        </div>
        <div className="gilroy">
          Protect the Participant’s privacy and confidential information
        </div>
        <div className="gilroy">
          Provide supports in a manner consistent with all relevant laws,
          including the National Disability Insurance Scheme Act 2013
        </div>
        <div className="gilroy">
          and rules, and the Australian Consumer Law; keep accurate records on
          the supports provided to the Participant, and
        </div>
        <div className="gilroy">
          Plan managed: Send signed service agreement to the Plan Manager to
          quarantine funds for camp. Issue invoices to the
        </div>
        <div className="gilroy">
          Participant's Plan Manager directly following camp. Payment is
          required within 7 business days of invoice issue. For camps
        </div>
        <div className="gilroy">
          that cost greater than $1500 payment will be requested 10 days in
          advance of the camp. Refunds will be provided in line with
        </div>
        <div className="gilroy">
          the cancellation policy outlined below if they occur within the 10 day
          period.
        </div>
        <div className="gilroy">
          Self managed: Issue regular invoices directly to the nominated person
          self managing the funds once a place on camp is
        </div>
        <div className="gilroy">
          confirmed. Payment is required within 7 business days of invoice
          issue.
        </div>

        <div className="gilroy">
          Agency Managed: weekly claiming of supports delivered through the NDIS
          portal. Issue monthly statements to the
        </div>

        <div className="gilroy">
          participant for signing to review supports claimed.
        </div>
        <div className="gilroy">
          Be available to attend one follow up session for clients, as therapist
          availability allows, through Calm and Connected’s
        </div>
        <div className="gilroy">
          therapy services to provide handover to the participants supports.
        </div>
        <div className="gilroy">
          Support the use and engagement of formal or informal advocates. This
          may be during service delivery or in providing
        </div>

        <div className="gilroy">
          feedback. Information on available advocacy services can be found on
          our website Click Here
        </div>
      </Grid>

      <Grid>
        <Box sx={{ mt: "3.5rem", mb: "3.5rem" }}>
          <p className="gilroy">
            Responsibilities of Participant’s representative
          </p>
        </Box>
        <div className="gilroy">
          The Participant’s representative agrees to:
        </div>
        <div className="gilroy">
          Inform the Provider about how they wish the supports to be delivered
          to meet the Participant’s needs.
        </div>
        <div className="gilroy">
          Complete all documentation and requirements for camp that is detailed
          in in the pre-information section of the Camp
        </div>
        <div className="gilroy">
          App and the Checklist. This is to ensure that we have all the relevant
          information needed to support the participant and
        </div>
        <div className="gilroy">
          create a safe environment for services to be conducted:
        </div>
        <div className="gilroy">
          Treat the Provider with courtesy and respect, this includes:
        </div>
        <div className="gilroy">
          No smoking, drinking alcohol or taking illicit substances during
          appointments
        </div>
        <div className="gilroy">
          Ensuring pets are kept out of the area that therapy is being conducted
        </div>
        <div className="gilroy">
          Disclose any violence in the household and/or restraining orders
        </div>
        <div className="gilroy">
          Disclose any violent, aggressive or verbally abusive behaviour of
          participants prior to therapists beginning services
        </div>
        <div className="gilroy">
          Talk to the Provider if the Participant has any concerns about the
          supports being provided
        </div>
        <div className="gilroy">
          Abide by the Cancellation Policy outlined below
        </div>
        <div className="gilroy">
          If there are any questions, queries, concerns, changes, developments
          etc the family should contact the Camp Manager to
          <br />
          update them or ask any questions.
        </div>
        <div className="gilroy">
          Disclose any medical conditions that their child may have and complete
          a medical action plan form, or provide one that <br />
          they currently have.
        </div>
        <div className="gilroy">
          Give the Provider the required notice if the Participant needs to end
          the Service Agreement (see ‘Ending this Service <br />
          Agreement’ below for more information), and
        </div>
        <div className="gilroy">
          Let the Provider know immediately if the Participant’s NDIS plan is
          suspended or replaced by a new NDIS plan or the <br /> Participant
          stops being a participant in the NDIS.
        </div>
      </Grid>

      <Grid>
        <Box sx={{ mt: "3.5rem", mb: "3.5rem" }}>
          <div>
            Sharing of Information <br />
            The participant gives consent for Calm and Connected to share
            information for the benefit of the
            <br /> participant with those listed below. Consent can be changed
            or withdrawn at any time by contacting
            <br /> your therapist or a member of our team.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "4.5rem", mb: "4.5rem" }}>
          <div>
            As a registered NDIS provider we are required to be audited by the
            NDIS Commission. As a participant recipient receiving
            <br />
            funding under this scheme you maybe contacted by the commission
            during our audit period in relation to the services you <br />
            have received from Calm and Connected.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Personal Information
            <br />
            Participant information is securely stored on our Client Management
            System Echidna and Information Storage System.
            <br />
            Participants are able to request access to sign into the Family
            Portal with Echidna. This gives access to documents,
            <br />
            appointments and notes as set up in agreement with the therapy team.
            Participants will be specifically asked for permission
            <br />
            to record visual or auditory information and will discuss what this
            will be used for and how it is stored as the need arises.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Cancellation Policy <br />
            Under NDIS Guidelines cancellations with greater than 10 business
            days notice will be refunded minus an administration fee <br />
            of $200. If less than 10 business days notice is given to cancel a
            place on camp we will charge 100% of the full fee. <br />
            Changes to this Service Agreement <br />
            If changes to the supports or their delivery are required, the
            Parties agree to discuss and review this Service Agreement. The{" "}
            <br />
            Parties agree that any changes to this Service Agreement will be in
            writing, signed, and dated by the Parties.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            If the provider changes the prices outlined in this service
            agreement, these changes will be communicated in writing to the{" "}
            <br />
            Participant/Participants representative with a minimum 1-month
            notice. Prices will remain in line with NDIS Pricing <br />
            Arrangements. <br />
            Ending this Service Agreement <br />
            Should either Party wish to end this Service Agreement they must
            give a minimum of 14 days notice in writing and all <br />
            payments claimed/paid for that have been provided until the date of
            finishing the service agreement. <br />
            If either Party seriously breaches this Service Agreement the
            requirement of notice will be waived. <br />
            Calm and Connected reserves the right to withdraw services if the
            terms of this agreement are breached, there is a high level <br />
            of risk, aggression towards staff, or funding package has been
            exhausted.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Feedback, complaints and disputes <br />
            We highly value receiving feedback and welcome any input on how to
            improve our service. We
            <br />
            encourage you to speak directly with your therapist to provide any
            compliments or criticism, or be in
            <br />
            touch with the Director, Kate Keisler with the contact details
            provided below. Otherwise we have a <br />
            Customer Feedback Form available online. <br />
            Kate Keisler <br />
            Calm and Connected Director <br />
            0430645086 <br />
            kate.keisler@calmandconnected.com.au <br />
            Every two years we conduct a Client Satisfaction Survey. You will be
            sent an email to be notified when <br />
            this is occurring and given the opportunity to opt out if you do not
            wish to participate. To read our full Feedback and <br />
            Complaints Policy Click Here or an Easy Language Guide is available
            Click Here.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Calm and Connected support the use and engagement of formal or
            informal advocates to assist you in providing feedback or <br />
            making a complaint. Information on available advocacy services can
            be provided by our team or found on our website Click <br />
            Here.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            If the Participant is not satisfied or does not want to talk to
            someone within the organisation, the <br />
            Participant can contact the NDIS Commission by calling 1800 035 544,
            visiting one of their offices in <br />
            person. You can also visit the NDIS Commission website for details
            on making a complaint Click Here.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Incident Management <br />
            Calm and Connected have an Incident Management System in place in
            line with the NDIS (Incident and Management and <br />
            Reportable Incidents) Rules 2018. If an incident occurs the family
            will be notified and involved in the investigation and <br />
            determining outcomes. A copy of our Incident Management Policy and
            Procedures is available to families on request.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Incident Management <br />
            Calm and Connected have an Incident Management System in place in
            line with the NDIS (Incident and Management and <br />
            Reportable Incidents) Rules 2018. If an incident occurs the family
            will be notified and involved in the investigation and <br />
            determining outcomes. A copy of our Incident Management Policy and
            Procedures is available to families on request.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Restrictive Practice <br />
            As a registered provider of NDIS, Calm and Connected are required to
            report on Restrictive Practices to the NDIS Quality and <br />
            Safeguarding Commission. We will work with your Behaviour Support
            Practitioner as required to reduce the use of restrictive <br />
            practice. If you do not have Behaviour Support funding and a
            restrictive practice becomes evident then we will explain and <br />
            support you through this process.
          </div>
        </Box>
      </Grid>

      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
            Goods and services tax (GST)
            <br />
            For the purposes of GST legislation, the Parties confirm that:
            <br />
            a supply of supports under this Service Agreement is a supply of one
            or more of the reasonable and necessary supports <br />
            specified in the statement included, under subsection 33(2) of the
            National Disability Insurance Scheme Act 2013 (NDIS Act), in <br />
            the Participant’s NDIS plan currently in effect under section 37 of
            the NDIS Act;
            <br />
            the Participant’s NDIS plan is expected to remain in effect during
            the period the supports are provided; and
            <br />
            the Participant’s representative will immediately notify the
            Provider if the Participant’s NDIS Plan is replaced by a new plan or
            the <br />
            Participant stops being a participant in the NDIS.
          </div>
        </Box>
      </Grid>


      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
          Contact details <br />
           The Participant’s representative can be contacted on:
          </div>
        </Box>
      </Grid>


      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 250 }} aria-label="customized table">
      

          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Contact Person</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Mobile</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Email</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>

            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Address</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
           
          </TableBody>
        </Table>
      </TableContainer>


      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <div>
          The Provider can be contacted on
          </div>
        </Box>
      </Grid>


      <TableContainer component={Paper} sx={{ my: "2rem", mt: "5rem" }}>
        <Table sx={{ minWidth: 250 }} aria-label="customized table">
      

          <TableBody>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Contact Person</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Mobile</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Email</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>

            <TableRow
              sx={{
                "& .MuiTableCell-root": { py: "0.45rem" },
              }}
            >
              <StyledTableCell component="th" scope="row">
                <Stack direction="row" justifyContent="left" spacing={1}>
                  <Box alignSelf="center">Address</Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell align="center">
                <Stack direction="row" justifyContent="center" spacing={1}>
                  <Box alignSelf="center"></Box>
                </Stack>
              </StyledTableCell>

              <StyledTableCell component="th" scope="row" align="center">
                <Stack
                  direction="column"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                   
                  </Box>
                </Stack>
              </StyledTableCell>
            </TableRow>
           
          </TableBody>
        </Table>
      </TableContainer>


      <Grid>
        <Box sx={{ mt: "2.5rem", mb: "2.5rem" }}>
          <p>
          Agreement Signatures
          </p>
          <p>
          The Parties agree to the terms and conditions of this service agreement
          </p>
        </Box>
      </Grid>


      <Grid>
        <Box sx={{ mt: "5.5rem", mb: "5.5rem" ,fontSize: "0.65rem"}}>
          <div>
          Signature of [Participant / Participant’s
          </div>
          <div>
          representative]
          </div>
        </Box>
      </Grid>



      <Grid container justifyContent="flex-end">
         
          <Grid item>
            <Button
              variant="contained"
              className="gilroy"
              sx={{ px: "2rem", mx: "0.5rem", bgcolor: "#379592","&:hover": { background: "#379592" }, textTransform: "none",}}
              onClick={shoot}
            >
              Submit
            </Button>
          </Grid>

          <Grid item>
            <Button
              variant="contained"
              sx={{
                px: "2rem",
                mx: "1rem",
                bgcolor: "#909090",
                textTransform: "none",
                "&:hover": { background: "#909090" },
              }}
              className="gilroy"
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
    </>
  );
}
