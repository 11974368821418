import axios from "axios";

export  async function apiService(apiInfo) {
  try {
    const { url, method, postData: data } = apiInfo;

    let apiConfig = { url, method };
    apiConfig = {...apiConfig,
      headers: {'Content-Type': 'application/json',
      'tokenheaderkey' : localStorage.getItem("token")
      // 'tokenheaderkey' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMDIyQHlvcG1haWwuY29tIiwiaWF0IjoxNjc0NTc3ODE1LCJleHAiOjE2NzQ1ODE0MTV9.u2sAAiYH1ITG2oOF503dkz7JlyQdgu1sPn3k1R5pYLA'

    },}
    if (Object.keys(data).length) {
      apiConfig = { ...apiConfig, data };
    }

    console.log("api", apiConfig);
    let response = await axios(apiConfig);
    if (response) {
      return response;
    }
    return false;
  } catch (error) {
    return false;
  }
}

export  async function apiService2(apiInfo) {
  try {
    const { url, method, postData: data ,headers} = apiInfo;
    let apiConfig = { url, method,headers };
    if (Object.keys(data).length) {
      apiConfig = { ...apiConfig, data };
    }

    // console.log("api", apiConfig);
    let response = await axios(apiConfig);
    if (response) {
      return response;
    }
    return false;
  } catch (error) {
    return false;
  }
}


export  async function apiServiceImage(apiInfo) {
  try {
    const { url, method, postData: data } = apiInfo;

    let apiConfig = { url, method };
    apiConfig = {...apiConfig,
      headers: {'Content-Type': 'multipart/form-data',
      'tokenheaderkey' : localStorage.getItem("token")
      // 'tokenheaderkey' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMDIyQHlvcG1haWwuY29tIiwiaWF0IjoxNjc0NTc3ODE1LCJleHAiOjE2NzQ1ODE0MTV9.u2sAAiYH1ITG2oOF503dkz7JlyQdgu1sPn3k1R5pYLA'

    },}
    if (Object.keys(data).length) {
      apiConfig = { ...apiConfig, data };
    }

    console.log("api", apiConfig);
    let response = await axios(apiConfig);
    if (response) {
      return response;
    }
    return false;
  } catch (error) {
    return false;
  }
}

// export async function apiServiceImage(data)
// {const response = await fetch(`https://cncdevapi.natsoft.io/api/camps/fileupload`,
//  { method: 'post',
//   headers: {'content-type': 'multipart/form-data'},
//   data: data
// }
//   )
//   return await response.json();
// }


export async function getProfileImg(data){

  const response = await fetch(`https://cncdevapi.natsoft.io/api/camps/listallcamps`, {

      method: 'post',

      headers: {'Content-Type': 'application/json',
      'tokenheaderkey' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6IlRlc3RwYXJlbnQyMjAwMkB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NDIxMzkyMSwiZXhwIjoxNjc0MjE3NTIxfQ.ZIMgPd1D3R70ho8C6FsTjHn_PnDuDF0RQ_XG36mTq_Q'
    },

      body: JSON.stringify(data)

    })

  return await response.json();

}
