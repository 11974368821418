import React, { useState } from "react";
import Button from "@mui/material/Button";
import { FormControl, Chip, Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
// import { Link } from "react-router-dom";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import ArrowBack from "../../Assets/Icons/Icons/ArrowBack.png";
import InputLabel from "@mui/material/InputLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Modal from "@mui/material/Modal";
import MenuItem from "@mui/material/MenuItem";
import Swal from "sweetalert2";
import question from "../../Assets/Icons/Icons/questionMark.png";
import GreenCheck2 from "../../Assets/Icons/Icons/GreenCheck2.png";
import { useHistory, useParams,useLocation } from "react-router-dom";
import "../../index.css";
import { apiService, apiService2 } from "../../Services/apiService";
import { Update } from "@material-ui/icons";
import CircularProgress from '@mui/material/CircularProgress';
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import {  SERVER_URL,GETCAMP_APPLICANTSCOMMENTS,UPDATESUITABILITY_STATUS,GETCAMP_APPLICANTS} from '../../Services/apiEndpints'

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: '25px'
};

const suitabilityStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  // border: '2px solid #000',
  boxShadow: 24,
  px: 4,
  py: 2,
  borderRadius: '25px'
};

export default function ChangeSuitability() {
  const [loading, setLoading] = React.useState(false)
  const [isConditionMet, setIsConditionMet] = useState(true);
  const [invoiceOpen, setinvoiceOpen] = React.useState(false);
  const [suitabilityOpen, setSuitabilityOpen] = React.useState(false);
  const [fundingStatusOptions, setFundingStatusOptions] = React.useState("");
  const [suitabilityStatusOptions, setSuitabilityStatusOptions] = React.useState("");
  const [childId, setChildId] = React.useState()
  const [campApplicantFundingList, setCampApplicantFundingList] = React.useState()
  const [suitabilityRemarks, setSuitabilityRemarks] = useState('')
  const [fundingRemarks, setFundingRemarks] = useState('')
  const [fundingComments, setFundingComments] = React.useState('')
  const [suitabilityComments, setSuitabilityComments] = React.useState('')
  const [ndisInfo, setNDISInfo] = React.useState([])
  const [invoiceInfo, setInvoiceInfo] = React.useState([])
  let { campId, applicantId } = useParams();


  const[invoiceStat,setInvoiceStat] =React.useState(false)
  const[ndiStat,setNdiStat] =React.useState(false)
  const[downloadStat,setDownloadStat] =React.useState(false)

  const listArray = [
    { title: 'Sent By', name: 'Rosey Michel' },
    { title: 'Sent Date', name: '05 Jun 2010' },
    { title: 'Signed By', name: 'Jessica Michel' },
    { title: 'Signed Date', name: '05 Jun 2010' }
  ]

  const location=useLocation()
  const invoiceHandleOpen = async () => {
    setinvoiceOpen(true)

    let dataSent = {
      userId: localStorage.getItem('userId'),
      campId: campId,
      applicationId: applicantId,
    }

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
      url: `${SERVER_URL}${GETCAMP_APPLICANTSCOMMENTS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    }
    let output = await apiService2(apiInfo)
    let commentList = output.data.responseData[0][0].comments
    setLoading(false)
    commentList && commentList.map((item) => {
      item.Funding && setFundingComments(item.Funding)
    })
  }


  const invoiceHandleClose = () =>{
    setFundingRemarks('')
    setinvoiceOpen(false);

  } 
  const suitabilityHandleOpen = async () => {
    setSuitabilityOpen(true);

    let dataSent = {
      userId: localStorage.getItem('userId'),
      campId: campId,
      applicationId: applicantId,
    }

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicantscomments",
      url: `${SERVER_URL}${GETCAMP_APPLICANTSCOMMENTS}`,
      method: "POST",
      postData: dataSent,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    }
    let output = await apiService2(apiInfo)
    let commentList = output.data.responseData[0][0].comments
    setLoading(false)
    commentList && commentList.map((item) => {
      item.Suitability && setSuitabilityComments(item.Suitability)
    })
  }


  const suitabilityHandleClose = () => {
    setSuitabilityRemarks('')
    setSuitabilityOpen(false);
  }

  const history = useHistory();
  const theme = createTheme({
    typography: {
      fontFamily: "gilroyMedium",
    },
    palette: {
      secondary: {
        main: "#379592",
      },
    },

    Muidisabled: {
      styleOverrides: {
        backgroundColor: "purple",
      },
    },
  });
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },

    // hide last border

    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      // backgroundColor: theme.palette.common.black,

      backgroundColor: "rgba(55, 149, 146, 1)",

      color: theme.palette.common.white,
    },

    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const handleFundingChange = (event) => {
    setFundingStatusOptions(event.target.value);
  };

  const handleSuitabilityChange = (event) => {
    setSuitabilityStatusOptions(event.target.value);

  };

  const onChangeSuitabilityRemarks = (event) => {
    setSuitabilityRemarks(event.target.value);

  };

  const onChangeFundingRemarks = (event) => {
    setFundingRemarks(event.target.value);

  };



  const shoot = (type) => {
    let stat = type
    console.log(type)

    Swal.fire({
      iconHtml: `<img src="${question}" width="100px" height="100px"  >`,
      text: "Are you sure you want to Submit?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      confirmButtonColor: "#379592",
      cancelButtonText: "No",
      cancelButtonColor: "#909090",
      customClass: {
        container: "my-swal",
        icon: 'no-border'

        //    confirmButton: 'your-confirm-button-class',
      },
    }).then((result) => {

      if (result.isConfirmed) {
        (async () => {
          // setLoading(true)
          let statusData = {}

          let suitabilityStatus = {
            applicationId: applicantId,
            childId: chId,
            campId: campId,
            suitabilityStatus: suitabilityStatusOptions,
            suitabilityRemarks: suitabilityRemarks,
            userId: localStorage.getItem('userId'),
          }
          let fundingStatus = {
            applicationId: applicantId,
            childId: chId,
            campId: campId,
            fundingStatus: fundingStatusOptions,
            applicationRemarks: fundingRemarks,
            userId: localStorage.getItem('userId'),
          }


          if (stat === 'suitability') {
            statusData = suitabilityStatus
            console.log('if loop is working', statusData)
          }
          else if (stat === 'funding') {
            statusData = fundingStatus
          }

          let apiInfo = {
            // url: "https://cncdevapi.natsoft.io/api/camps/updatesuitabilitystatus",
            url: `${SERVER_URL}${UPDATESUITABILITY_STATUS}`,
            method: "POST",
            postData: statusData,
            headers: {
              "Content-Type": "application/json",
              tokenheaderkey: localStorage.getItem("token"),
            },
          }

          let output = await apiService(apiInfo);
          console.log('looping out ', output.data.responseData[0][0].Code)
          // setLoading(false)
          if (output?.data?.responseData[0][0].Code === "0") {

            let statusOutput = output.data.responseData[0][0].Desc
            Swal.fire({
              iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
              text: statusOutput,
              confirmButtonText: 'OK',
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
               
              },
            }).then((result2) => {
              if (result2.value) {
                history.push(`/ChangeSuitability/${campId}/${applicantId}`)
                setIsConditionMet((prev) => !prev);
                console.log(isConditionMet)
                setinvoiceOpen(false)
                setSuitabilityOpen(false)
                setSuitabilityRemarks('')
                setFundingRemarks('')

              }
            });
          }
        })()

      }

    });
  };


  React.useEffect(() => {
    (
      async () => {
        setLoading(true)
        let dataSent = {
          userId: localStorage.getItem('userId'),
          campId: campId,
          applicationId: applicantId,
        }

        let apiInfo = {
          // url: "https://cncdevapi.natsoft.io/api/camps/getcampapplicants",
          url: `${SERVER_URL}${GETCAMP_APPLICANTS}`,
          method: "POST",
          postData: dataSent,
          headers: {
            "Content-Type": "application/json",
            tokenheaderkey: localStorage.getItem("token"),
          },

        }

        let output = await apiService2(apiInfo)
        console.log(output.data.responseData[0])
        let campApplicantList = output.data.responseData[0]
        setLoading(false)
        setCampApplicantFundingList(campApplicantList)
        setChildId(campApplicantList[0].childId)
        console.log(JSON.parse(campApplicantList[0].ndiInfo))
        setNDISInfo(JSON.parse(campApplicantList[0].ndiInfo))
        setInvoiceInfo(JSON.parse(campApplicantList[0].invoiceInfo))
        localStorage.setItem('childId', (campApplicantList[0].childId))
        localStorage.setItem('ndisStatus', (campApplicantList[0].serviceAgreementStatus))
        localStorage.setItem('invoiceStatus', (campApplicantList[0].invoiceStatus))
        let nd = localStorage.getItem('ndisStatus')
        let inv = localStorage.getItem('invoiceStatus')
        console.log(nd,inv,'hereeeeeeee')
      
          if( nd === 'NA' || nd === 'Draft'){
            console.log('coming')
           setInvoiceStat(true)
           setDownloadStat(true)
          }
          else if((nd === 'Provider-Submitted') || ( nd === 'Parent-Submitted')){
            setNdiStat(true)
      
            if(inv === 'Submitted'){
              console.log('shouldddddddddd')
              setInvoiceStat(true)
            }
            else{
              console.log('notttttttt')
              setInvoiceStat(false)
            }
            
          }
        
      }
    )()
  }

    , [isConditionMet])


  let chId = localStorage.getItem("childId")
  
  const handleConfirmCampPlace = async () => {

    let statusData = {
      applicationId: applicantId,
      childId: chId,
      campId: campId,
      applicationStatus: 'Confirmed',
      userId: localStorage.getItem('userId'),
    }
    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/updatesuitabilitystatus",
      url: `${SERVER_URL}${UPDATESUITABILITY_STATUS}`,
      method: "POST",
      postData: statusData,
      headers: {
        "Content-Type": "application/json",
        tokenheaderkey: localStorage.getItem("token"),
      },
    }

    let output = await apiService(apiInfo);
    console.log('looping out ', output.data.responseData[0][0].Code)
    let statusOutput = ''
    if (output?.data?.responseData[0][0].Code === "0") {
      statusOutput = output.data.responseData[0];
      statusOutput = output.data.responseData[0][0].Desc
      Swal.fire({
        iconHtml: `<img src="${GreenCheck2}" width="100px" height="100px">`,
        // icon:'success',
        text: statusOutput,
        confirmButtonText: 'OK',
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",

        },
      }).then((result2) => {
        if (result2.value) {
          
          setIsConditionMet((prev) => !prev);
          
        }
      });
    }
  };
  // React.useEffect (() => {
  //  let nd = localStorage.getItem('ndisStatus')
  // let inv = localStorage.getItem('invoiceStatus')
  // console.log(nd,inv,'hereeeeeeee')

  //   if( nd === 'NA' || nd === 'Draft'){
  //     console.log('coming')
  //    setInvoiceStat(true)
  //   }
  //   else if((nd === 'Provider-Submitted') || ( nd === 'Parent-Submitted')){
  //     setNdiStat(true)

  //     if(inv === 'Submitted'){
  //       console.log('shouldddddddddd')
  //       setInvoiceStat(true)
  //     }
  //     else{
  //       console.log('notttttttt')
  //       setInvoiceStat(false)
  //     }
      
  //   }
  
      
  // },[])

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box>
        <Grid container direction="flex" justifyContent="space-between">
          <Stack direction="row" spacing={1}>
            <Link to={`/ApplicantsReview/${campId}`} className="link2">
              <Box
                alignSelf="center"
                component="img"
                sx={{ height: "0.75rem", width: "0.65rem", cursor: "pointer" }}
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>

            <Box alignSelf="center">{campApplicantFundingList && campApplicantFundingList[0].applicantName} Application Status </Box>
          </Stack>
        </Grid>
        {loading === true ? (
          <Grid container justifyContent='center' alignItems='center' >
            <CircularProgress />
          </Grid >
        ) : (
          <TableContainer component={Paper} sx={{ my: "2rem" }}>
            <Table sx={{ minWidth: 450 }} aria-label="customized table">
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="center">Funding</StyledTableCell>
                  <StyledTableCell align="center">Suitability</StyledTableCell>
                  <StyledTableCell align="center">Application</StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {campApplicantFundingList && campApplicantFundingList.map((list, index) => (
                  <TableRow key={index}
                    sx={{
                      "& .MuiTableCell-root": { py: "0.45rem" },
                    }}
                  >
                    <StyledTableCell >
                      <Stack direction="row" justifyContent="center" spacing={1}>
                        <Box alignSelf="center">{list.Funding}</Box>
                        <Link className="link2" to={`/ChangeSuitability/${list.campId}/${list.applicationId}`}>
                          <Chip

                            label="View"
                            variant="outlined"
                            size="small"
                            className="medium"
                            mx="0.75rem"
                            sx={{
                              color: "#707070",
                              backgroundColor: "white",
                              fontSize: "0.75rem",
                              px: "0.5rem",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={invoiceHandleOpen}
                          />
                        </Link>
                      </Stack>

                    </StyledTableCell>
                    <StyledTableCell align='center'>
                      <Stack direction="row" justifyContent="center" alignItems='flex-start' spacing={1} >
                        <Box >
                          {list.suitability ? list.suitability : 'Unknown'}
                        </Box>
                        <Link className="link2" to={`/ChangeSuitability/${list.campId}/${list.applicationId}`} >
                          <Chip
                            label='View'
                            variant="outlined"
                            size="small"
                            className="medium"
                            mx="0.75rem"
                            sx={{
                              color: "#707070",
                              backgroundColor: "white",
                              fontSize: "0.75rem",
                              px: "0.5rem",
                              height: "25px",
                              cursor: "pointer",
                            }}
                            onClick={suitabilityHandleOpen}
                          />
                        </Link>
                      </Stack>

                    </StyledTableCell>
                    <StyledTableCell component="th" scope="row" align="center">
                      <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                          {list.status}
                        </Box>
                        <Link to={`/ReviewApplication/${campId}/${applicantId}`} className="link2">
                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#379592",
                              color: "white",
                              textTransform: "none",
                              "&:hover": { background: "#379592", color: "white" },
                              width: "200px",
                              mx: "0.5rem",
                              my: "0.5rem",
                            }}
                            className="gilroy"
                          >
                            Review Application
                          </Button>
                        </Link>

                        <Link to={`/NotificationLog/${campId}/${applicantId}`} className="link2">
                          <Button
                            variant="outlined"
                            sx={{
                              color: "#379592",
                              textTransform: "none",
                              "&:hover": { color: "#379592" },
                              width: "200px",
                              mx: "0.5rem",
                              my: "0.5rem",
                            }}
                            className="gilroy"
                            onClick={ localStorage.setItem("previousPage", 'ChangeSuitability')}
                          >
                            Notifications
                          </Button>
                        </Link>
                        {list.welcomePackAccessed === 'Yes' 
                        ? (
                          // <Chip label="Welcome Pack Accessed" color="success" />
                          <Box sx={{color:'#78C091',fontSize:'14px'}} >Welcome Pack Accessed</Box>
                        ) : 
                        (
                          <Box sx={{color:'#F21010',fontSize:'14px'}}>Welcome Pack Not Accessed</Box>
                          // <Chip label="Welcome Pack Not Accessed" color="error"  />
                        )}
                         
                        
                      </Stack>
                    </StyledTableCell>
                  </TableRow>
                ))}
              </TableBody>
              <TableRow>
                <StyledTableCell>
                  <Stack>
                    <Card
                      className="ndis-card"
                      sx={{
                        backgroundColor: "#F8F8F8",
                        color: "#707070",
                        my: "1rem",
                        border: 1,

                        borderRadius: "0.75rem",
                      }}
                    >


                      <CardContent>
                        <Grid container display='flex' justifyContent='space-between'>
                          <Grid item >
                            <Box sx={{ width: '10px', color: 'black', textDecoration: 'underline' }}> NDIS Agreement</Box>
                          </Grid>
                          <Grid item>
                            <Stack direction='row' spacing={1}>
                              
                              <Button
                                variant="outlined"
                                disabled={ndiStat === true}
                                // disabled={campApplicantFundingList && campApplicantFundingList[0].serviceAgreementStatus === 'Provider-Submitted'}
                                sx={{
                                  
                                  mx: "0.5rem",
                                  bgcolor: "white",
                                  color: "#379592",
                                  textTransform: "none",
                                  borderColor: '#379592',
                                  borderRadius: '20px',
                                  "&:hover": { background: "white", color: '#379592', borderColor: '#379592', borderRadius: '20px' },
                                  width: '90px',
                                  py: '0rem'
                                }}
                                className="gilroy"
                                onClick={() => { history.push(`/NDISAgreement/${campId}/${applicantId}`) }}
                              >Generate
                              </Button>
                              {console.log(localStorage.getItem('ndisStatus'))}
                              <Button
                                variant="outlined"
                                // disabled={ndiStat === false}
                                // disabled={localStorage.getItem('ndisStatus') !== 'Parent-Submitted'}
                                // disabled={invoiceStat === true}
                                disabled={downloadStat === true}
                                sx={{
                                  // px: "1rem",
                                  mx: "0.5rem",
                                  bgcolor: "white",
                                  color: "#379592",
                                  textTransform: "none",
                                  borderColor: '#379592',
                                  borderRadius: '20px',
                                  "&:hover": { background: "white", color: '#379592', borderColor: '#379592', borderRadius: '20px' },
                                  width: '90px',
                                  py: '0rem'
                                }}
                                className="gilroy"
                              onClick={()=>{history.push(`/DownloadNDIS/${campId}/${applicantId}`)}}
                              >View
                              </Button>
                            </Stack>

                          </Grid>
                        </Grid>

                        <List >
                          
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Sent by :</Grid>
                              <Grid item md={7} className="medium">{ndisInfo?.sentBy}</Grid>
                            </Grid>
                          </ListItem>
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Sent Date : </Grid>
                              <Grid item md={7} className="medium">{ndisInfo?.sentDate}</Grid>
                            </Grid>
                          </ListItem>
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Signed by : </Grid>
                              <Grid item md={7} className="medium">{ndisInfo?.signedBy}</Grid>
                            </Grid>
                          </ListItem>
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Signed Date : </Grid>
                              <Grid item md={7} className="medium">{ndisInfo?.signedDate}</Grid>
                            </Grid>
                          </ListItem>
                          {/* ))} */}
                        </List>
                      </CardContent>
                    </Card>
                  </Stack>
                  <Stack>
                    <Card

                      sx={{
                        backgroundColor: "#F8F8F8",
                        color: "#707070",
                        my: "1rem",
                        border: 1,

                        borderRadius: "0.75rem",
                      }}
                    >


                      <CardContent>
                        <Grid container display='flex' justifyContent='space-between'>
                          <Grid item >
                            <Box sx={{ width: '10px', color: 'black', textDecoration: 'underline' }}> Invoice</Box>
                          </Grid>
                          <Grid item>
                            <Stack direction='row' spacing={1}>
                             
                              <Button
                                variant="outlined"
                                disabled={invoiceStat === true}
                                
                                // disabled={campApplicantFundingList && campApplicantFundingList[0].invoiceStatus === 'Submitted' }
                                sx={{
                                  
                                  mx: "0.5rem",
                                  bgcolor: "white",
                                  color: "#379592",
                                  textTransform: "none",
                                  borderColor: '#379592',
                                  borderRadius: '20px',
                                  "&:hover": { background: "white", color: '#379592', borderColor: '#379592', borderRadius: '20px' },
                                  width: '90px',
                                  py: '0rem'
                                }}
                                className="gilroy"
                                onClick={() => {history.push(`/GenerateInvoice/${campId}/${applicantId}`)  }}
                              >Generate
                              </Button>

                              <Button
                                variant="outlined"
                                
                                disabled={localStorage.getItem('invoiceStatus') !== 'Submitted'}
                                
                                sx={{
                                  // px: "1rem",
                                  mx: "0.5rem",
                                  bgcolor: "white",
                                  color: "#379592",
                                  textTransform: "none",
                                  borderColor: '#379592',
                                  borderRadius: '20px',
                                  "&:hover": { background: "white", color: '#379592', borderColor: '#379592', borderRadius: '20px' },
                                  width: '90px',
                                  py: '0rem'
                                }}
                                className="gilroy"
                              onClick={()=>{history.push(`/DownloadInvoice/${campId}/${applicantId}`)}}
                              >View
                              </Button>
                            </Stack>

                          </Grid>
                        </Grid>

                        <List >
                          {/* {listArray.map((item, index) => ( */}
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Sent by : </Grid>
                              <Grid item md={7} className="medium">{invoiceInfo?.sentBy}</Grid>
                            </Grid>
                          </ListItem>
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Sent Date : </Grid>
                              <Grid item md={7} className="medium">{invoiceInfo?.sentDate}</Grid>
                            </Grid>
                          </ListItem>
                          {/* <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Paid by : </Grid>
                              <Grid item md={7} className="medium">{invoiceInfo?.paidBy}</Grid>
                            </Grid>
                          </ListItem> */}
                          <ListItem sx={{ color: 'black' }} >
                            <Grid container display='flex' justifyContent='space-between'>
                              <Grid item md={5}>Paid Date : </Grid>
                              <Grid item md={7} className="medium">{invoiceInfo?.paidDate}</Grid>
                            </Grid>
                          </ListItem>
                          {/* ))} */}
                        </List>

                      </CardContent>


                    </Card>
                  </Stack>
                </StyledTableCell>

              </TableRow>
            </Table>
          </TableContainer>
        )}


        <Grid
          container
          justifyContent="flex-end"
          direction="row"
          alignContent="flex-end"
        >
          {/* <Link className="link2"> */}

          <Button
            disabled={campApplicantFundingList && campApplicantFundingList[0].status !== 'Waitlisted'}
            variant="contained"
            sx={{
              bgcolor: "#4C9F70",
              color: "white",
              textTransform: "none",
              "&:hover": { background: "#4C9F70", color: "white" },
              width: "180px",
              mx: "0.5rem",
              my: "0.5rem",
            }}
            className="gilroy"
            onClick={() => handleConfirmCampPlace()}
          >
            Confirm Camp Place
          </Button>
          {/* </Link> */}
          <Link className='link2' to={`/RecentFiles/${campId}/${applicantId}`}>
            <Button
              variant="outlined"
              sx={{
                color: "#379592",
                textTransform: "none",
                "&:hover": { color: "#379592" },
                width: "180px",
                mx: "0.5rem",
                my: "0.5rem",
              }}
              className="gilroy"
            >
              Files
            </Button>
          </Link>
        </Grid>
        <Grid>
          <Modal
            open={invoiceOpen}
            onClose={invoiceHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Box sx={{maxHeight: "500px", overflow: "auto", overflowX: "hidden"}}>
              <Grid
                container
                justifyContent="flex-Start"
                direction="row"
                alignContent="flex-Start"
              >
                {/* <Link to="/serviceAgreement" className="link2">
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#718F94",
                      color: "white",
                      textTransform: "none",
                      borderColor: "#707070",
                      "&:hover": { background: "#718F94", color: "white" },
                      width: "95%",
                      mx: "0.5rem",
                      my: "0.5rem",
                    }}
                    className="gilroy"
                  >
                    Send Agreement
                  </Button>
                </Link>

                <Link to="/serviceAgreement" className="link2">
                  <Button
                    variant="contained"
                    sx={{
                      bgcolor: "#718F94",
                      color: "white",
                      borderColor: "#707070",
                      textTransform: "none",
                      "&:hover": { background: "#718F94", color: "white" },
                      width: "95%",
                      // height:"25%",
                      mx: "0.5rem",
                      my: "0.5rem",
                    }}
                    className="gilroy"
                  >
                    Send Invoice
                  </Button>
                </Link> */}

                <FormControl sx={{ m: 1, minWidth: 215 }} size="small">
                  <InputLabel id="demo-select-small">
                    Choose Funding Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={fundingStatusOptions}
                    label="Choose Funding Status"
                    onChange={handleFundingChange}

                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value='Agreement Sent'>Agreement Sent</MenuItem>
                    <MenuItem value='Agreement Signed'>Agreement Signed</MenuItem>
                    <MenuItem value='Invoice Sent'>Invoice Sent</MenuItem>
                    <MenuItem value='Funding Confirmed'>Funding Confirmed</MenuItem>
                    <MenuItem value='Funding Declined'>Funding Declined</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      mt: "0.75rem",
                      width: "100%",
                      height: "20%",
                      mb: "1rem",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={3}
                    label="Enter Remarks..."
                    value={fundingRemarks}
                    onChange={onChangeFundingRemarks}

                  />
                </Box>
              </Grid>
              <Box className="gilroy">Previous Comments</Box>
              <Grid>
                <TableContainer
                  component={Paper}
                  sx={{ my: "2rem", mt: "0.5rem" }}
                >
                  <Table sx={{ minWidth: 450 }} aria-label="customized table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>

                        <StyledTableCell align="center">
                          Camp Manager
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Funding Status
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Remarks
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody >
                      {fundingComments && fundingComments.map((list, index) => (
                        <TableRow key={index} sx={{
                          "& .MuiTableCell-root": { py: "0.45rem" },
                        }}>
                          <StyledTableCell component="th" scope="row" align="center" >
                            {list.Date}
                          </StyledTableCell>
                          <StyledTableCell align="center">
                            {list.CampManager}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center" >
                            {list.Status}
                          </StyledTableCell>
                          <StyledTableCell component="th" scope="row" align="center" >
                            {list.Remarks}
                          </StyledTableCell>
                        </TableRow>
                      ))
                      }
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#909090",
                      textTransform: "none",
                      "&:hover": { background: "#909090" },
                    }}
                    className="gilroy"
                    onClick={invoiceHandleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="gilroy"
                    onClick={() => { shoot('funding') }}

                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#379592",
                      "&:hover": { background: "#379592" },
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
              </Box>
             
            </Box>
          </Modal>
        </Grid>
        <Grid>
          <Modal
            open={suitabilityOpen}
            onClose={suitabilityHandleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={suitabilityStyle}>
            <Box sx={{maxHeight: "500px", overflow: "auto", overflowX: "hidden"}}>
            <Grid
                container
                justifyContent="flex-Start"
                direction="row"
                alignContent="flex-Start"

              >
                <FormControl sx={{ m: 1, minWidth: 230 }} size="small">
                  <InputLabel id="demo-select-small">
                    Choose Suitability Status
                  </InputLabel>
                  <Select
                    labelId="demo-select-small"
                    id="demo-select-small"
                    value={suitabilityStatusOptions}
                    label="Choose Suitability Status"
                    onChange={handleSuitabilityChange}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {/* <MenuItem value='Pending Suitability-Pre screen'>Pending Suitability-Pre screen</MenuItem> */}
                    <MenuItem value='Pending Suitability-Other'>Pending Suitability-Other</MenuItem>
                    <MenuItem value='Suitability Confirmed'>Suitability Confirmed</MenuItem>
                    <MenuItem value='Suitability Declined'>Suitability Declined</MenuItem>

                  </Select>
                </FormControl>
              </Grid>
              <Grid>
                <Box
                  component="form"
                  sx={{
                    "& .MuiTextField-root": {
                      mt: "0.5rem",
                      width: "100%",
                      height: "20%",
                      mb: "0.5rem",
                    },
                  }}
                  noValidate
                  autoComplete="off"
                >
                  <TextField
                    id="outlined-multiline-static"
                    multiline
                    rows={2}
                    label="Enter Remarks..."
                    onChange={onChangeSuitabilityRemarks}
                    value={suitabilityRemarks}

                  />
                </Box>
              </Grid>
              <Box className="gilroy">Previous Comments</Box>
              {/* <Box>{suitabilityRemarks}</Box> */}
              <Grid>
                <TableContainer
                  component={Paper}
                  sx={{ my: "2rem", mt: "0.5rem", maxHeight: '200' }}

                >
                  <Table sx={{ minWidth: 450 }} stickyHeader aria-label="sticky table">
                    <TableHead>
                      <StyledTableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>

                        <StyledTableCell align="center">
                          Camp Manager
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Suitability Status
                        </StyledTableCell>

                        <StyledTableCell align="center">
                          Remarks
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableHead>

                    <TableBody >
                      {suitabilityComments && suitabilityComments.map((item, index) => (
                        <TableRow key={index}
                          sx={{
                            "& .MuiTableCell-root": { py: "0.45rem" },
                          }}
                        >
                          <StyledTableCell component="th" scope="row">
                            <Stack
                              direction="row"
                              justifyContent="center"
                              spacing={1}
                            >
                              <Box alignSelf="center">{item.Date}</Box>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Stack
                              direction="row"
                              justifyContent="center"
                              spacing={1}
                            >
                              <Box alignSelf="center">{item.CampManager}</Box>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell
                            component="th"
                            scope="row"
                            align="center"
                          >
                            <Stack
                              direction="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box my="0.5rem" sx={{ alignItems: "flex-end" }}>
                                {item.Status}
                              </Box>
                            </Stack>
                          </StyledTableCell>

                          <StyledTableCell align="center">
                            <Stack
                              direction="row"
                              justifyContent="center"
                              spacing={1}
                            >
                              <Box alignSelf="center">
                                {item.Remarks}
                              </Box>
                            </Stack>
                          </StyledTableCell>
                        </TableRow>
                      ))}



                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
              <Grid container sx={{ justifyContent: "center" }}>
                <Grid item>
                  <Button
                    variant="contained"
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#909090",
                      textTransform: "none",
                      "&:hover": { background: "#909090" },
                    }}
                    className="gilroy"
                    onClick={suitabilityHandleClose}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className="gilroy"
                    // onClick={()=>{updateSuitabilityStatus()}}
                    onClick={() => { shoot('suitability') }}
                    sx={{
                      px: "2rem",
                      mx: "0.5rem",
                      bgcolor: "#379592",
                      "&:hover": { background: "#379592" },
                      textTransform: "none",
                    }}
                  >
                    Submit
                  </Button>
                </Grid>
              </Grid>
            </Box>
              
            </Box>
          </Modal>
        </Grid>
      </Box>


    </ThemeProvider>
  );
}
