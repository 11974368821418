import React from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Modal from '@mui/material/Modal';
import Stack from "@mui/material/Stack";
import "../index.css";
import Grid from "@mui/material/Grid";
import Card from '@mui/material/Card';
import { alpha } from "@mui/material/styles";
import TextField from "@mui/material/TextField";


import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Button from '@mui/material/Button';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import BackgroundImage from "../Assets/Icons/Icons/Background-image.png"
import SearchIcon from '@mui/icons-material/Search';
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const SaveButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#909090',
  '&:hover': {
    backgroundColor: '#909090',
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform:'none',
  // color: theme.palette.getContrastText(purple[500]),
  backgroundColor: '#379592',
  '&:hover': {
    backgroundColor: '#379592',
  },
}));

const UploadButton = styled(Button)(({ theme }) => ({
    textTransform:'none',
    // color: theme.palette.getContrastText(purple[500]),
    backgroundColor: 'white',
    color:'#379592',
    '&:hover': {
      backgroundColor: 'white',
      borderColor:'#379592'
    },
  }));
  

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  // border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function createData(name, calories, fat, carbs, protein,parameter) {
  return { name, calories, fat, carbs, protein,parameter };
}
const rowCount =[1,2,3,4]
const rows = [
  createData(" "," ", "", "", ""),
  createData(
    " ",
    " ",
    "",
    "",
    ""
  ),
  createData(
    " ",
    "",
    "",
    "",
    ""
  ),
  
];

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});


export default function UploadingPhoto() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div>
        <Stack direction='row'>
       
        <Box sx={{fontSize:'1.5rem'}}>Camp Withnell</Box>
        </Stack>
        
       <Grid mx='1.5rem' my='1rem' sx={{color:'#AFAFAF'}}>
        <Box sx={{fontSize:'14px',my:'0.5rem'}}>Camp Name</Box>
        <Box sx={{fontSize:'14px' ,my:'0.5rem'}}>Location</Box>
        <Box sx={{fontSize:'14px',my:'0.5rem'}}>Start Date & Time</Box>
        <Box sx={{fontSize:'14px',my:'0.5rem'}} >Registration open date & time</Box>
        <Box sx={{fontSize:'14px',my:'0.5rem'}}>Registration open date & time</Box>
        <Box sx={{fontSize:'14px',my:'0.5rem'}}>Age Group</Box>
       </Grid>

        <Grid mt="2rem" display='flex' >
          <TableContainer component={Paper}>
      <Table sx={{ minWidth: 450 }} aria-label="customized table" my='1rem'>

        <TableHead>
           
          <TableRow>
            <StyledTableCell align='center'>Group</StyledTableCell>
            <StyledTableCell align="center">Camp Manager</StyledTableCell>
            <StyledTableCell align="center">Support Staff</StyledTableCell>
            <StyledTableCell align="center">Therapist</StyledTableCell>
            <StyledTableCell align="center">Medication Manager</StyledTableCell>
            <StyledTableCell align="center">Participants</StyledTableCell>
            
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            
           
            <StyledTableRow key={row.name}>
              <StyledTableCell component="th" scope="row" align="center" >
              {row.name}
              </StyledTableCell>
                  <StyledTableCell align="center">
                {row.calories}
                </StyledTableCell>
                <StyledTableCell component="th" scope="row" align="center" >
              {row.fat}
              </StyledTableCell><StyledTableCell component="th" scope="row" align="center" >
              {row.carbs}
              </StyledTableCell><StyledTableCell component="th" scope="row" align="center" >
              {row.protein}
              </StyledTableCell>
              <StyledTableCell align="center">
                {row.parameter}
                </StyledTableCell>
            
            </StyledTableRow>
            // </>

            
                
          ))}     
        </TableBody>    
      </Table>    
    </TableContainer>
    {/* <Box>button</Box> */}
        </Grid>
         <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { mt:'3rem', width: '40%',mb:'1rem' },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={4}
        //   defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
        />
    </Box>
    <Box>
            <TextField
              id="standard-basic"
              label="Fee"
              variant="standard"
              className="txt-grey"
            //   defaultValue='$ 50'
            ></TextField>
          </Box>
          <Grid sx={{my:'2rem'}}>
          <Box sx={{ color:'#AFAFAF',mb:'1rem'}}>
            File Attachment
        </Box>
        <Stack direction='row'spacing={2} >
            {rowCount.map((ele,index)=>(
                <Box
              component="img"
              sx={{ height: "10%", width: "8%" }}
              alt="Logo"
              src={BackgroundImage}
            ></Box>
            ))}
        
        </Stack>
          </Grid>
          <Grid container display='flex' justifyContent='space-between' mr='1rem'>
<Box>Gallery of Camp Withnell</Box>
<UploadButton variant="outlined" sx={{mr:'2rem'}}>Upload Photo / Video</UploadButton>
          </Grid>  
         {rowCount.map(()=>(
            <Grid container display='flex' sx={{my:'2rem'}}>
          <Box
              component="img"
              sx={{  width: "20%" }}
              alt="Logo"
              src={BackgroundImage}
            ></Box>
            <Box alignSelf='center' sx={{width:'60%',ml:'2rem'}}>
            <TextField  sx={{borderColor:'#707070',width:'100%'}}
          id="outlined-multiline-static"
          label="Description"
          multiline
          rows={3}
        
        //   defaultValue="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Duis at scelerisque sapien. Nullam ut tempus elit, sed volutpat diam. In tortor lacus, commodo ut tempor eget."
        />
            </Box>
            
          </Grid>
         ))}
          
      <Grid container justifyContent='flex-end' my='2rem'>
<Grid item>
<SaveButton variant="contained" sx={{px:'2rem',mx:'1rem'}} className='gilroy'>Cancel</SaveButton>
</Grid>
<Grid item>
  <PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Save</PublishButton>
  </Grid>
      </Grid>
      <div>
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
         

          <Box>
            <Box textAlign='center' my='1rem'>Camp Manager</Box>
            <Paper
      component="form"
      sx={{border:1,mx:'1rem', display: 'flex', alignItems: 'center', width: 300, justifyContent:'center'}}
    >
      
      <InputBase
        sx={{ ml: 2, flex: 1 }}
        textAlign='center'
        placeholder="Search"
        inputProps={{ 'aria-label': 'search google maps' }}
      />
      <IconButton type="button" sx={{ py: '10px',px:'2rem' }} aria-label="search">
        <SearchIcon />
      </IconButton>
    
      
      
    </Paper>
    <Grid container display='flex' mx='1rem' my='1rem' >
    <AccountCircleIcon px='1rem'/>
    <AccountCircleIcon px='1rem' />
    <AccountCircleIcon  px='1rem'/>
    </Grid>
    
      <Divider orientation="horizontal" color='#379592'/>
      <Box sx={{my:'1rem',mx:'1rem'}}>
        <Stack direction='row' spacing={1} my='0.75rem'>
          <AccountCircleIcon/>
          <Box sx={{color:'#6C6C6C'}}>Name</Box>
        </Stack>
        <Stack direction='row' spacing={1} my='0.75rem'>
          <AccountCircleIcon/>
          <Box sx={{color:'#6C6C6C'}}>Name</Box>
        </Stack>
        <Stack direction='row' spacing={1} my='0.75rem'>
          <AccountCircleIcon/>
          <Box sx={{color:'#6C6C6C'}}>Name</Box>
        </Stack>
      </Box>
      <Box textAlign='center'>
      <PublishButton variant="contained" className='gilroy' sx={{px:'2rem',mx:'1rem'}}>Save</PublishButton>
      </Box>
          </Box>
        </Box>
      </Modal>
    </div>
      </div>
    </ThemeProvider>
  );
}
