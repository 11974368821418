import React from "react";
import { useState, useRef } from "react";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import List from "@material-ui/core/List";

import { makeStyles } from "@material-ui/core/styles";
import Typography from "@mui/material/Typography";

import Stack from "@mui/material/Stack";
import { alpha } from "@mui/material/styles";
import Swal from "sweetalert2";
import editIcon from "../Assets/Icons/Icons/edit.png";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import ArrowBack from "../Assets/Icons/Icons/ArrowBack.png";
import Searchdvance from "../Assets/Icons/Icons/searchadvanced.png";
import "../index.css";
import Grid from "@mui/material/Grid";
import Add from "../Assets/Icons/Icons/add.png";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import Button from "@mui/material/Button";

import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import {
  apiService,
  apiService2,
  apiServiceImage,
} from "../Services/apiService";
import { useHistory, Link } from "react-router-dom";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Modal from "@mui/material/Modal";
import Minus from "../Assets/Icons/Icons/Minus.png";
import GreenCheck from "../Assets/Icons/Icons/GreenCheck.png";

import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import CrossGreen from "../Assets/Icons/Icons/CrossGreen.png";
import User from "../Assets/Icons/Icons/User.png";
import moment from "moment";
import PdfIcon from "../Assets/Icons/Icons/PdfIcon.png";
import InputAdornment from "@mui/material/InputAdornment";

import {
  SERVER_URL,
  GETLIST_STAFF,
  FILE_UPLOAD,
  CREATE_CAMP,
} from "../Services/apiEndpints";
import ThumbnailImage from "../Assets/Icons/Icons/ThumbnailImage.png"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const min = 0;
const max = 10;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };
const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  // backgroundColor: '#00000003',
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
    // backgroundColor:'pink',
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

// function getStyles(name, personName, theme) {
//   return {
//     fontWeight:
//       personName.indexOf(name) === -1
//         ? theme.typography.fontWeightRegular
//         : theme.typography.fontWeightMedium,
//   };
// }

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "rgba(55, 149, 146, 1)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "#909090",
  // color:'purple',

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "50ch",
      "&:focus": {
        width: "70ch",
      },
    },
  },
}));

const PublishButton = styled(Button)(({ theme }) => ({
  textTransform: "none",
  backgroundColor: "#379592",
  "&:hover": {
    backgroundColor: "#379592",
  },
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        "@font-face": {
          fontFamily: "gilroyMedium",
          src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
        },
      },
    },
  },
});
let dum = [1, 2, 3];
const campData = {
  campName: "",
  location: "",
  campStartdate: "",
  campRegStartdate: "",
  campRegEnddate: "",
  campType: "",
  campMinAge: "",
  campMaxAge: "",
  maxSpots: "",
  description: "",
  campFeeParticipants: "",
  groupInfo: "",
  campEnddate: "",
  campFeeSS: "",
  campHoursDatewise: "",
};

export default function CreateCamp() {
  // const [selectedDate, setSelectedDate] = useState(null);

  const [filesPath, setFilePath] = useState();
  const [filesPathTwo, setFilePathTwo] = useState();
  const inputRef = useRef(null);
  const [imgsSrc, setImgsSrc] = useState([]);
  const [attachmentsArr, setAtachmentsArr] = useState([]);
  const [docPath, setDocPath] = useState([]);
  const hiddenFileInput = React.useRef(null);
  const [file, setFile] = useState();
  const [thumbnail, setThumbnail] = useState();
  const history = useHistory();
  const [groupError, setGroupError] = React.useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [search, setSearch] = useState("");
  const [rowId, setRowId] = useState("");
  const [seachName, setSearchName] = useState(null);
  const [userId, setUserId] = useState(null);
  const [formData, setFormData] = React.useState(campData);
  const [list, setList] = React.useState([]);
  const [thumbStatus, setThumbStatus] = React.useState(
    "Thumbnail not uploaded"
  );
  const [tableformData, setTableFormData] = useState([]);
  const [rows, setRows] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [today, setToday] = React.useState(null);
  const [regDate, setRegDate] = React.useState(null);
  const [latestDate, setLatestDate] = React.useState(null);
  const [attachments, setAttachments] = useState([]);
  const [attachmentsStatus, setAttachmentsStatus] = useState(
    "Attachments not uploaded"
  );
  const [thumbnailPath, setThumbnailPath] = useState(null);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [thumbnailDocPath, setThumbnailthumbnailDocPath] = useState(null);
  const [errorThumbnail, seterrorThumbnail] = useState(false);
  const [errorFile, setErrorFile] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [dateState, setDateState] = React.useState(false);
  const [errMsg, setErrMsg] = React.useState(null);
  const hiddenFileInputThum = React.useRef(null);

  // const q = [{ start: '', end: '' }]
  // const [tableDates, setTableDates] = React.useState(q)
  const [tableStart, setTableStart] = React.useState("");
  const [tableEnd, setTableEnd] = React.useState("");
  const [finalDateArray, setFinalDateArray] = React.useState([]);
  const [hoursTable, setHoursTable] = React.useState([]);
  const [campErr, setCampErr] = React.useState("");

  const [regStart, setRegStart] = React.useState("");
  const [regEnd, setRegEnd] = React.useState("");
  const [regErr, setRegErr] = React.useState("");
  const [spotErr, setSpotErr] = React.useState("");
  const [campHoursErr, setCampHoursErr] = React.useState(false);
  const [value, setValue] = React.useState();
  const [videoRow, setVideoRow] = React.useState([]);

  const useStyles = makeStyles({
    root: {
      margin: "10px",
      boxShadow: "0px 0px 10px #00000003",
      borderRadius: "20px",
    },
    mainheader: {
      letterSpacing: "0px",
      color: "#6c6c6c",
      textAlign: "center",
      fontSize: "18px",
      marginBottom: "2rem",
    },
    subheader: {
      letterSpacing: "0px",
      color: "#3B3B3B",
      textAlign: "left",
      fontSize: "16px",
      paddingTop: "16px",
    },
    listStyle: {
      height: "300px",
      overflowY: "scroll",
    },
  });
  const classes = useStyles();

  const [personName, setPersonName] = React.useState([]);

  const [thumbnailUrl, setThumbnailUrl] = React.useState(
    "https://picsum.photos/300/200"
  );

  const uploadFile = (e) => {
    document.getElementById("selectFile").click();
  };

  async function imgSelectHandler(e) {
    console.log(e);

    if (e.target.files.length !== 0) {
      let file = "";
      file = e.target.files[0];
      //   console.log(file);
      setFile(e.target.files[0]);
      setThumbnail(e.target.files[0]);
      // setFileName(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      // read file as data url
      reader.onload = (e) => {
        console.log(e.target.result);
        // called once readAsDataURL is completed
        setFilePath(e.target.result);
      };
      //  let forminputs = new FormData()
      //     forminputs.set("file", 'aaaaa')
      //     forminputs.set("userId", 7)
      //     forminputs.set("documentKey", "childphoto")
      //     forminputs.set("documentContent", "content")

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/fileupload",
        url: `${SERVER_URL}${FILE_UPLOAD}`,
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };
      console.log("process", process.env);
      let response = await apiServiceImage(apiInfo);
      console.log("response", response);
      // props.handleFile(fileUploaded);
    }
  }
  const handleChange3 = (event) => {
    console.log("valueTarget", event.target);
    console.log("list", list);
    const {
      target: { value },
    } = event;

    let data = list.find((item) => {
      return item.userId === value[0];
    });

    setPersonName((prev) => [...prev, data.userName]);

    console.log("data", data);
    // setPersonName(
    //   // On autofill we get a stringified value.
    //   // typeof value === "string" ? value.split(",") : value
    //   data.userName
    // );
  };

  // const [formData, setFormData] = React.useState(campData);
  // const handleOpen = (head) => {
  //   setOpen(true);
  //   console.log(head);
  // };
  const handleClose = () => {
    setOpen(false);
    // setSearch('')
  };
  const validationSchema = Yup.object().shape({
    campName: Yup.string().required("Camp name is required"),
    location: Yup.string().required("Location is required"),

    campStartdate: Yup.date().nullable().typeError("Start date is required"),

    campRegStartdate: Yup.date()
      .nullable()
      .typeError("Reg Start date is required"),
    campRegEnddate: Yup.date().nullable().typeError("Reg End date is required"),

    campEnddate: Yup.date().nullable().typeError("End date is required"),

    campType: Yup.string().required("Camp type is required"),

    campMinAge: Yup.string().required("Minimum Age is required"),
    campMaxAge: Yup.string().required("Maximum Age is required"),

    maxSpots: Yup.number()
      .required("Max spots is required")
      .typeError("Enter Number Of Max Spots "),

    description: Yup.string().required("Description is required"),

    campFeeParticipants: Yup.number()
      .required("Fee is required")
      .typeError("Fee is required"),
    campFeeSS: Yup.number()
      .required("Fee is required")
      .typeError("Fee is required"),
  });

  // const date2 = `${campStartdate.getMonth() + 1}/${
  //   campStartdate.getDate() - 1
  // }/${campStartdate.getFullYear()}`;

  const removeimg = () => {
    console.log(attachmentsArr);
    setTimeout(() => {
      setThumbnailFile(null);
      setThumbnailPath(null);
      hiddenFileInputThum.current.value = "";
    }, 500);

    // const [thumbnailPath, setThumbnailPath] = useState(null);
  };

  // const generateThumbnail = async (e, batch) => {
  //   let videoUrl = e.target.value
  //   console.log("Got video URL", e.target.value, batch)
  //   let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
  //   let res = await axios({
  //     url: apiUrl,
  //     method: 'get',
  //     timeout: 8000,
  //     headers: {
  //       'Content-Type': 'application/json',
  //     }
  //   })

  //   console.log("Resppp", res)
  //   if (res.status == 200)
  //     // setThumbnailUrl(res.data.thumbnail_url_with_play_button)

  //   setVideoRow(
  //     videoRow.map((row, index) =>
  //       index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
  //     )
  //   )

  // }
  const generateThumbnail = (e, batch) => {

    (async () => {
      console.log(batch, 'oooooooo')
      let videoUrl = e.target.value
      setVideoRow(
        videoRow.map((row, index) =>
          index + 1 === batch.id ? { ...row, actualUrl: e.target.value } : row
        )
      )
      let apiUrl = `https://vimeo.com/api/oembed.json?url=${videoUrl}`
      let res = await axios({
        url: apiUrl,
        method: 'get',
        timeout: 8000,
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(res)

      if (res.status == 200)
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: res.data.thumbnail_url_with_play_button, actualUrl: e.target.value } : row
          )
        )
    })()
      .catch(() => {
        console.log('wohoooooooooo')
        setVideoRow(
          videoRow.map((row, index) =>
            index + 1 === batch.id ? { ...row, thumbnailurl: ThumbnailImage, actualUrl:  e.target.value } : row
          )
        )
       
      })
      .finally(() => {
        // setLoading(false);
      });

  };
  console.log(videoRow, "herpppp");
  const generateStatic = (e,batch) =>{
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === batch.id ? { ...row, actualUrl: e.target.value,thumbnailurl: ThumbnailImage} : row
      )
    )
  }
  const handleVideoRow = () => {
    setVideoRow([
      ...videoRow,
      {
        id: videoRow.length + 1,
        actualUrl: "",
        description: "",
        thumbnailurl: "",
        videoType: "PRE_CAMP",
        videoProvider: "vimeo",
      },
    ]);

    console.log(videoRow);
  };
  const handleDesc = (e, item) => {
    setVideoRow(
      videoRow.map((row, index) =>
        index + 1 === item.id ? { ...row, description: e.target.value } : row
      )
    );
  };
  const removeUrlItem = (index) => {
    setVideoRow([...videoRow.slice(0, index), ...videoRow.slice(index + 1)]);
  };
  const removeAttachment = (index) => {
    setAtachmentsArr([
      ...attachmentsArr.slice(0, index),
      ...attachmentsArr.slice(index + 1),
    ]);
    hiddenFileInput.current.value = "";
  };

  const {
    register,
    trigger,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    // defaultValues: async () => await getProfileDetails(),
  });

  // const captureMinAge = async (event) => {
  //  setMinAge(event.target.value)

  // };

  const doSomething = async (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    console.log(value);
    // setTableDates(prev => [{ ...prev, start: value }])
    setTableStart(value);
    var date = new Date(value);

    var day = date.getTime() - 10 * 24 * 60 * 60 * 1000;
    var regDay = date.getTime();

    date.setTime(day);
    const newDateVal = formatDate(new Date(date).toISOString().substr(0, 10));
    // console.log(newDateVal);
    setToday(newDateVal);

    date.setTime(regDay);
    const regDateValue = formatDate(new Date(date).toISOString().substr(0, 10));
    setRegDate(regDateValue);
    // setDateState(true)
  };

  const getEndDate = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    var d = new Date(value);
    setTableEnd(value);
  };

  const getRegStartDate = (value) => {
    setRegStart(value);
  };

  const getRegEndDate = (value) => {
    setRegEnd(value);
  };

  React.useEffect(() => {
    if (tableStart && tableEnd) {
      const date1 = new Date(tableStart);
      const date2 = new Date(tableEnd);

      console.log("dates", date1, date2);

      let allDates = getDatesBetween(date1, date2);
      console.log(allDates);
      // setFinalDateArray(allDates);
      const arr = [];
      if (allDates && allDates.length > 0) {
        allDates.forEach((item, index) => {
          arr.push({
            campDate: moment(item).format("YYYY-MM-DD"),
            campHours: 12,
          });
        });
        setFinalDateArray(allDates);
        setHoursTable(arr);
      }
    }
    console.log(hoursTable, 'whatttttt')
    if (new Date(tableStart).getTime() <= new Date(tableEnd).getTime()) {
      console.log("yesssssssssss");
      setCampErr(false);
    } else if (new Date(tableStart).getTime() > new Date(tableEnd).getTime()) {
      setCampErr(true);
    }

    if (new Date(regStart).getTime() < new Date(regEnd).getTime()) {
      setRegErr(false);
    } else if (
      new Date(regStart).getTime() > new Date(regEnd).getTime() ||
      new Date(regStart).getTime() > new Date(tableEnd).getTime() ||
      new Date(regStart).getTime() > new Date(tableStart).getTime() ||
      new Date(regEnd).getTime() > new Date(tableStart).getTime()
    ) {
      setRegErr(true);
    }

    let isMaxSpots = false;
    if (
      (Number(formData.maxSpots) <= 60 && Number(formData.maxSpots) >= 10) ||
      formData.maxSpots === ""
    ) {
      isMaxSpots = true;
      setSpotErr("");
    } else {
      setSpotErr("Max spots should be between 10 & 60");
    }

    let isMinAge = false;
    if (
      Number(formData.campMinAge) < Number(formData.campMaxAge) ||
      formData.campMinAge === "" ||
      formData.campMaxAge === ""
    ) {
      isMinAge = true;
      console.log("looping inside min age");
      setErrMsg("");
    } else {
      setErrMsg("Min age should be less than Max age");
    }
  }, [tableStart, tableEnd, regStart, regEnd, formData]);


  function getDatesBetween(startDate, endDate) {
    const currentDate = new Date(startDate.getTime());
    const dates = [];
    while (currentDate <= endDate) {
      dates.push(new Date(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
      // moment(data.campStartdate).format("YYYY-MM-DD")
    }
    return dates;
  }

  React.useEffect(() => {
    var date = new Date();
    var day = date.getTime();
    date.setTime(day);
    const newDate = formatDate(new Date(date).toISOString().substr(0, 10));
    setLatestDate(newDate);
  }, []);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  // const getTableFormData = (data) => {
  //   setTableFormData((prev) =>
  //     prev.formIndex === formIndex
  //       ? (prev = [
  //           prev,
  //           ...[
  //             {
  //               StaffInfo: [...data],
  //             },
  //           ],
  //         ])
  //       : [
  //           ...prev,
  //           ...[
  //             {
  //               StaffInfo: [...data],
  //             },
  //           ],
  //         ]
  //   );
  //   setOpen(false);
  // };

  const getStaffInfo = (userRole, index) => {
    let data = [];
    (tableformData || []).forEach((item) => {
      data = [...data, ...item.StaffInfo];
    });
    let userNames = (data || []).filter((ele) => {
      return ele.formIndex === index && ele.rolesId === userRole;
    });
    return (userNames || []).map((item) => item.userName);
  };
  // console.log("getStaffInfo", getStaffInfo(3, 0));

  function storeInfo(event) {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  console.log(formData, "herrrrrrrrrrrr");

  const onSubmit = async (data) => {
    let isMaxSpots = false;
    if (Number(data.maxSpots) <= 60 && Number(data.maxSpots) >= 10) {
      isMaxSpots = true;
    } else {
      setSpotErr("Max spots allowed is 60 and Min spots is 10");
    }

    let isMinAge = false;
    if (Number(data.campMinAge) < Number(data.campMaxAge)) {
      isMinAge = true;
      console.log("looping inside min age");
      setErrMsg("");
    } else {
      setErrMsg("Min age should be less than Max age");
    }

    let finalData = data;

    var s = moment(data.campStartdate).format("YYYY-MM-DD");
    var s1 = moment(data.campEnddate).format("YYYY-MM-DD");
    var s2 = moment(data.campRegStartdate).format("YYYY-MM-DD");
    var s3 = moment(data.campRegEnddate).format("YYYY-MM-DD");
    finalData["campStartdate"] = s;
    finalData["campEnddate"] = s1;
    finalData["campRegStartdate"] = s2;
    finalData["campRegEnddate"] = s3;
    const fileInfo = [];
    const thum = [thumbnailDocPath];
    const attachmentsPaths = [];
    if (attachmentsArr && attachmentsArr.length > 0) {
      attachmentsArr.forEach((attach) => {
        attachmentsPaths.push(attach.docPath);
      });
    }
    const totalFiles = [...attachmentsPaths, ...thum];
    if (totalFiles && totalFiles.length > 0) {
      totalFiles.forEach((file) => {
        fileInfo.push({ documentKey: "camp", documentHash: file });
      });
    }
    const finalArray = [];
    rows.forEach((row) => {
      const obj = {
        groupName: row.group,
        staffinfo: [
          {
            rolesId: "",
            staffUserId: "",
          },
          {
            rolesId: "",
            staffUserId: "",
          },
          {
            rolesId: "",
            staffUserId: "",
          },
          {
            rolesId: "",
            staffUserId: "",
          },
        ],
      };

      filteredList.forEach((list) => {
        // console.log(list)
        if (row.groupLeaderId === list.userId) {
          obj["staffinfo"][0]["rolesId"] = 9;
          obj["staffinfo"][0]["staffUserId"] = list.userId;
        }
        if (row.supportStaffId === list.userId) {
          obj["staffinfo"][1]["rolesId"] = 4;
          obj["staffinfo"][1]["staffUserId"] = list.userId;
        }
        if (row.therapistId === list.userId) {
          obj["staffinfo"][2]["rolesId"] = 5;
          obj["staffinfo"][2]["staffUserId"] = list.userId;
        }
        if (row.medicationManagerId === list.userId) {
          obj["staffinfo"][3]["rolesId"] = 6;
          obj["staffinfo"][3]["staffUserId"] = list.userId;
        }
      });
      finalArray.push(obj);
    });

    // if (new Date(data.campStartdate).getTime() < new Date(data.campEnddate).getTime()) {
    //   console.log('yesssssssssss')

    // }
    // else if (new Date(data.campStartdate).getTime() > new Date(data.campEnddate).getTime()) {
    //   setCampErr(true)
    // }

    // new Date(data.campStartdate).getTime() < new Date(data.campEnddate).getTime()
    // debugger
    // if (isMinAge && campErr !== true && regErr !== true && isMaxSpots) {

    if (isMinAge && isMaxSpots) {
      // videoRow.forEach((item) => {
      //   console.log(item)
      //   setVideoRow(videoRow.filter((row) => row.actualUrl !== ''));

      // });
      // if ((finalArray && finalArray.length != 0) ) {
      setGroupError(false);
      finalData.groupInfo =
        finalArray && finalArray.length === 0 ? [] : finalArray;
      finalData["userId"] = localStorage.getItem("userId");
      finalData["filesInfo"] = fileInfo;
      finalData["campHoursDatewise"] = hoursTable;
      finalData["videoInfo"] = videoRow;
      console.log("finalData", finalData, "hooooooooooo");

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/createcamp",
        url: `${SERVER_URL}${CREATE_CAMP}`,
        method: "POST",
        postData: finalData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let createcmp = await apiService(apiInfo);
      if (createcmp?.data?.responseData[0][0]?.Code === "0") {
        // alert("Register successfull");
        Swal.fire({
          icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,
          // text: register?.data?.responseData,
          text: createcmp?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: "no-border",
          },
        }).then(history.push("/CampList1"));
        // } else if(createcmp?.data?.responseData[0][0]?.Code === '1'){
      } else {
        Swal.fire({
          icon: "error",

          // text: register?.data?.responseData,
          text: createcmp?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: "no-border",
          },
        });
      }
    } else {
      Swal.fire({
        icon: "error",

        // text: register?.data?.responseData,
        text: "Invalid Fields",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        customClass: {
          container: "my-swal",
          icon: "no-border",
        },
      });
    }
  };

  const onPublish = async (data) => {
    console.log("groupByconsole", groupBy("groupName"));
    let isMaxSpots = false;

    if (Number(data.maxSpots) <= 60 && Number(data.maxSpots) >= 10) {
      isMaxSpots = true;
    } else {
      setSpotErr("Max spots allowed is 60 and Min spots is 10");
    }

    let isMinAge = false;
    if (Number(data.campMinAge) < Number(data.campMaxAge)) {
      isMinAge = true;
      console.log("looping inside min age");
      setErrMsg("");
    } else {
      setErrMsg("Min age should be less than Max age");
    }

    // let isMaxSpots = false

    let finalData = data;

    var s = moment(data.campStartdate).format("YYYY-MM-DD");
    var s1 = moment(data.campEnddate).format("YYYY-MM-DD");
    var s2 = moment(data.campRegStartdate).format("YYYY-MM-DD");
    var s3 = moment(data.campRegEnddate).format("YYYY-MM-DD");
    finalData["campStartdate"] = s;
    finalData["campEnddate"] = s1;
    finalData["campRegStartdate"] = s2;
    finalData["campRegEnddate"] = s3;
    const fileInfo = [];
    const thum = [thumbnailDocPath];
    const totalFiles = [...docPath, ...thum];
    if (totalFiles && totalFiles.length > 0) {
      totalFiles.forEach((file) => {
        fileInfo.push({ documentKey: "camp", documentHash: file });
      });
    }
    // let finalData = formData;

    const finalArray = [];

    rows.forEach((row) => {
      const obj = {
        groupName: row.group,
        staffinfo: [
          {
            rolesId: "",
            staffUserId: "",
          },
          {
            rolesId: "",
            staffUserId: "",
          },
          {
            rolesId: "",
            staffUserId: "",
          },
          {
            rolesId: "",
            staffUserId: "",
          },
        ],
      };

      filteredList.forEach((list) => {
        console.log(list);
        if (row.groupLeaderId === list.userId) {
          obj["staffinfo"][0]["rolesId"] = 9;
          obj["staffinfo"][0]["staffUserId"] = list.userId;
        }
        if (row.supportStaffId === list.userId) {
          obj["staffinfo"][1]["rolesId"] = 4;
          obj["staffinfo"][1]["staffUserId"] = list.userId;
        }
        if (row.therapistId === list.userId) {
          obj["staffinfo"][2]["rolesId"] = 5;
          obj["staffinfo"][2]["staffUserId"] = list.userId;
        }
        if (row.medicationManagerId === list.userId) {
          obj["staffinfo"][3]["rolesId"] = 6;
          obj["staffinfo"][3]["staffUserId"] = list.userId;
        }
      });
      finalArray.push(obj);
    });



    if (isMinAge && isMaxSpots) {
      setGroupError(false);
      finalData.groupInfo =
        finalArray && finalArray.length === 0 ? [] : finalArray;
      finalData["userId"] = localStorage.getItem("userId");
      finalData["campStatus"] = "PUBLISHED";
      finalData["filesInfo"] = fileInfo;
      finalData["campHoursDatewise"] = hoursTable;
      finalData["videoInfo"] = videoRow;
      console.log("finalData", finalData);
      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/createcamp",
        url: `${SERVER_URL}${CREATE_CAMP}`,
        method: "POST",
        postData: finalData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let createcmp = await apiService(apiInfo);
      if (createcmp?.data?.responseData[0][0].Code === "0") {
        // alert("Register successfull");
        Swal.fire({
          icon: "success",
          iconHtml: `<img src="${GreenCheck}" >`,

          text: createcmp?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: "no-border",
          },
        }).then(history.push("/CampList1"));
      } else {
        Swal.fire({
          icon: "error",
          text: createcmp?.data?.responseData[0][0].Desc,
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          customClass: {
            container: "my-swal",
            icon: "no-border",
          },
        });
      }
    }
  };

  const handleAddRow = () => {
    setRows([
      ...rows,
      {
        id: rows.length + 1,
        group: "",
        groupLeader: "",
        groupLeaderId: "",

        supportStaff: "",
        supportStaffId: "",

        therapist: "",
        therapistId: "",

        medicationManager: "",
        medicationManagerId: "",
      },
    ]);

    console.log(rows);
  };
  const handleDeleteRow = (id) => {
    console.log(rows);
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleRowChange = (id, field, value, field1, value1) => {
    {
      console.log(id, field, value, field1, value1);
    }
    setRows(
      rows.map((row) =>
        row.id === id ? { ...row, [field]: value, [field1]: value1 } : row
      )
    );
    console.log(rows);
    setSearchName(null);
    setOpen(false);
    setSearch("");
    setSelectedType("");
    setUserId("");
  };
  const selectItem = (selectedName, userId) => {
    setSearchName(selectedName);
    setUserId(userId);

    console.log(seachName);
  };
  const handleOpen = (id, name) => {
    console.log(name);
    console.log(id);
    setOpen(true);
    setSelectedType(name);
    setRowId(id);
    // console.log(rowId)
  };

  React.useEffect(() => {
    (async () => {
      let inputData = {
        userId: localStorage.getItem("userId"),
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/staff/getlistofstaff",
        url: `${SERVER_URL}${GETLIST_STAFF}`,
        method: "POST",
        postData: inputData,
        headers: {
          "Content-Type": "application/json",
          tokenheaderkey: localStorage.getItem("token"),
        },
      };

      let output = await apiService2(apiInfo);
      let listofStaff = output.data.responseData[0];

      setList(listofStaff);
    })()
      .catch((e) => {
        // console.error(e);
      })
      .finally(() => { });
  }, []);

  if (list && list.length > 0) {
    var filteredList = list.filter((item) =>
      item.userName.toLowerCase().includes(search.toLowerCase())
    );
  } else {
    filteredList = [{}];
  }

  // console.log("getStaffInfo", getStaffInfo(3, 0));
  const callApi = () => {
    let groupInfo = groupBy("groupName");
  };
  function groupBy(property) {
    let dataArr = [];
    (tableformData || []).forEach((item) => {
      dataArr = [...dataArr, ...item.StaffInfo];
    });
    let prepareData = [];
    if (dataArr.length) {
      prepareData = (dataArr || []).reduce((acc, obj) => {
        const key = obj[property];
        if (!acc[key]) {
          acc[key] = [];
        }

        acc[key].push(obj);
        return acc;
      }, {});
    }
    let finalTableData = [];
    for (let item in prepareData) {
      finalTableData = [
        ...finalTableData,
        {
          groupName: prepareData[item][0].groupName,
          staffInfo: removeKeys(prepareData[item]),
        },
      ];
    }
    return finalTableData;
    console.log("finalTableData", finalTableData);
  }
  const removeKeys = (arr) => {
    return arr.map((ele) => {
      return {
        rolesId: ele.rolesId,
        staffUserID: ele.staffUserID,
      };
    });
  };

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleSearchChange = (event) => {
    setSearch(event.target.value);
  };
  const documentPaths = [];

  async function uploadThumnails(event) {
    event.preventDefault();

    const forminputsTwo = new FormData();

    forminputsTwo.append("file", thumbnailFile);
    forminputsTwo.append("userId", localStorage.getItem("userId"));
    forminputsTwo.append("documentKey", "camp");
    forminputsTwo.append("documentContent", "Thumbnail");
    forminputsTwo.append("documentType", "THUMBNAIL");
    console.log(forminputsTwo, "sjcakdjcbdcj");
    axios
      .post(
        // "https://cncdevapi.natsoft.io/api/camps/fileupload",
        `${SERVER_URL}${FILE_UPLOAD}`,
        forminputsTwo,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        }
      )
      .then((response) => {
        console.log(response);
        setThumbnailthumbnailDocPath(
          response["data"]["result"][0][0].documentHash
        );

        setThumbStatus("Thumbnail uploaded");
      })
      .catch((error) => { });
  }

  const handleThumbnailChange = (event) => {
    console.log(event.target.files[0], "lolololololol");

    setThumbnailFile(event.target.files[0]);
    // if (
    //   parseFloat(event.target.files[0].size / (1024 * 1024)).toFixed(2) > 3
    // ) {
    //   Swal.fire({
    //     icon: "error",

    //     text: "Please select image size less than 3 MB",
    //     confirmButtonText: "OK",
    //     confirmButtonColor: "#379592",
    //     customClass: {
    //       container: "my-swal",
    //     },
    //   }).then(history.push("/CreateCamp"));
    // }
    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = (e) => {
      setThumbnailPath(e.target.result);
    };
    setSelectedImages((previousImages) =>
      previousImages.concat(thumbnailFile)
    );
    if (
      event.target.files[0].type === "image/jpeg" ||
      event.target.files[0].type === "image/png" ||
      event.target.files[0].type === "image/jpg" ||
      event.target.files[0].type === "image/svg" ||
      event.target.files[0].type === "image/jfif" ||
      event.target.files[0].type === "image/tiff"

    ) {
      console.log("insideee");
      if (parseFloat(event.target.files[0].size / (1024 * 1024)).toFixed(2) > 5) {
        console.log(event.target.files[0].size);
        // alert("Please select image size less than 2 MB");
        // setImageAlert(true)
        Swal.fire({
          icon: "error",
          confirmButtonText: "OK",
          confirmButtonColor: "#379592",
          text: "Please select image size less than 5 MB",
          customClass: {
            container: "my-swal",
          },
        });
        setTimeout(() => {


          setThumbnailFile(null);
          setThumbnailPath(null);
        }, 2000);


      } else {
        // setImageAlert(false)
        console.log("yes");
      }
    } else {
      Swal.fire({
        icon: "error",
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "You can upload only following file types: jpeg, jpg, png, gif, svg, mp4, pdf, PDF, ionic, jfif, xls, xlsx, doc, docx",
        customClass: {
          container: "my-swal",
        },
      });
      setTimeout(() => {
        setThumbnailFile(null);
        setThumbnailPath(null);
      }, 2000);
    }

  };

  function displayThumbnailError() {
    // alert()
    if (thumbnailFile === null) {
      seterrorThumbnail(true);
    } else {
      seterrorThumbnail(false);
    }
  }


  const handleFileChange = (event) => {
    console.log(event);

    const numSelectedFiles = event.target.files ? event.target.files.length : 0;
    const numTotalFiles = attachmentsArr.length + numSelectedFiles;

    if (numTotalFiles > 5) {
      // alert("Please select only 5 files");

      Swal.fire({
        icon: "error",
        // iconHtml: `<img src="${GreenCheck}" >`,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "Please select only 5 files",
        customClass: {
          container: "my-swal",
        },
      }).then((result) => { });
      return;
    }

    if (
      (event.target.files &&
        (event.target.files.length === 4 || event.target.files.length < 4)) ||
      (attachmentsArr && (attachmentsArr === 4 || attachmentsArr.length < 4))
    ) {
      for (const file of event.target.files) {
        console.log(file, "fileeeeeeeeeeeeee");
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = (e) => {
          setImgsSrc((imgs) => [...imgs, e.target.result]);
          setAttachments((paths) => [...paths, file]);
          setAtachmentsArr((attachment) => [
            ...attachment,
            {
              path: e.target.result,
              fileObj: file,
              docPath: "",
              docTitle: file.name,
            },
          ]);
        };
      }

      if (event.target.files && event.target.files.length > 0) {
        for (const file of event.target.files) {
          console.log(file, "lolollolololololol", file.type);
          // ||"image/png" || "image/jpg" || "image/gif" || "image/svg"
          // accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          if (
            file.type === "image/jpeg" ||
            file.type === "image/png" ||
            file.type === "image/jpg" ||
            file.type === "image/svg" ||
            file.type === "image/jfif" ||
            file.type === "image/tiff" ||
            file.type === "application/pdf" ||
            file.type === "application/PDF" ||
            file.type === "application/msword" ||
            file.type ===
            "application/vnd.openxmlformats-officedocument.wordprocessingml.document" ||
            file.type === "application/xls" ||
            file.type === ".xlsx"
          ) {
            console.log("insideee");
            if (parseFloat(file.size / (1024 * 1024)).toFixed(2) > 5) {
              console.log(file.size);
              // alert("Please select image size less than 2 MB");
              // setImageAlert(true)
              Swal.fire({
                icon: "error",
                confirmButtonText: "OK",
                confirmButtonColor: "#379592",
                text: "Please select image size less than 5 MB",
                customClass: {
                  container: "my-swal",
                },
              });
              setTimeout(() => {
                console.log(attachmentsArr, file.name); // Log current values for debugging purposes
                setAtachmentsArr((attachmentsArr) => {
                  return attachmentsArr.filter(
                    (row) => row.docTitle !== file.name
                  );
                });
              }, 2000);

              console.log(attachmentsArr, "hereeeeeeeeeeeee");
            } else {
              // setImageAlert(false)
              console.log("yes");
            }
          } else {
            Swal.fire({
              icon: "error",
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              text: "You can upload only following file types: jpeg, jpg, png, gif, svg, mp4, pdf, PDF, ionic, jfif, xls, xlsx, doc, docx",
              customClass: {
                container: "my-swal",
              },
            });
            setTimeout(() => {
              console.log(attachmentsArr, file.name); // Log current values for debugging purposes
              setAtachmentsArr((attachmentsArr) => {
                return attachmentsArr.filter(
                  (row) => row.docTitle !== file.name
                );
              });
            }, 2000);
          }
        }
      }
    }
    else {
      Swal.fire({
        icon: "error",
        // iconHtml: `<img src="${GreenCheck}" >`,
        confirmButtonText: "OK",
        confirmButtonColor: "#379592",
        text: "Please select only 5 files",
        customClass: {
          container: "my-swal",
        },
      }).then((result) => { });
    }
  };

  async function uploadAttachments(event) {
    console.log(attachmentsArr);
    event.preventDefault();
    // setFileName('')
    const uniq = [...new Set(attachments)];

    attachmentsArr.forEach((file) => {
      const forminputs = new FormData();
      console.log(file);
      forminputs.append("file", file.fileObj);
      forminputs.append("userId", localStorage.getItem("userId"));
      forminputs.append("documentKey", "camp");
      forminputs.append("documentContent", "content");
      forminputs.append("documentType", "PRE_CAMP");

      axios
        .post(`${SERVER_URL}${FILE_UPLOAD}`, forminputs, {
          headers: {
            "Content-Type": "multipart/form-data",
            tokenheaderkey: localStorage.getItem("token"),
          },
        })
        .then((response) => {
          if (response?.data?.responseCode === 1) {
            console.log("enetring that");

            Swal.fire({
              icon: "error",
              text: response?.data?.responseData,
              confirmButtonText: "OK",
              confirmButtonColor: "#379592",
              customClass: {
                container: "my-swal",
              },
            });
          } else if (response.data.result[0][0].Code === "0") {
            console.log("entering this");
            setDocPath((paths) => [
              ...paths,
              response["data"]["result"][0][0].documentHash,
            ]);
            displayFileError();
            setAttachmentsStatus("Attachments Uploaded");

            // return { ...file, [file.docPath]: response['data']['result'][0][0].documentHash };
            // Handle server response

            setAtachmentsArr((attach) => {
              return attach.map((attachment) => {
                if (attachment.fileObj === file.fileObj) {
                  // Update the docPath property for the matching attachment
                  return {
                    ...attachment,
                    docPath: response["data"]["result"][0][0].documentHash,
                  };
                } else {
                  return attachment;
                }
              });
            });
          }
        })
        .catch((error) => {
          // Handle errors
        });
    });
    //  setAtachmentsArr(newattachments);

    console.log(documentPaths);
  }

  function displayFileError() {
    // alert()
    if (thumbnailFile === null) {
      setErrorFile(true);
    } else {
      setErrorFile(false);
    }
  }



  const setHoursValue = (event, i) => {
    console.log(event.target.value);
    console.log(hoursTable[i]);
    setHoursTable(
      hoursTable.map((row, index) =>
        index === i ? { ...row, campHours: event.target.value } : row
      )
    );
  };
  
  // hoursTable.forEach((item, index) => {
  //   console.log(item, 'nowwwww',item.campHours)
  //   if (item.campHours == null) {
  //     console.log('coming insideeeeeeeeeeeeeeeee')
  //     // item.campHours = 10
  //   }
  //   else{
  //     console.log('not insideeeeeeeeeeeeeeeee')
  //     item.campHours = 12
  //   }

  // })
  console.log(hoursTable)
  return (
    <>
      {/* <BrowserRouter> */}
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div>
          <Stack
            direction="row"
            spacing={1}
            xs={12}
            sm={12}
            md={6}
            alignItems="center"
          >
            <Link to="/CampList">
              <Box
                alignSelf="center"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>
            <Box alignSelf="center">Create Camp</Box>
          </Stack>
          {/* <Stack
            direction="row"
            spacing={1}
            xs={12}
            sm={12}
            md={6}
            alignItems="center"
          >
            <Link to="/CampList">
              <Box
                mt="0.65rem"
                alignSelf="center"
                component="img"
                sx={{
                  height: "0.75rem",
                  width: "0.65rem",
                  cursor: "pointer",
                }}
                height="20"
                alt="Logo"
                src={ArrowBack}
              ></Box>
            </Link>
            <Box alignSelf="center">Create Camp</Box>
          </Stack> */}
          {/* <Box>Creating Camp</Box> */}
          <Grid mx="1rem" className="txt-grey">
            <Box>
              <TextField
                id="standard-basic"
                label="Camp Name"
                variant="standard"
                name="campName"
                className="txt-grey"
                // value={formData.campName}
                // onChange={(event) => storeInfo(event)}
                {...register("campName")}
                error={errors.campName ? true : false}
                // sx={{
                //   width: 220,
                // }}
                sx={{ width: 185 }}
              ></TextField>
              <Typography
                color="error.main"
                // ml="2rem"
                sx={{ fontSize: "12px", textAlign: "left" }}
              >
                {errors.campName?.message}
              </Typography>
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="input-with-sx"
                  label="Start Date"
                  variant="standard"
                  type="date"
                  // sx={{ width: 220 }}
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: latestDate,
                  }}
                  align="center"
                  name="campStartdate"
                  // value={formData.campEnddate}
                  // onChange={(event) => storeInfo(event)}
                  {...register("campStartdate")}
                  error={errors.campStartdate ? true : false}
                  onChange={(e) => doSomething(e)}
                />

                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campStartdate?.message}
                  {campErr ? "Start date should be before End date" : ""}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  disabled={!regDate}
                  id="input-with-sx"
                  label="End Date"
                  variant="standard"
                  type="date"
                  // sx={{ width: 220 }}
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: regDate,
                  }}
                  align="center"
                  name="campEnddate"
                  // value={formData.campEnddate}
                  // onChange={(event) => storeInfo(event)}
                  {...register("campEnddate")}
                  error={errors.campEnddate ? true : false}
                  onChange={(e) => getEndDate(e)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campEnddate?.message}
                  {campErr ? "End date should be after Start date" : ""}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="standard-basic"
                  // sx={{ width: 200 }}
                  sx={{ width: 185 }}
                  label="Location"
                  variant="standard"
                  className="txt-grey "
                  name="location"
                  {...register("location")}
                  error={errors.location ? true : false}

                  // value={formData.location}
                  // onChange={(event) => storeInfo(event)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.location?.message}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  disabled={!regDate}
                  id="input-with-sx"
                  label="Registration open date "
                  variant="standard"
                  type="date"
                  defaultValue=" "
                  // sx={{ width: 220 }}
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    // max: latestDate,
                    max: regDate,
                  }}
                  name="campRegStartdate"
                  {...register("campRegStartdate")}
                  error={errors.campRegStartdate ? true : false}
                  onChange={(e) => getRegStartDate(e.target.value)}
                  // value={formData.campRegStartdate}
                  // onChange={(event) => storeInfo(event)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campRegStartdate?.message}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  disabled={!regDate}
                  id="input-with-sx"
                  label="Registration close date"
                  variant="standard"
                  type="date"
                  // sx={{ width: 220 }}
                  sx={{ width: 185 }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  align="center"
                  name="campRegEnddate"
                  // value={formData.campRegEnddate}
                  // onChange={(event) => storeInfo(event)}
                  {...register("campRegEnddate")}
                  error={errors.campRegEnddate ? true : false}
                  inputProps={{
                    // max: latestDate,
                    max: regDate,
                  }}
                  onChange={(e) => getRegEndDate(e.target.value)}
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campRegEnddate?.message}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={12} md={4} mt="0.65rem">
                <TextField
                  id="standard-basic"
                  label="Max Spots:   "
                  variant="standard"
                  // sx={{ width: 200 }}
                  sx={{ width: 185 }}
                  // sx={{ my: "1rem" }}
                  className="txt-grey"
                  name="maxSpots"
                  // value={formData.maxSpots}

                  {...register("maxSpots")}
                  error={errors.maxSpots ? true : false}
                  onChange={(event) => storeInfo(event)}
                ></TextField>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.maxSpots?.message}
                  {spotErr}
                </Typography>
              </Grid>
            </Grid>
            <Typography
              color="error.main"
              sx={{ fontSize: "12px", textAlign: "left" }}
            >
              {regErr ? "Invalid dates" : ""}
            </Typography>

            <Grid container spacing={4}>
              <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Minimum Age
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campMinAge"
                    // sx={{ width: 220 }}
                    sx={{ width: 185 }}
                    // value={formData.campAgegroup}
                    {...register("campMinAge")}
                    error={errors.campMinAge ? true : false}
                    label="Select Age Group"
                    onChange={(event) => storeInfo(event)}
                  >
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campMinAge?.message}
                  {errMsg}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.25rem">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Maximum Age
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campMaxAge"
                    // sx={{ width: 220 }}
                    sx={{ width: 185 }}
                    // value={formData.campAgegroup}

                    {...register("campMaxAge")}
                    error={errors.campMaxAge ? true : false}
                    label="Select Age Group"
                    onChange={(event) => storeInfo(event)}
                  >
                    {/* {ageList.map((item,index)=>(
                      <MenuItem key={index} value={item.value}>{item.age}</MenuItem>
                    ))} */}
                    <MenuItem value="4">4</MenuItem>
                    <MenuItem value="5">5</MenuItem>
                    <MenuItem value="6">6</MenuItem>
                    <MenuItem value="7">7</MenuItem>
                    <MenuItem value="8">8</MenuItem>
                    <MenuItem value="9">9</MenuItem>
                    <MenuItem value="10">10</MenuItem>
                    <MenuItem value="11">11</MenuItem>
                    <MenuItem value="12">12</MenuItem>
                    <MenuItem value="13">13</MenuItem>
                    <MenuItem value="14">14</MenuItem>
                    <MenuItem value="15">15</MenuItem>
                    <MenuItem value="16">16</MenuItem>
                    <MenuItem value="17">17</MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campMaxAge?.message}
                  {errMsg}
                  {/* {isMin && 'Min age should be less than Max age'} */}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={4} mt="0.35rem">
                <FormControl variant="standard">
                  <InputLabel id="demo-simple-select-standard-label">
                    Select Camp Type
                  </InputLabel>
                  <Select
                    // sx={{ width: 200 }}
                    sx={{ width: 185 }}
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    name="campType"
                    // value={formData.campType}
                    // onChange={handleChange1}
                    // onChange={(event) => storeInfo(event)}
                    // label="Select Camp Type"
                    {...register("campType")}
                    error={errors.campType ? true : false}
                    onChange={(event) => storeInfo(event)}
                  >
                    <MenuItem value="Boys Camp">Boys Camp</MenuItem>
                    <MenuItem value="Girls Camp">Girls Camp</MenuItem>
                    <MenuItem value="Support Camp">Support Camp</MenuItem>
                    <MenuItem value="Camp Confidence">Camp Confidence</MenuItem>
                    <MenuItem value="Adventure Camp">Adventure Camp</MenuItem>
                    <MenuItem value="Young Ladies Getaway">
                      Young Ladies Getaway
                    </MenuItem>
                  </Select>
                </FormControl>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campType?.message}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Box mt="2rem">
            <TableContainer component={Paper}>
              <Table
                sx={{ minWidth: 500 }}
                aria-label="customized table"
                my="1rem"
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell align="center">Group</StyledTableCell>
                    <StyledTableCell align="center">
                      Group Leader
                    </StyledTableCell>
                    {/* <StyledTableCell align="center">
                      Support Staff
                    </StyledTableCell> */}
                    <StyledTableCell align="center">Therapist</StyledTableCell>
                    <StyledTableCell align="center">
                      Medication Manager
                    </StyledTableCell>
                    <StyledTableCell align="center">Action</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.id}>
                      <TableCell align="center">
                        <Box>
                          <TextField
                            id="standard-basic"
                            // label="Camp Name"
                            variant="standard"
                            name="campName"
                            className="txt-grey"
                            value={row.group}
                            sx={{ width: 150 }}
                            onChange={(e) =>
                              handleRowChange(
                                row.id,
                                "group",
                                e.target.value,
                                "",
                                ""
                              )
                            }
                          ></TextField>
                        </Box>
                      </TableCell>

                      <TableCell align="center">
                        {row.groupLeader ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.groupLeader}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() => handleOpen(row.id, "groupLeader")}
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              // height: "30px",
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() => handleOpen(row.id, "groupLeader")}
                          ></Grid>
                        )}
                      </TableCell>
                      {/* <TableCell align="center">
                        {row.supportStaff ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.supportStaff}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() => handleOpen(row.id, "supportStaff")}
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() => handleOpen(row.id, "supportStaff")}
                          ></Grid>
                        )}
                      </TableCell> */}
                      <TableCell align="center">
                        {row.therapist ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.therapist}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() => handleOpen(row.id, "therapist")}
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() => handleOpen(row.id, "therapist")}
                          ></Grid>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {row.medicationManager ? (
                          <Grid
                            container
                            display="flex"
                            justifyContent="center"
                          >
                            <Grid item>{row.medicationManager}</Grid>
                            <Grid
                              item
                              mt="0.2rem"
                              ml="0.75rem"
                              component="img"
                              sx={{
                                width: "0.75rem",
                                height: "0.75rem",
                              }}
                              alt="Logo"
                              onClick={() =>
                                handleOpen(row.id, "medicationManager")
                              }
                              src={editIcon}
                            ></Grid>
                          </Grid>
                        ) : (
                          <Grid
                            item
                            mx="1.5rem"
                            component="img"
                            sx={{
                              width: "1.5rem",
                            }}
                            alt="Logo"
                            src={Searchdvance}
                            onClick={() =>
                              handleOpen(row.id, "medicationManager")
                            }
                          ></Grid>
                        )}
                      </TableCell>
                      <TableCell align="center">
                        {/* <Grid
                          item
                          mx="1.5rem"
                          component="img"
                          sx={{
                            width: "1rem",
                          }}
                          alt="Logo"
                          src={Minus}
                          onClick={() => handleDeleteRow(row.id)}
                        ></Grid> */}
                        <Button
                          onClick={() => handleDeleteRow(row.id)}
                          variant="contained"
                          sx={{
                            bgcolor: "#F21010",
                            color: "white",
                            textTransform: "none",
                            "&:hover": {
                              background: "#F21010",
                              color: "white",
                            },
                            mx: "0.5rem",
                            //   width: "150px",
                          }}
                          className="gilroy"
                        >
                          Remove
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
                <tfoot>
                  <TableRow>
                    <TableCell colSpan={12} align="center">
                      <Button
                        disabled={rows.length > 6 ? true : false}
                        variant="contained"
                        sx={{
                          bgcolor: "#4C9F70",
                          color: "white",
                          textTransform: "none",
                          "&:hover": { background: "#4C9F70", color: "white" },
                          //   width: "150px",
                          mx: "0.5rem",
                        }}
                        className="gilroy"
                        onClick={handleAddRow}
                      >
                        Add
                      </Button>
                    </TableCell>
                  </TableRow>
                </tfoot>
              </Table>
            </TableContainer>
            {/* {groupError === true ? (
              <Typography color="error">Group info is missing</Typography>
            ) : (
              ""
            )} */}
          </Box>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={8} sm={12}>
              <Box
                component="form"
                sx={{
                  "& .MuiTextField-root": {
                    mt: "3rem",
                    width: "100%",
                    mb: "1rem",
                  },
                }}
                noValidate
                autoComplete="off"
              >
                <TextField
                  id="outlined-multiline-static"
                  label="Description"
                  multiline
                  rows={4}
                  name="description"
                  // value={formData.description}
                  // onChange={(event) => storeInfo(event)}
                  {...register("description")}
                  error={errors.description ? true : false}
                  // defaultValue="Default Value"
                />
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.description?.message}
                </Typography>
              </Box>
            </Grid>
            {/* <Grid item md={5} sm={12} >
              <Box>
                
                <TextField
                  // id="standard-basic"
                  id="outlined-basic"
                  label="Fee for Support Staff"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeSS"
                  // size='small'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  
                  {...register("campFeeSS")}
                  error={errors.campFeeSS ? true : false}
                ></TextField>
               
                
                
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeSS?.message}
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
          <Box mt={5} mb={5}>
            {" "}
            <Divider />
          </Box>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item md={6} sm={12}>
              <Box mt="2rem">
                <TableContainer component={Paper}>
                  <Table
                    // sx={{ minWidth: 500 }}
                    aria-label="customized table"
                    my="1rem"
                  >
                    <TableHead>
                      <TableRow>
                        <StyledTableCell align="center">Date</StyledTableCell>
                        <StyledTableCell align="center">Hours</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {console.log(hoursTable, "hoursss tableeee")}
                      {console.log(finalDateArray, "finaalllll")}
                      {hoursTable?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell align="center">
                            {moment(item.campDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell align="center">
                            {/* <TextField
                              id="standard-basic"
                              // label="Camp Name"
                              type='number'
                              variant="standard"
                              name="campHours"
                              className="txt-grey"
                                InputProps={{
                                inputProps: { 
                                    max: 24, min: 0 
                                }
                            }}
                              // value={row.group}
                              sx={{ width: 80 }}
                              onChange={(event) => setHoursValue(event, index)}
                            ></TextField> */}
                            <FormControl variant="standard">
                              {/* <InputLabel id="demo-simple-select-standard-label">
                    Select Minimum Age
                  </InputLabel> */}
                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                name="campHours"
                                sx={{ width: 120, maxHeight: 50 }}
                                // value={formData.campAgegroup}

                                // label="Select Age Group"
                                onChange={(event) =>
                                  setHoursValue(event, index)
                                }
                                value={item.campHours}
                                //  defaultValue={12}
                                // onChange={(event) => storeInfo(event)}
                              >
                                {/* <MenuItem value="0">0</MenuItem> */}
                                <MenuItem value="1">1</MenuItem>
                                <MenuItem value="2">2</MenuItem>
                                <MenuItem value="3">3</MenuItem>
                                <MenuItem value="4">4</MenuItem>
                                <MenuItem value="5">5</MenuItem>
                                <MenuItem value="6">6</MenuItem>
                                <MenuItem value="7">7</MenuItem>
                                <MenuItem value="8">8</MenuItem>
                                <MenuItem value="9">9</MenuItem>
                                <MenuItem value="10">10</MenuItem>
                                <MenuItem value="11">11</MenuItem>
                                <MenuItem value="12">12</MenuItem>
                                <MenuItem value="13">13</MenuItem>
                                <MenuItem value="14">14</MenuItem>
                                <MenuItem value="15">15</MenuItem>
                                <MenuItem value="16">16</MenuItem>
                                {/* <MenuItem value="17">17</MenuItem>
                    <MenuItem value="18">18</MenuItem>
                    <MenuItem value="19">19</MenuItem>
                    <MenuItem value="20">20</MenuItem>
                    <MenuItem value="21">21</MenuItem>
                    <MenuItem value="22">22</MenuItem>
                     <MenuItem value="23">23</MenuItem>
                     <MenuItem value="24">24</MenuItem> */}
                              </Select>
                            </FormControl>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {campErr ? "Start and End Dates are not valid" : ""}
                  {/* {campHoursErr ? ('Hours should only be numbers and less than 24') : ('')} */}
                </Typography>

                {/* {groupError === true ? (
              <Typography color="error">Group info is missing</Typography>
            ) : (
              ""
            )} */}
              </Box>
            </Grid>

            {/* <Grid item md={5} sm={12} >
              <Box>
                
                <TextField
                  id="outlined-basic"
                  label="Fee for Participants/hr"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeParticipants"
                  
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    )
                  }}
                  {...register("campFeeParticipants")}
                  error={errors.campFeeParticipants ? true : false}
                ></TextField>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeParticipants?.message}
                </Typography>
              </Box>
            </Grid> */}
          </Grid>
          {/* <Box mt={5} mb={5}>
            {" "}
            <Divider />
          </Box> */}
          <Grid container display="flex" mt="2rem" columnSpacing={10}>
            <Grid item md={3} sm={12}>
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Fee for Participants/hr"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeParticipants"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("campFeeParticipants")}
                  error={errors.campFeeParticipants ? true : false}
                ></TextField>
                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeParticipants?.message}
                </Typography>
              </Box>
            </Grid>
            <Grid item md={3} sm={12} className="feessedit">
              <Box>
                <TextField
                  // id="standard-basic"
                  id="outlined-basic"
                  label="Fee for Support Staff"
                  variant="outlined"
                  className="txt-grey"
                  name="campFeeSS"
                  size="small"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  {...register("campFeeSS")}
                  error={errors.campFeeSS ? true : false}
                ></TextField>

                <Typography
                  color="error.main"
                  sx={{ fontSize: "12px", textAlign: "left" }}
                >
                  {errors.campFeeSS?.message}
                </Typography>
              </Box>
            </Grid>
          </Grid>

          <Box mt={5} mb={5}>
            {" "}
            <Divider />
          </Box>

          <Box sx={{ color: "#AFAFAF", my: "1rem" }}>
            Upload Thumbnail (Only One Picture)
          </Box>

          <form onSubmit={uploadThumnails} encType="multipart/form-data">
            {/* <Box>
              <input type="file" onChange={handleThumbnailChange} />
            </Box> */}

            <Button
              onClick={(event) => (
                hiddenFileInputThum.current.click(),
                (event.currentTarget.value = null)
              )}
              //     (event)=> {
              //       event.currentTarget.value = null
              //  }
              variant="contained"
              // type="submit"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.45rem",
                // width:"15%",
                textTransform: "none",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Choose File
            </Button>
            <Box>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                placeholder=""
                ref={hiddenFileInputThum}
                onChange={handleThumbnailChange}
                accept="image/jpeg, image/png, image/jpg ,image/gif, image/svg"
              />
            </Box>

            <Button
              type="submit"
              variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.75rem",
                textTransform: "none",
                width: "138px",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Upload
            </Button>
          </form>
          <Box>
            <span className="thumbnail-span">
              {thumbnailPath != null ? (
                <img
                  src={thumbnailPath}
                  style={{ height: "20%", width: "20%", marginTop: "0.5%" }}
                />
              ) : (
                ""
              )}

              {thumbnailPath != null ? (
                <img
                  src={CrossGreen}
                  onClick={removeimg}
                  className="cross-img"
                />
              ) : (
                ""
              )}
            </span>

            {/* ) : null} */}
          </Box>
          {console.log(thumbnailPath, "hooooooo")}
          {console.log(thumbnailDocPath, "heeeee")}
          {thumbnailPath !== null ? (
            <Box>{thumbnailDocPath !== null ? "Uploaded" : "Not Uploaded"}</Box>
          ) : (
            ""
          )}
          {errorThumbnail ? <Box> please select thumbnail </Box> : ""}

          {/* <Box mb="1rem">{thumbStatus}</Box> */}
          <Divider />
          <Box sx={{ color: "#AFAFAF", my: "1rem" }}>Videos</Box>
          <Button
            // type="submit"
            variant="contained"
            mx="0rem"
            sx={{
              px: "2rem",
              bgcolor: "#379592",
              my: "0.75rem",
              textTransform: "none",
              width: "138px",
              "&:hover": { background: "#379592" },
            }}
            onClick={handleVideoRow}
            className="gilroy"
          >
            Add
          </Button>
          {videoRow.length > 0 &&
            videoRow.map((item, index) => {
              return (
                // <span className="thumbnail-span">
                <div>
                  {/* {item.actualUrl != null ? ( */}
                  <div key={index} className="thumbnail-container">
                    <Grid container display="row" alignItems="center">
                      <Grid item md={4} sx={{ textAlign: "center" }}>
                        <TextField
                          id="outlined-basic"
                          multiline
                          label="Vimeo URL"
                          variant="outlined"
                          onChangeCapture={(e) => {
                            generateThumbnail(e, item);
                          }}
                          size="small"
                          sx={{ my: "0.5rem", width: "90%" }}
                        ></TextField>

                        <Box sx={{ textAlign: "center" }}>
                          {item.thumbnailurl !== "" ? (
                            <a href={item.actualUrl} target="_blank">
                              <img
                                src={item.thumbnailurl}
                                style={{
                                  height: "45%",
                                  width: "50%",
                                  my: "0.5rem",
                                }}
                              />
                            </a>
                          ) : (
                            ""
                          )}
                        </Box>
                      </Grid>

                      <Grid item md={8}>
                        <Stack direction="row">
                          <Box sx={{ width: "100%" }}>
                            <TextField
                              id="outlined-multiline-static"
                              label="Description"
                              multiline
                              rows={5}
                              name="description"
                              onChange={(e) => {
                                handleDesc(e, item);
                              }}
                              value={item.description}
                              // onChange={(event) => handleComboDescription(event, index)}
                              sx={{ width: "100%", borderRadius: "20px" }}
                            />
                          </Box>
                          <div className="video-thumb">
                            <img
                              src={CrossGreen}
                              onClick={() => removeUrlItem(index)}
                              // className="video-thumb"
                            />
                          </div>
                        </Stack>
                      </Grid>
                    </Grid>
                  </div>

                  {/* ) : (
                  ""
                )} */}

                  {/* {item.actualUrl != null ? ( */}
                  {/* < Box>

                    <img
                      src={CrossGreen}
                      onClick={() => removeUrlItem(index)}
                      className="video-thumb"
                    />

                  </Box> */}

                  {/* ) : (
                  ""
                )} */}
                </div>
              );
            })}

          <Divider />

          <Box sx={{ color: "#AFAFAF", my: "1rem" }}>Attachments</Box>

          <form onSubmit={uploadAttachments} encType="multipart/form-data">
            <Button
              onClick={() => hiddenFileInput.current.click()}
              variant="contained"
              // variant="contained"
              mx="0rem"
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.45rem",
                // width:"15%",
                textTransform: "none",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Choose File
            </Button>
            <Box>
              <input
                type="file"
                multiple
                style={{ display: "none" }}
                placeholder=""
                ref={hiddenFileInput}
                onChange={handleFileChange}
                // accept="image/jpeg, image/png, image/jpg"
                // accept='.doc,.docx,application/pdf'
                accept="image/jpeg, image/png, image/jpg,image/svg,application/pdf,application/PDF "
              />
            </Box>
            <Button
              type="submit"
              variant="contained"
              mx="0rem"
              maxcount={5}
              sx={{
                px: "2rem",
                bgcolor: "#379592",
                my: "0.75rem",
                textTransform: "none",
                width: "138px",
                "&:hover": { background: "#379592" },
              }}
              className="gilroy"
            >
              Upload
            </Button>
          </form>

          {/* <Box>{attachmentsStatus}</Box> */}

          {/* <form onSubmit={handleSubmit} encType="multipart/form-data">
         <input type="file" onChange={handleFileChange} />
          <button type="submit">Upload</button>
          </form> */}

          {/* <Box>
            <label className="file-name">{fileName}</label>
          </Box> */}
          <Box>
            <Box>
              {attachmentsArr.map((link, index) => {
                let x = link.docTitle.search(".pdf");
                let y = link.docTitle.search(".doc");
                {
                  console.log(link, "liiiiiiiiii");
                }
                if (x !== -1 || y !== -1)
                  return (
                    <Box key={index}>
                      <Grid container display="flex" my="0.5rem">
                        <Grid item>
                          <Box
                            component="img"
                            sx={{ height: "70%", mx: "0.5rem" }}
                            alt="Logo"
                            src={PdfIcon}
                          ></Box>
                          <a href={link.path} target="_blank">
                            {link.docTitle}
                          </a>
                        </Grid>
                        <Grid item>
                          <img
                            src={CrossGreen}
                            onClick={() => removeAttachment(index)}
                            style={{ height: "15px", cursor: "pointer" }}
                            // className="removeIcon"
                            // className="cross-img"
                          />
                        </Grid>
                      </Grid>
                      <Box>
                        {link.docPath !== "" ? "Uploaded" : "Not Uploaded"}
                      </Box>
                    </Box>
                    // <span className="thumbnail-span">
                    //   {link.path != null ? (
                    //     <Box sx={{ color: '#0075CE'}}>

                    //   <a href={link.path} target="_blank">
                    //     {link.docTitle}
                    //   </a>
                    // </Box>
                    //   ) : (
                    //     ""
                    //   )}

                    //   {link.path != null ? (
                    //     <img
                    //       src={CrossGreen}
                    //       onClick={() => removeAttachment(index)}
                    //       className="cross-img"
                    //     />
                    //   ) : (
                    //     ""
                    //   )}
                    // </span>
                  );
                else if (x === -1 || y === -1)
                  return (
                    // <span className="thumbnail-span">
                    //   {link.path != null ? (
                    //     <img
                    //       src={link.path}
                    //       style={{ height: "20%", width: "20%", marginTop: '0.5%', marginLeft: '2%' }}

                    //     />
                    //   ) : (
                    //     ""
                    //   )}

                    //   {link.path != null ? (
                    //     <img
                    //       src={CrossGreen}
                    //       onClick={() => removeAttachment(index)}
                    //       className="cross-img"
                    //     />
                    //   ) : (
                    //     ""
                    //   )}
                    // </span>
                    <Box>
                      <Grid
                        container
                        display="flex"
                        my="0.5rem"
                        className="thumbnail-span"
                      >
                        <Grid item>
                          <Box sx={{ color: "#0075CE" }}>
                            <img
                              src={link.path}
                              style={{
                                height: "120px",
                                width: "200px",
                                alignSelf: "center",
                              }}
                            />
                          </Box>
                        </Grid>
                        <Grid item>
                          <img
                            src={CrossGreen}
                            onClick={() => removeAttachment(index)}
                            style={{ height: "15px", cursor: "pointer" }}
                            // className="cross-img"
                            // className='cross-pdf'
                            className="removeIcon"
                          />
                        </Grid>
                      </Grid>
                      <Box>
                        {link.docPath !== "" ? "Uploaded" : "Not Uploaded"}
                      </Box>
                    </Box>
                  );
              })}
            </Box>
          </Box>
          {/* {errorFile  ? (
          <Box> please select Attachments </Box>
      ) : (
        ''
      )} */}

          {/* <Grid display='flex' justifyContent='center'> */}

          {/* <input 
            type="file"
            ref={hiddenFileInput}
            onChange={handleChange}
            style={{display:"none"}}
            /> */}

          <Grid container justifyContent="flex-end">
            <Grid item>
              <Button
                variant="contained"
                sx={{
                  px: "2rem",
                  mx: "1rem",
                  bgcolor: "#379592",
                  textTransform: "none",
                  "&:hover": { background: "#379592" },
                }}
                className="gilroy"
                onClick={handleSubmit(onSubmit)}
                // disabled={true}
              >
                Save
              </Button>
            </Grid>
            <Grid item>
              {/* <Link className='link' to='/ViewCamp'> */}
              <PublishButton
                variant="contained"
                className="gilroy"
                sx={{ px: "2rem", mx: "1rem" }}
                // onClick={() => onSubmit()}
                onClick={handleSubmit(onPublish)}
              >
                Publish
              </PublishButton>
              {/* </Link> */}
            </Grid>
          </Grid>
        </div>
      </ThemeProvider>
      {/* </BrowserRouter> */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {(() => {
          if (selectedType === "groupLeader") {
            return (
              <div>
                <Box sx={style}>
                  <Typography className={classes.mainheader}>
                    Group Leader
                  </Typography>

                  <Search
                    width="20%"
                    sx={{
                      backgroundColor: "#F8F8F8",
                      border: 1,
                      borderColor: "#909090",
                      borderRadius: "0.75rem",
                      "&:hover": {
                        // backgroundColor: alpha(theme.palette.common.white, 0.25),
                        // backgroundColor:'pink',
                        color: "#379592",
                        border: 2,
                        borderColor: "rgba(55, 149, 146, 0.8)",
                      },
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#909090" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      value={search}
                      onChange={handleSearchChange}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                  <Stack direction="row" spacing={3} my="1.5rem">
                    <Box component="img" src={User}></Box>
                    <Box sx={{ color: "#379592" }}>{seachName}</Box>
                  </Stack>

                  <List className={classes.listStyle}>
                    {filteredList
                      .filter(
                        (ele) =>
                          ele.rolesName !== "SUPPORT STAFF" &&
                          ele.userStatus === "ACTIVE"
                      )
                      // .filter((ele) => ele.userStatus === 'ACTIVE')
                      .map((item, index) => (
                        <Typography
                          key={index}
                          onClick={() => selectItem(item.userName, item.userId)}
                          sx={{
                            cursor: "pointer",
                            color:
                              item.rolesName === "CAMP MANAGER"
                                ? "#379592"
                                : "black",
                          }}
                        >
                          {item.userName}
                        </Typography>
                      ))}
                  </List>
                  <Box display="flex" justifyContent="center" my="0.5rem">
                    <Button
                      variant="contained"
                      disabled={!seachName}
                      onClick={() =>
                        handleRowChange(
                          rowId,
                          selectedType,
                          seachName,
                          "groupLeaderId",
                          userId
                        )
                      }
                      sx={{
                        bgcolor: "#379592",
                        color: "white",
                        textTransform: "none",
                        "&:hover": { background: "#379592" },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </div>
            );
          } else if (selectedType === "supportStaff") {
            return (
              <div>
                <Box sx={style}>
                  <Typography className={classes.mainheader}>
                    Support Staff
                  </Typography>

                  <Search
                    width="20%"
                    sx={{
                      backgroundColor: "#F8F8F8",
                      border: 1,
                      borderColor: "#909090",
                      borderRadius: "0.75rem",
                      "&:hover": {
                        // backgroundColor: alpha(theme.palette.common.white, 0.25),
                        // backgroundColor:'pink',
                        color: "#379592",
                        border: 2,
                        borderColor: "rgba(55, 149, 146, 0.8)",
                      },
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#909090" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      value={search}
                      onChange={handleSearchChange}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                  <Stack direction="row" spacing={3} my="1.5rem">
                    <Box component="img" src={User}></Box>
                    <Box sx={{ color: "#379592" }}>{seachName}</Box>
                  </Stack>
                  <List className={classes.listStyle}>
                    {filteredList
                      .filter(
                        (ele) =>
                          ele.rolesName === "SUPPORT STAFF" &&
                          ele.userStatus === "ACTIVE"
                      )
                      .map((item, index) => (
                        <Typography
                          key={index}
                          onClick={() => selectItem(item.userName, item.userId)}
                          sx={{ cursor: "pointer" }}
                        >
                          {item.userName}
                        </Typography>
                      ))}
                  </List>
                  <Box display="flex" justifyContent="center" my="0.5rem">
                    <Button
                      variant="contained"
                      disabled={!seachName}
                      onClick={() =>
                        handleRowChange(
                          rowId,
                          selectedType,
                          seachName,
                          "supportStaffId",
                          userId
                        )
                      }
                      sx={{
                        bgcolor: "#379592",
                        color: "white",
                        textTransform: "none",
                        "&:hover": { background: "#379592" },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </div>
            );
          } else if (selectedType === "therapist") {
            return (
              <div>
                <Box sx={style}>
                  <Typography className={classes.mainheader}>
                    Therapist
                  </Typography>

                  <Search
                    width="20%"
                    sx={{
                      backgroundColor: "#F8F8F8",
                      border: 1,
                      borderColor: "#909090",
                      borderRadius: "0.75rem",
                      "&:hover": {
                        // backgroundColor: alpha(theme.palette.common.white, 0.25),
                        // backgroundColor:'pink',
                        color: "#379592",
                        border: 2,
                        borderColor: "rgba(55, 149, 146, 0.8)",
                      },
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#909090" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      value={search}
                      onChange={handleSearchChange}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                  <Stack direction="row" spacing={3} my="1.5rem">
                    <Box component="img" src={User}></Box>
                    <Box sx={{ color: "#379592" }}>{seachName}</Box>
                  </Stack>
                  <List className={classes.listStyle}>
                    {filteredList
                      .filter(
                        (ele) =>
                          ele.rolesName !== "SUPPORT STAFF" &&
                          ele.userStatus === "ACTIVE"
                      )
                      .map((item, index) => (
                        <Typography
                          key={index}
                          onClick={() => selectItem(item.userName, item.userId)}
                          sx={{
                            cursor: "pointer",
                            color:
                              item.rolesName === "CAMP MANAGER"
                                ? "#379592"
                                : "black",
                          }}
                        >
                          {item.userName}
                        </Typography>
                      ))}
                  </List>
                  <Box display="flex" justifyContent="center" my="0.5rem">
                    <Button
                      variant="contained"
                      disabled={!seachName}
                      onClick={() =>
                        handleRowChange(
                          rowId,
                          selectedType,
                          seachName,
                          "therapistId",
                          userId
                        )
                      }
                      sx={{
                        bgcolor: "#379592",
                        color: "white",
                        textTransform: "none",
                        "&:hover": { background: "#379592" },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </div>
            );
          } else {
            return (
              <div>
                <Box sx={style}>
                  <Typography className={classes.mainheader}>
                    Medication Manager
                  </Typography>

                  <Search
                    width="20%"
                    sx={{
                      backgroundColor: "#F8F8F8",
                      border: 1,
                      borderColor: "#909090",
                      borderRadius: "0.75rem",
                      "&:hover": {
                        // backgroundColor: alpha(theme.palette.common.white, 0.25),
                        // backgroundColor:'pink',
                        color: "#379592",
                        border: 2,
                        borderColor: "rgba(55, 149, 146, 0.8)",
                      },
                    }}
                  >
                    <SearchIconWrapper>
                      <SearchIcon sx={{ color: "#909090" }} />
                    </SearchIconWrapper>
                    <StyledInputBase
                      placeholder="Search…"
                      value={search}
                      onChange={handleSearchChange}
                      inputProps={{ "aria-label": "search" }}
                    />
                  </Search>
                  <Stack direction="row" spacing={3} my="1.5rem">
                    <Box component="img" src={User}></Box>
                    <Box sx={{ color: "#379592" }}>{seachName}</Box>
                  </Stack>
                  <List className={classes.listStyle}>
                    {filteredList
                      .filter(
                        (ele) =>
                          ele.rolesName !== "SUPPORT STAFF" &&
                          ele.userStatus === "ACTIVE"
                      )
                      .map((item, index) => (
                        <Typography
                          key={index}
                          onClick={() => selectItem(item.userName, item.userId)}
                          sx={{
                            cursor: "pointer",
                            color:
                              item.rolesName === "CAMP MANAGER"
                                ? "#379592"
                                : "black",
                          }}
                        >
                          {item.userName}
                        </Typography>
                      ))}
                  </List>
                  <Box display="flex" justifyContent="center" my="0.5rem">
                    <Button
                      variant="contained"
                      disabled={!seachName}
                      onClick={() =>
                        handleRowChange(
                          rowId,
                          selectedType,
                          seachName,
                          "medicationManagerId",
                          userId
                        )
                      }
                      sx={{
                        bgcolor: "#379592",
                        color: "white",
                        textTransform: "none",
                        "&:hover": { background: "#379592" },
                      }}
                    >
                      Save
                    </Button>
                  </Box>
                </Box>
              </div>
            );
          }
        })()}
      </Modal>
    </>
  );
}
