// apiEndpoints.js

export const SERVER_URL ='https://dev.calmandconnected.com.au/microservice'
// export const SERVER_URL ='https://uat.calmandconnected.com.au/microservice'
// export const SERVER_URL ='https://app.calmandconnected.com.au/microservice'
// export const SERVER_URL = 'https://cncdevapi.natsoft.io'
export const CREATE_INCIDENT = '/api/reports/createincidentreport';
export const GET_INCIDENT_LIST = '/api/reports/getincidentreports'
export const VIEW_INCIDENT = "/api/reports/viewincidentreport"
export const EDIT_INCIDENT = "/api/reports/editincidentreport"
export const  GET_GROUP_WISE_PARTICIPENT = '/api/actcamp/getgroupwiseparticipantslist'
// Add more endpoints as needed

// Non folder components
export const ACTIVE_INACTIVE_STAFF = "/api/staff/activeinactivestaff"
export const GETLIST_STAFF = "/api/staff/getlistofstaff"

export const GROUP_FULLINFO ="/api/actcamp/getgroupfullinfo"
export const GROUPWISE_PARTICIPANTLIST = "/api/actcamp/getgroupwiseparticipantslist"
export const ASSIGN_PARTICIPANT = "/api/actcamp/assignparticipantstogroup" 
export const GET_GROUPNAME = "/api/actcamp/getgroupnames"

export const STAFF_INVITE_EMAIL = "/api/auth/staffinvitemail"

export const LIST_CAMPS = "/api/camps/listallcamps"
export const FILTER_LIST_CAMPS = "/api/camps/filterlistcamps" 
export const SENDMAIL_TO_CAMPSTAFF = "/api/camps/sendmailtocampstaff" 

// export const CREATE_NOTIFICATION = "/api/camps/createnotification" 

export const GET_NOTIFICATION = "/api/camps/getnotifications" 

export const CAMP_CHECK_IN = "/api/actcamp/camp_check_in" 

export const CAMP_CHECK_IN_LOG = "/api/actcamp/camp_check_in_log" 

// export const GET_CAMPAPPLICATION = "/api/camps/getcampapplication"

export const FILE_UPLOAD = "/api/camps/fileupload"
export const CREATE_CAMP = "/api/camps/createcamp"

export const RESET_PASSWORD = "/api/auth/resetpassword" 

export const VIEW_CAMP = "/api/camps/viewcamp"
export const EDIT_CAMP = "/api/camps/editcamp" 

export const OTP = "/api/auth/otp"

export const GET_CAMP_GALLERY = "/api/actcamp/getcampgallery"

export const USER_LOGIN = "/api/auth/userlogin" 

export const UPDATE_STAFF_PROFILE = "/api/auth/updatestaffprofile"
export const GET_PROFILE = "/api/auth/getprofile"

export const SET_EMERGENCY_CONTACT = "/api/auth/setemergencycontacts"

export const REMOVE_CAMP = "/api/camps/removecamp"
export const GET_EMERGENCY_CONTACT = "/api/auth/getemergencycontacts"

export const VERIFY_OTP = "/api/auth/verifyotp"

export const SIGNUP = "/api/auth/signup";

// Camp Summary module
// export const US8_URL = '/api/us8';

// Participants 
export const DECLINE_APPLICATIONS = '/api/camps/declineapplications';
export const UPDATECAMP_REGSTATUS = '/api/camps/updatecampregstatus';
export const GETCAMP_APPLICATION = '/api/camps/getcampapplicants';
export const GETCAMP_APPLICANTSCOMMENTS = '/api/camps/getcampapplicantscomments';
export const UPDATESUITABILITY_STATUS = '/api/camps/updatesuitabilitystatus';
export const GETCAMP_APPLICANTS = '/api/camps/getcampapplicants';
export const CREATEWELCOME_PACK = '/api/actcamp/createwelcomepack';
export const US8_URL = '/api/us8';
export const VIEW_NDISAGREEMENT = '/api/child/viewndisagreement';
export const GETNDIS_AGREEMENTDETAILS = '/api/child/getndisagreementdetails';
export const CREATENDIS_AGREEMENT = '/api/child/createndisagreement';
export const EDITNDIS_AGREEMENTMANAGER = '/api/child/editndisagreementmanager';
export const CREATE_NOTIFICATION = '/api/camps/createnotification';
export const GET_NOTIFICATIONS = '/api/camps/getnotifications';
export const GET_PARTICIPANTSCOUNT = '/api/camps/getparticipantscount';
export const GET_CHILDPARTICIPANTFILES = '/api/child/getchildparticipantfiles';
export const GET_CAMPAPPLICATION = '/api/camps/getcampapplication';
export const VIEW_WELCOMEPACK = '/api/actcamp/viewwelcomepack';
export const EDIT_WELCOMEPACK = '/api/actcamp/editwelcomepack';

//Support Staff
export const GETSTAFF_CAMPAPPLICANTS = '/api/staff/getstaffcampapplicants';
export const REVIEWSTAFF_APPLICATION = '/api/staff/reviewstaffappllication';
export const GETSTAFF_CAMPAPPLICATION = '/api/staff/getstaffcampapplication';
export const GETCMPWISE_STAFFREGSCOUNT = '/api/camps/getcmpwisestaffregscount';


//Medication Manager

export const CAMP_CHECKIN = '/api/actcamp/camp_check_in';
export const CAMP_CHECKIN_LOG = '/api/actcamp/camp_check_in_log';

// Profilepic
export const UPLOAD_PROFILE_PIC = '/api/auth/uploadprofilepic';

//Reports
export const INVOICE_REPORTS_DOWNLOAD = '/api/reports/invoicereports';
export const MEDICATION_REPORTS = '/api/reports/medicationreports';
export const DOWNLOAD_SUMMARY = '/api/reports/pdf_camp_summary';
export const ALERT_REPORT = '/api/reports/pdf_alert_reports';
localStorage.setItem("version",1.3)