import React from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import CardMedia from "@mui/material/CardMedia";
import image from "./Assets/Logo-Main.png";
import Image from "./Assets/candcimg1.png";
import Iconawesome from "./Assets/Icon-awesome-check-circle.png";
import { Link, useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
// import Link from "@mui/material/Link";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider, styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Swal from 'sweetalert2'
import "./VerifyOTP.css";


const theme = createTheme({
    typography: {
        fontFamily: "gilroyMedium",
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                "@font-face": {
                    fontFamily: "gilroyMedium",
                    src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
                },
            },
        },
    },
})

export default function Email() {
    const history = useHistory();
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box>
<Box>Email received with application link once Camp Manager added Staff user</Box>  
<Grid my='4rem'> 
<Box textAlign='center'>Application link for users:<Link to="/register" >www.C&C.Example.com</Link>  </Box>

<Box textAlign='center'>Email: Marie:yopmail.com</Box>
<Box textAlign='center'>Password: sdfbfbweuhbfyw4BF4U3IW </Box>
<Box textAlign='center'> Role: Therapist</Box>
</Grid>              

            </Box>
        </ThemeProvider>
    );
}
