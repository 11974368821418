import React,{useState}  from "react";
import Button from "@mui/material/Button";
import "../index.css";
import {
  CardActionArea,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  
  Chip,
  CardContent,
  Divider,
  Box,
} from "@mui/material";
import Grid from "@mui/material/Grid";
// import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import Paper from "@mui/material/Paper";

import Filter from "../Assets/Icons/Icons/filter.png";
import CardMedia from "@mui/material/CardMedia";
import Popover from "@mui/material/Popover";
import Card from "@mui/material/Card";
import Image from "../Assets/Icons/Icons/Background-image.png";
import { apiService, apiService2 } from "../Services/apiService";
import moment from "moment";
import { Link,useHistory } from "react-router-dom";
// import EditComponent from "./EditComponent";
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "@mui/material/Pagination";
import { CssBaseline } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import DefaultImage from "../Assets/Icons/Icons/DefaultImage.png"
import StaticImage from "../Assets/Icons/Icons/StaticImage.png"
import {
  SERVER_URL,
  LIST_CAMPS,
  FILTER_LIST_CAMPS,
} from "../Services/apiEndpints";

// const filters = [
//   "Camp confidence",
//   "Girls Camp",
//   "Boys Camp",
//   "Young Ladies Getaway",
//   "Adventure Camps",
//   "Support Camps",
// ];

const filters=[
  // {
  //   name:'Mixed',
  //   value:'Mixed'
  // },
  {
    name:'Camp Confidence',
    value:'Camp Confidence'
  },
  {
    name:'Girls Camp',
    value:'Girls Camp'
  },
  {
    name:'Boys Camp',
    value:'Boys Camp'
  },
  {
    name:'Young Ladies Getaway',
    value:'Young Ladies Getaway'
  },
  {
    name:'Adventure Camp',
    value:'Adventure Camp'
  },
  {
    name:'Support Camps',
    value:'Support Camp'
  },
]

const sortBy = [
  "Date",
  "Fee",
  "Age group",
];

const theme = createTheme({
  typography: {
    fontFamily: "gilroyMedium",
  },
  palette: {
    secondary: {
      main: "#379592",
    },
  },
  // components: {
  //   MuiCssBaseline: {
  //     styleOverrides: {
  //       "@font-face": {
  //         fontFamily: "gilroyMedium",
  //         src: "url($(./Assets/Font/gilroy/Gilroy-Medium.ttf))",
  //       },
  //     },
  //   },
  // },
  Muidisabled: {
    styleOverrides: {
      backgroundColor: "purple",
    },
  },
});


export default function CampList() {
  const history = useHistory();

  const[loading,setLoading] = React.useState(false)
  const [search, setSearch] = React.useState('')
  const [currentPage, setCurrentPage] = React.useState(1);
  const [sortType,setSortType] = React.useState('default');
  
  const handleSearchChange = (event) => {
    console.log(event.target.value)
    setSearch(event.target.value)
    setCurrentPage(1)
  }
    
    
  const [anchorEl, setAnchorEl] = useState(null);
  const [datatry, setDataTry] = React.useState([]);
  const [filterData,setFilterData] = React.useState([])
  // const [searchValue,setSearchValue] = React.useState('')
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    console.log(anchorEl)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

//   const getSearchData = async (filterValue)=>{
//   console.log(filterValue)
//   setSearchValue(filterValue);
// if(filterValue){
//   let apiInfo = {
//     url:"https://cncdevapi.natsoft.io/api/camps/filterlistcamps",
//     method:"POST",
//     postData:{
//       userId: localStorage.getItem("userId"),
//       filterBy: "camp_type",
//       filterValue : filterValue,
//       headers : {
//         "Content-Type": "application/json",
//         // tokenheaderkey:
//         //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rjb29yZGluYXRvckB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NjI4NjM0NywiZXhwIjoxNjg0MDYyMzQ3fQ.TSAbvmY8RwRINFkDN2btiMOSm0f8bIjkTv2-w7APjP4",
//         tokenheaderkey:localStorage.getItem("token"),
//       },
//       }
//     }
//     // console.log("filter",filterValue)
//     let filterValues = await apiService (apiInfo);
//     let filterOutputList = []
//     //  filterValues.data.responseData[0]
//     if(filterValues.data.responseDesc==="Success"){
//       filterOutputList=filterValues.data.responseData[0];
//       // setDataTry(filterOutputList)
//       // setFilterData(filterOutputList)
//       setFilterData(filterOutputList)
//       setDataTry(filterOutputList)
//     }
// }
//       else{
//         showList()
//       }
//     }


  const getFilterData = async (filterValue)=>{

    let apiInfo = {
      // url:"https://cncdevapi.natsoft.io/api/camps/filterlistcamps",
      url:`${SERVER_URL}${ FILTER_LIST_CAMPS}`,
      method:"POST",
      postData:{
        userId: localStorage.getItem("userId"),
        filterBy: "camp_type",
        filterValue : filterValue,
        headers : {
          "Content-Type": "application/json",
          
          tokenheaderkey:localStorage.getItem("token"),
        },
        }
      }
      console.log("filter",filterValue)
      let filterValues = await apiService (apiInfo);
      let filterOutputList = []
      //  filterValues.data.responseData[0]
      if(filterValues.data.responseDesc==="Success"){
        filterOutputList=filterValues.data.responseData[0];
        // setDataTry(filterOutputList)
        setFilterData(filterOutputList)
      
      }
      // else{
      //   setDataTry(datatry)
      // }
      
    }

    const handleApply = ()=>{
      setDataTry(filterData)
    }

const showList = () =>{
  (async () => {
     
    let gettran = {
      userId: localStorage.getItem("userId"),
    };

    let apiInfo = {
      // url: "https://cncdevapi.natsoft.io/api/camps/listallcamps",
      url:`${SERVER_URL}${LIST_CAMPS}`,
      method: "POST",
      postData: gettran,
      headers: {
        "Content-Type": "application/json",
        // tokenheaderkey:
        //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rjb29yZGluYXRvckB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NjI4NjM0NywiZXhwIjoxNjg0MDYyMzQ3fQ.TSAbvmY8RwRINFkDN2btiMOSm0f8bIjkTv2-w7APjP4",
        tokenheaderkey:localStorage.getItem("token"),
      },
      
    };
    let login = await apiService2(apiInfo);
    // login.data.responseData[0]?():()
    const campDataList = login.data.responseData[0];
     
    campDataList.forEach((element) => {
      if (element.StaffList != null) {
        
        console.log(element.StaffList);
        element.StaffList = JSON.parse(element.StaffList);
       
      }
    });
    console.log(campDataList);
    setDataTry(campDataList);
    console.log(datatry)
  })()
    .catch((e) => {
      console.error(e);
    })
    .finally(() => {
      // setLoading(false);
    });
      }  

  React.useEffect(() => {
    (async () => {
      setLoading(true)
      let gettran = {
        userId: localStorage.getItem("userId"),
        // campStatus:"ACTIVE"
      };

      let apiInfo = {
        // url: "https://cncdevapi.natsoft.io/api/camps/listallcamps",
        url:`${SERVER_URL}${LIST_CAMPS}`,
        method: "POST",
        postData: gettran,
        headers: {
          "Content-Type": "application/json",
          // tokenheaderkey:
          //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlc3Rjb29yZGluYXRvckB5b3BtYWlsLmNvbSIsImlhdCI6MTY3NjI4NjM0NywiZXhwIjoxNjg0MDYyMzQ3fQ.TSAbvmY8RwRINFkDN2btiMOSm0f8bIjkTv2-w7APjP4",
          tokenheaderkey:localStorage.getItem("token"),
        },
        
      };
      let login = await apiService2(apiInfo);
      // login.data.responseData[0]?():()
      const campDataList = login.data.responseData[0];
      setLoading(false)
      
      campDataList.forEach((element) => {
        if (element.StaffList != null) {
          
          // console.log(element.StaffList);
          element.StaffList = JSON.parse(element.StaffList);
         
        }
      });
      
      setDataTry(campDataList);
      
    })()
      .catch((e) => {
        console.error(e);
      })
      .finally(() => {
        // setLoading(false);
      });
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', function (event){
          window.history.pushState(null, document.title,  window.location.href);
      });
  }, []);




const filteredList = datatry.filter((item) =>{
  // 
  // console.log(item.campName,search)
 
  return (
    item?.campName?.toLowerCase().includes(search.toLowerCase())|| 
    item?.campType?.toLowerCase().includes(search.toLowerCase())||
    item?.campLocation?.toLowerCase().includes(search.toLowerCase())
  );
});
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
     
    }
  };

  const sortTypes = {
    
    fee: {
      class: 'sort-up',
      fn: (a, b) => (a.campFee > b.campFee) ? 1: -1,
      
    },
    downFirstName: {
      class: 'sort-down',
      fn: (a, b) =>  (a.firstName.toUpperCase() > b.firstName.toUpperCase()) ? -1 : 1,
      
    },
    default: {
      class: 'sort',
      fn: (a, b) => a
    }
   
  }

  

  // function useExpiredLocalStorage(key, defaultValue, expirationTime) {
  //   const storedData = localStorage.getItem(key);
  
  //   if (storedData) {
  //     console.log('coming insideeeeeee')

  //     const parsedData = JSON.parse(storedData);
  //     console.log(parsedData.expiration,Date.now() )
  //     if (parsedData.expiration > Date.now()) {
  //       return parsedData.value;
  //     } else {
  //       localStorage.removeItem('dashboard');
  //       localStorage.clear()
  //       history.push('/')
        
  //     }
  //   }
  
  //   return defaultValue;
  // }
  
  //  const userName = useExpiredLocalStorage('key', '',  60 * 1000);
  
   
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
       <div>
      <Box>
        <Grid container direction="row" >
          {/* <Grid item> */}
            <Grid container display='flex' justifyContent='space-between'>
            <Grid item sx={{width:'60%'}}>
            <Paper
              xs={12}
              sm={12}
              md={6}
              component="form"
              sx={{ display: "flex", alignItems: "center", width: "100%"}}
            >
              <InputBase
             
                sx={{ ml: 1, flex: 1,width:'100%'}}
                placeholder="Search"
                // inputProps={{ 'aria-label': 'search' }}
                value={search}
                onChange={handleSearchChange}
                onKeyDown={handleKeyDown}
              />

              {/* <Box
                component="img"
                src={Filter}
                sx={{ height: "1rem", width: "1rem", mx: "1rem" ,cursor:"pointer",}}
                onClick={handleClick}
              /> */}
            </Paper>
          </Grid>

          <Grid item>
          <Box className="gilroy" alignSelf="center" sx={{fontSize:'1.2rem'}}  >
            <Button variant='contained' sx={{color: '#379592',bgcolor:'#718F9480',
                  textTransform: 'none',
                  "&:hover": { background: "#718F9480" },
                  fontFamily:'gilroy',
                  mx:'0.5rem',}}>
            <Link to='/CampList1' className='link2' >View all</Link>
            </Button>
           
          </Box>
          </Grid>
            </Grid>
          {/* </Grid> */}
          
          
        </Grid>
        {loading===true
         ? (<Grid container justifyContent='center' alignItems='center' ><CircularProgress /></Grid >)
         : (<Box>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} mb='1rem'>
          {filteredList.sort(sortTypes[sortType].fn).slice(currentPage * 6 - 6, currentPage * 6).map((ele, index) => (
            // {filteredList.map((ele, index) => (
            // <div>
               <Grid item key={index} xs={12} sm={12} md={4} my="2rem">
              {/* <Box>{ele}</Box> */}
              <Card sx={{ maxWidth: 345}} className='container'>
              {/* <EditComponent/> */}
                <CardActionArea  >
                <Link to={`/ViewCamp/${ele.campId}`} className="link2">
                <CardMedia 
                    component="img"
                    height="140"
                    
                    // image={DefaultImage}
                    src={ele.Thumbnail ? ele.Thumbnail : StaticImage}
                  
                  />
            </Link>
                  
                 
                  
                  <CardContent >
                    <Grid
                      container
                      display="flex"
                      justifyContent="space-between"
                      // className='content'
                    >
                      <Grid item className="name" >
                        <Box className="gilroy fs1rem " >{ele.campName}</Box>
                        <Box className="medium fs-10" mt="0.5rem">
                       {ele.campLocation}
                        </Box>
                      </Grid>
                      <Grid item>
                        <Box className="gilroy fs-12">
                          <Chip
                            label={ele.campType.charAt(0).toUpperCase()+ele.campType.slice(1).toLowerCase()}
                            variant="outlined"
                            size="small"
                          />
                        </Box>
                        <Box
                          className="medium fs-10"
                          textAlign="center"
                          alignSelf="baseline"
                          mt="4px"
                        >
                          {/* {moment(ele.campStartDateTime).format("dd MM")} */}
                           {/* {ele.campStartDateTime} */}
                          {/* {moment("20111031", "YYYYMMDD").format("D MMM")}{" "} */}
                          {moment(ele.campStartDateTime.slice(0,10), "DD/MM/YYYY").format("D MMM YYYY").toUpperCase()}{" "}
                        </Box>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
            ))}
        </Grid>
        <Grid container justifyContent='flex-end' >
        <Pagination
        page={currentPage}
             count={
              filteredList.length%6===0 
              ? Math.floor(filteredList.length/6)
              : Math.floor(filteredList.length/6)+1} 
               onChange={(event,val)=> setCurrentPage(val)}
              //  onClick={()=>{test()}}
               color="secondary"
            size="small"
          />
        </Grid>
         </Box>)}
        <Popover
          id={id}
          open={open}
         
          onClose={handleClose}
          anchorReference="anchorPosition"
  anchorPosition={{ top: 250, left: 500 }}
  anchorOrigin={{
    vertical: 'top',
    horizontal: 'center',
  }}
  transformOrigin={{
    vertical: 'center',
    horizontal: 'center',
  }}
        >
          <Grid>
            <Divider />
            <Grid>
              <FormControl className="medium">
                {/* <FormLabel id="demo-row-radio-buttons-group-label">Gender</FormLabel> */}
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  size="small"
                  sx={{
                    maxWidth: 500,
                    "& .MuiSvgIcon-root": {
                      fontSize: 16,
                    },
                  }}
                  // className="medium"
                  my="2rem"
                  mx="1rem"
                >
                  {filters.map((filter, index) => (
                    <FormControlLabel
                      key={index}
                      value={filter.value}
                      control={<Radio />}
                      label={filter.name}
                      className="medium"
                      sx={{ px: "1rem" }}
                      // onHandle={getFilterData}
                      onChange={
                        (event)=>{getFilterData(event.target.value)}
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
              <Divider />
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label" sx={{px:'1rem'}} >
                  Sort by
                </FormLabel>
                <RadioGroup
                  sx={{ maxWidth: 500, "& .MuiSvgIcon-root": { fontSize: 16 } }}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  size="large"
                  
                  // className="medium"
                >
                  {sortBy.map((filter, index) => (
                    <Box>
                      <FormControlLabel
                        key={index}
                        value={filter}
                        control={<Radio />}
                        label={filter}
                        className="medium"
                        sx={{px:'1rem'}}
                      />
                    </Box>
                  ))}
                </RadioGroup>
              </FormControl>
              
            </Grid>
          </Grid>
          <Grid display='flex' justifyContent='flex-end' mt='1rem'>
          <Button  onClick={()=>showList()} variant="contained"
                sx={{
                  px: "3rem",
                  bgcolor: "#707070",
                  textTransform: 'none',
                  "&:hover": { background: "#707070" },
                  mx:'0.5rem',
                  
                }}>Reset</Button>
                
          <Button onClick={()=> handleApply ()} variant="contained"
                sx={{
                  px: "3rem",
                  bgcolor: "#379592",
                  textTransform: 'none',
                  "&:hover": { background: "#379592" },
                  mx:'0.5rem'
                }}>Apply</Button>
          
          </Grid>
          
        </Popover>
      </Box>
      {/* <Box mt="2rem"></Box> */}
    </div>
    </ThemeProvider>
   
  );
}
